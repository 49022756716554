import * as React from 'react';
import { Reason } from 'redux/ducks/reasons';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, RadioGroup, Button, Typography } from '@material-ui/core';
import { SelectReasonOption } from './SelectReasonOption';
import SelectReasonDialog from './SelectReasonDialog/index';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import classNames from 'classnames';

interface SelectReason {
  onSelect: (reason: Reason) => void;
  reasons: Reason[];
  error: boolean;
  selected?: Reason;
  style?: string;
  title?: string;
  titleStyles?: string;
}

interface SelectReasonProps extends SelectReason, InjectedIntlProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.secondary,
    marginBottom: '20px'
  },
  titleError: {
    color: '#F44236',
    marginBottom: '20px',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  reasons: {
    marginTop: '-5px'
  },
  reasonsError: {
    marginTop: '-5px'
  },
  formControlReasons: {
    width: '100%',
    marginLeft: '10px'
  },
  moreReasonsButton: {
    width: '100%',
    border: '2px solid #c4e4e2',
    borderRadius: '1px',
    padding: '15px 10px 15px 10px'
  },
  moreReasonsLabel: {
    font: 'inherit',
    fontWeight: 'bold',
    color: '#018b87'
  }
}));

const SelectReason: React.FunctionComponent<SelectReasonProps> = (props) => {
  const {
    onSelect,
    reasons,
    error,
    selected = {} as Reason,
    intl,
    style,
    title,
    titleStyles
  } = props;

  const [isOpen, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const sortedReasons = React.useMemo(() => {
    const index = reasons.findIndex((r) => r.id === selected.id);
    if (selected.id && index > 4) {
      const croppedSelected = reasons.filter((r) => r.id !== selected.id).slice(0, 4);
      return [selected, ...croppedSelected];
    } else {
      return reasons.slice(0, 5);
    }
  }, [selected, reasons]);

  const handleSelect = (reason: Reason) => {
    onSelect(reason);
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <div className={style}>
      <Typography
        className={classNames(error ? classes.titleError : classes.title, titleStyles)}
        component='h1'
        variant='h3'
      >
        {title ? intl.messages[title] : intl.messages['registration.selectReason']}
      </Typography>
      <div className={error ? classes.reasonsError : classes.reasons}>
        <FormControl className={classes.formControlReasons}>
          <RadioGroup>
            {sortedReasons.map((reason, index) => (
              <SelectReasonOption
                onClick={handleSelect}
                selected={reason.id === selected.id}
                reason={reason}
                key={index}
              />
            ))}
          </RadioGroup>
          <RadioGroup>
            {reasons.length > 5 && isOpen && (
              <SelectReasonDialog
                reasons={reasons}
                selected={selected}
                onSelect={handleSelect}
                isOpen={isOpen}
                onClose={handleDialogClose}
              />
            )}
          </RadioGroup>
        </FormControl>
        {reasons.length > 5 && (
          <Button
            classes={{ root: classes.moreReasonsButton, label: classes.moreReasonsLabel }}
            onClick={handleDialogOpen}
          >
            {intl.messages['registration.viewMoreReasons']}
          </Button>
        )}
      </div>
    </div>
  );
};

export default injectIntl(SelectReason);
