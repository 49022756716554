import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TopbarMenu } from 'styles/themes/global';
import { Button, Card, Grid, Link, TextField, Typography } from '@material-ui/core';

import upgradePlan from 'static/img/upgrade-plan.svg';
import classNames from 'classnames';
import { Icon } from 'components/Icon';
import Helmet from 'react-helmet';
import ContactUsDialog from 'components/ContactUsDialog';

type RegisterProps = InjectedIntlProps;

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: '3px',
    padding: '6px',
    backgroundColor: theme.palette.grey.A400,
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    minHeight: `calc(100vh - ${TopbarMenu.height})`
  },
  label: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.grey.A100
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  card: {
    width: '90%',
    backgroundColor: 'white',
    padding: '12px 10px',
    maxWidth: '400px',
    margin: '24px 0 0 0'
  },
  buttonSubmit: {
    marginTop: theme.spacing(5),
    height: '54px',
    marginBottom: theme.spacing(1),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.grey.A100,
    boxShadow: 'none'
  },
  upgradeImage: {
    width: '160px'
  },
  flexDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    margin: '2px 0 0 3px',
    cursor: 'pointer'
  },
  linkContainer: {
    marginTop: theme.spacing(1)
  }
}));

const StarterPlanGuestRegistration: React.FunctionComponent<RegisterProps> = (props) => {
  const { intl } = props;
  const classes = useStyles(props);
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.form}>
      <ContactUsDialog isOpen={isOpen} onClose={handleDialogClose} />
      <Helmet title={intl.messages['registration.headline']} />
      <Card className={classes.card}>
        <div className={classes.formContainer}>
          <Grid container alignItems='center' direction='column' justify='center'>
            <Typography className={classes.label} component='h1' variant='h3'>
              {intl.messages['guests.enterNumberOfGuests']}
            </Typography>
            <TextField
              fullWidth
              type='number'
              placeholder=' - '
              className={classes.input}
              value={''}
              disabled
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  style: { textAlign: 'center', height: '48px' }
                }
              }}
            />
          </Grid>

          <Button variant='contained' className={classes.buttonSubmit}>
            {intl.messages['registration.btn']}
          </Button>
        </div>
      </Card>

      <Card className={classes.card}>
        <div className={classes.flexDiv}>
          <div>
            <Typography variant='h1' component='h1'>
              {intl.messages['guests.upgradePlan']}
            </Typography>
            <div className={classNames(classes.flexDiv, classes.linkContainer)}>
              <Typography>
                {intl.messages['guests.getMore']}
                <Link onClick={handleDialogOpen} underline='none' className={classes.link}>
                  {intl.messages['base.here']}
                </Link>
              </Typography>
            </div>
          </div>
          <Icon icon={upgradePlan} className={classes.upgradeImage} />
        </div>
      </Card>
    </div>
  );
};

export default injectIntl(StarterPlanGuestRegistration);
