import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Card, CardContent, Grid } from '@material-ui/core';
import { RootState } from 'redux/rootReducer';
import { fetchMetrics, StatusMetric } from 'redux/ducks/dashboard';
import { connect } from 'react-redux';
import {
  getSelectedAccountIds,
  getSustainabilityDashboardMetrics,
  getTimeFilter
} from 'redux/ducks/reportFilter/selectors';
import {
  DASHBOARD_METRIC_INTL_KEYS,
  DEFAULT_DASHBOARD_METRIC_OPTIONS,
  getStatusIntlKey
} from 'pages/Dashboard/DashboardMetricCard';
import Fact from 'pages/Report/components/Fact';
import { formatNumber, FormatNumberOptions, MassUnit, transformAmount } from 'utils/number-format';
import { ProgressText } from 'pages/Report/components/ProgressMessage';
import { makeStyles } from '@material-ui/styles';
import MealIndicator from 'pages/Dashboard/MealIndicator';
import TotalPerGuestMetric from 'pages/Report/Sustainability/components/EmissionMetrics/TotalPerGuestMetric';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type CarbonFootprintProps = StateProps & DispatchProps & InjectedIntlProps;

interface MetricCardProps extends CarbonFootprintProps {
  metric: StatusMetric;
}

const valueFormatOptions: FormatNumberOptions = {
  displayUnit: true,
  precision: 0,
  rounding: 'round'
};

const useStyles = makeStyles({
  fullHeight: {
    height: '100%'
  }
});

const EmissionMetrics: React.FunctionComponent<CarbonFootprintProps> = (props) => {
  const {
    timeFilter: { period, from, to },
    metrics,
    customerIds,
    fetchMetrics
  } = props;

  React.useEffect(() => {
    if (customerIds) {
      void fetchMetrics({ customerIds, timeRange: { from, to }, period, dimension: 'co2' });
    }
  }, [customerIds, period, from, to]);

  const { co2_waste: totalMetric, per_guest_saved: perGuestMetric } = metrics;

  return (
    <>
      {totalMetric && (
        <Grid key={totalMetric.id} item xs={12} sm={6} lg={3}>
          <MetricCard metric={totalMetric} {...props} />
        </Grid>
      )}
      <TotalPerGuestMetric />
      {perGuestMetric && (
        <Grid key={perGuestMetric.id} item xs={12} sm={6} lg={3}>
          <MetricCard metric={perGuestMetric} {...props} />
        </Grid>
      )}
    </>
  );
};

const MetricCard = (props: MetricCardProps) => {
  const { metric, state } = props;
  const classes = useStyles(props);

  const getTransformOptions = (metric: StatusMetric) => {
    const { id, point } = metric;
    const { as } = DEFAULT_DASHBOARD_METRIC_OPTIONS[id];
    return as ? { unit: point.unit, as } : undefined;
  };

  const getViewUnit = (metric: StatusMetric) => {
    const { id, point } = metric;
    const { as } = DEFAULT_DASHBOARD_METRIC_OPTIONS[id];

    return as || (point.unit as MassUnit);
  };

  return (
    <Card className={classes.fullHeight}>
      <CardContent>
        <Fact
          isTrendInverted={metric.isTrendInverted}
          isLoading={state !== 'idle'}
          value={formatNumber(transformAmount(metric.point.value, getTransformOptions(metric)), {
            ...valueFormatOptions,
            unit: getViewUnit(metric)
          })}
          title={<FormattedHTMLMessage id={DASHBOARD_METRIC_INTL_KEYS[metric.id].title} />}
          dimension='co2'
          message={{
            customIcon: metric.id === 'co2_waste' ? <MealIndicator /> : undefined,
            text: (
              <FormattedMessage
                id={getStatusIntlKey(metric)}
                values={{
                  value: (
                    <ProgressText>
                      {formatNumber(metric.status.value, {
                        ...valueFormatOptions,
                        signDisplay: 'never',
                        unit: metric.status.unit
                      })}
                    </ProgressText>
                  )
                }}
              />
            ),
            progressValue: metric.growth === 'noTrend' ? 0 : metric.status.value,
            invertedProgress:
              (metric.status.value < 0 && metric.growth === 'positive') ||
              (metric.status.value > 0 && metric.growth === 'negative')
          }}
        />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: state.dashboard.state,
  metrics: getSustainabilityDashboardMetrics(state),
  timeFilter: getTimeFilter(state),
  customerIds: getSelectedAccountIds(state)
});

const mapDispatchToProps = {
  fetchMetrics
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmissionMetrics));
