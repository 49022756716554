import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { Card, CardContent, Grid } from '@material-ui/core';
import { RootState } from 'redux/rootReducer';
import { fetchData } from 'redux/ducks/reportData';
import { connect } from 'react-redux';
import { getSelectedAccountNames } from 'redux/ducks/reportFilter/selectors';
import { getTotalGuests } from 'redux/ducks/reportData/selector';
import Fact from 'pages/Report/components/Fact';
import { makeStyles } from '@material-ui/styles';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type CarbonFootprintProps = StateProps & DispatchProps & InjectedIntlProps;

const id = 'guestRegistrations';

const useStyles = makeStyles({
  fullHeight: {
    height: '100%'
  }
});
const TotalGuestMetric: React.FunctionComponent<CarbonFootprintProps> = (props) => {
  const classes = useStyles(props);
  const {
    totalGuestsMetric,
    fetchData,
    filterLoading,
    accounts,
    timeRange,
    selectedGuestTypeNames
  } = props;

  const { data, isLoading } = totalGuestsMetric;
  React.useEffect(() => {
    void fetchData(id);
  }, [filterLoading, accounts, timeRange.to, timeRange.from, selectedGuestTypeNames]);

  return (
    <>
      <Grid key={id} item xs={12} sm={6} lg={3}>
        <Card className={classes.fullHeight}>
          <CardContent>
            <Fact
              value={data}
              isLoading={isLoading}
              title={<FormattedMessage id='report.totalGuests' />}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  totalGuestsMetric: getTotalGuests(state),
  timeRange: state.reportFilter.timeRange,
  selectedGuestTypeNames: state.reportFilter.selectedGuestTypeNames,
  accounts: getSelectedAccountNames(state),
  filterLoading: state.reportFilter.loading
});

const mapDispatchToProps = {
  fetchData
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TotalGuestMetric));
