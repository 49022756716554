/* eslint-disable */

import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import '../index.scss';

// MATERIAL TABLE
import { Column, MTableBody, Options } from 'material-table';
import AlteredMaterialTable, { AlteredMaterialTableProps } from 'components/MaterialTable';

// ICONS
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';

// OVERWRITTEN MATERIAL TABLE COMPONENTS
import CustomTableBody from 'components/MaterialTable/components/customTableBody';
import CustomTableHead from 'components/MaterialTable/components/customTableHead';
import CustomEditRow from 'components/MaterialTable/components/customEditRow';

// MISC
import {
  colors,
  Button,
  TextField,
  Typography,
  Theme,
  createStyles,
  WithStyles
} from '@material-ui/core';

import { validate } from 'utils/validator';

import { scrollToEl } from 'utils/helpers';
import { isIE11 } from 'utils/browsers';
import { Slide } from '@material-ui/core';
import { Reason } from 'redux/ducks/reasons/types';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import {
  editRegistration,
  getMultipleRegistration,
  RegistrationActions,
  RegistrationStatus
} from 'redux/ducks/registration';
import RemoveIcon from '@material-ui/icons/Remove';
import { convertMassToViewValue, formatWeight, unformat } from 'utils/number-format';
import NumberInput from 'components/Input/NumberInput';
import * as registrationDispatch from 'redux/ducks/registration';
import ProductPlaceholder from 'static/img/product_placeholder.png';
import { withStyles } from '@material-ui/styles';

interface RowData {
  id: string | number;
  status: RegistrationStatus;
  amount: number;
  field: string;
  selected: boolean;
  name: string;
}

// MT options (https://material-table.com/#/docs/all-props)
const materialTableOptions = {
  toolbar: false,
  paging: false,
  disableToolbar: true,
  actionsColumnIndex: -1,
  showTitle: false,
  search: false,
  searchFieldAlignment: 'left',
  draggable: false,
  tableLayout: 'fixed',
  headerStyle: {
    width: '0%',
    backgroundColor: colors.grey['50'],
    padding: '5px 10px',
    fontSize: '15px',
    fontWeight: 'bold',
    fontFamily: 'Lato'
  },
  // need to override mui cell padding
  rowStyle: (rowData: RowData) => {
    return {
      //CRAS: IE11 has an issue with transitioning opacity on table rows
      transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
      borderBottom: 0,
      padding: 0,
      height: 70,
      backgroundColor: rowData.selected === true ? '#e0f2f1' : null
    };
  },
  actionsCellStyle: {
    width: '0%',
    whiteSpace: 'pre',
    padding: '5px 0px',
    height: 70,
    borderBottom: 0
  }
};

const NEW_OR_UPDATED_ROW = 'new-or-updated-row';
const deleteTransitionDuration = 1000;

type ActionFunction = (row: any) => {
  onClick: (event, rowData) => void;
  icon: () => any;
  tooltip: string;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StyleClasses = WithStyles<typeof styles>;

interface OverviewProps extends StateProps, DispatchProps, StyleClasses, InjectedIntlProps {}

class MultipleRegistrationTable extends React.Component<OverviewProps, {}> {
  deletedRow: any;
  tableRef = React.createRef<any>();

  // whitelistedKeys: Array including properties that we want to work with. Taken from old solution.
  private whitelistedKeys: string[];
  // newRow: Variable which we can use to determine if the row we're rendering is a newly added one. (Styling purposes)
  private newRow: number | string;
  // updatedRow: Just like newRow, this is used to determine if the row we're rendering has just been updated. (Styling purposes)
  private updatedRow: number | string;

  constructor(props: OverviewProps) {
    super(props);

    this.whitelistedKeys = ['name', 'skipAction', 'amount'];
  }

  shouldComponentUpdate(
    nextProps: Readonly<OverviewProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return nextProps.registrations !== this.props.registrations;
  }

  componentDidMount(): void {
    window.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keyup', this.onKeyUp);
  }

  /**
   * KeyUp handler: Cancels the Edit Row
   * */
  onKeyUp = (event): void => {
    if (event.which === 27) {
      // If Esc key is pressed
      const mode = this.tableRef.current.state.showAddRow ? 'add' : 'update';

      this.tableRef.current.onEditingCanceled(mode);
      this.onCancelHandler();
    }
  };

  /**
   * Takes the registration-points data coming from the endpoint, parses it,
   * and starts setting up the columns based on the fields.
   * @returns { Column[] } - array of columns that will be passed as a prop to MT
   * */
  extractColumnsFromData = (): Column<RowData>[] => {
    const enabledColumns = this.whitelistedKeys;

    return enabledColumns.map((key) => {
      switch (key) {
        case 'name':
          return this.setupNameColumn(key);
          break;
        case 'skipAction':
          return this.setupSkipColumn(key);
          break;
        case 'amount':
          return this.setupAmountColumn(key);
          break;
        default:
          return this.setupNameColumn(key);
      }
    });
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @param { key }
   * @returns { Column } - object including properties needed to custom-render the field in the table
   * */
  setupNameColumn = (key): Column<RowData> => {
    const { intl } = this.props;

    return {
      title: intl.messages['product'],
      field: key,
      cellStyle: {
        position: 'relative',
        width: '90%',
        whiteSpace: 'pre',
        height: '70px',
        padding: '5px',
        borderBottom: 0
      },
      headerStyle: {
        paddingLeft: 20,
        width: '70%'
      },
      editable: 'never',
      customSort: (a, b) => {
        return a.name.localeCompare(b.name, intl.locale, { sensitivity: 'base' });
      },
      render: this.renderProductColumn
    };
  };

  /**
   * Renders the data for the 'Name' column
   * @param { rowData } - row data
   * @returns { string | JSX.Element } - returns the value needed to be rendered, or the value together with an icon if it's a newly added row
   * */
  renderProductColumn = (rowData): string | JSX.Element => {
    this.newRow = this.newRow === rowData.id ? undefined : this.newRow;
    const { classes } = this.props;
    return (
      <div className={classes.cellAlign}>
        <div className={classes.productImageContainer}>
          <img
            className={classes.productImg}
            src={rowData.image || ProductPlaceholder}
            alt={'product image'}
          />
          <div
            className={classes.imageStatusOverlay}
            style={{ display: rowData.status === undefined ? 'none' : null }}
          />
          <div className={classes.imageIconOverlay}>
            <CheckIcon
              color='primary'
              style={{ display: rowData.status !== 'registered' ? 'none' : null }}
            />
            <RemoveIcon
              color='primary'
              style={{ display: rowData.status !== 'skipped' ? 'none' : null }}
            />
          </div>
        </div>
        <Typography className={classes.productLabel} variant='h4'>
          {rowData.name}
        </Typography>
      </div>
    );
  };

  /**
   * Renders the custom edit component for the 'Name' column
   * @param { props } - props
   * @param { key } - the key
   * @returns { JSX.Element } - the element that should be rendered instead of the default edit component provided by MT
   * */
  renderAmountEditMode = (props, key): JSX.Element => {
    let amountValue = props.value;
    let floatValue = formatWeight(amountValue);
    return (
      <NumberInput
        required
        value={floatValue}
        type={'text'}
        style={{ minWidth: '30px', marginTop: '5px' }}
        component={TextField}
        onChange={(e) => {
          props.onChange(unformat(e.target.value) * 1000);
        }}
        inputMode={'decimal'}
        allowNegative={false}
        min={0.01}
        name={`${key}_field`}
        onKeyDown={(event) => this.onInputKeyDown(event, props)}
        autoFocus={true}
      />
    );
  };

  setupAmountColumn = (key): Column<RowData> => {
    return {
      title: 'KG',
      field: key,
      cellStyle: {
        position: 'relative',
        width: '100%',
        whiteSpace: 'pre',
        height: '70px',
        textAlign: 'right',
        borderBottom: 0,
        display: 'flex',
        justifyContent: 'flex-end'
      },
      headerStyle: {
        paddingLeft: 20,
        width: '70%',
        textAlign: 'right'
      },
      sorting: false,
      render: this.renderAmountColumn,
      editComponent: (props) => this.renderAmountEditMode(props, key)
    };
  };

  renderAmountColumn = (rowData: RowData): string | JSX.Element => {
    const { id, status, amount } = rowData;
    const { classes } = this.props;
    this.newRow = this.newRow === id ? undefined : this.newRow;

    let value;
    if (status === 'skipped') {
      value = 'Skipped';
    } else {
      value = convertMassToViewValue(amount);
    }
    return (
      <div className={classes.cellAlign} style={{ display: status === undefined ? 'none' : null }}>
        <span className={classes.productAmount}>{value}</span>
      </div>
    );
  };

  setupSkipColumn = (key): Column<RowData> => {
    return {
      field: key,
      cellStyle: {
        width: '0%',
        padding: '5px 0px',
        position: 'relative',
        whiteSpace: 'pre',
        borderBottom: 0
      },
      headerStyle: {
        padding: '5px 0px'
      },
      sorting: false,
      render: null, // makes the skip show only on edit mode
      editComponent: (props) => this.renderSkipActionColumn(props, key)
    };
  };

  renderSkipActionColumn = (props, key): JSX.Element => {
    const { rowData } = props;
    return (
      <div key={key}>
        <Button
          style={{ textTransform: 'capitalize' }}
          color='primary'
          variant='outlined'
          onClick={() => this.skipRegistration(rowData)}
        >
          Skip
        </Button>
      </div>
    );
  };

  skipRegistration = (newData) => {
    const { tableData, ...rowData } = newData;
    this.tableRef.current.onEditingCanceled('update');
    this.resetHelperGlobals();

    if (rowData.status !== 'skipped') {
      this.props.skipRegistration(rowData);
    }
    return;
  };

  /*
   *
   *
   * */
  isValidData(rowData, key) {
    const validation = validate('registration-create-request', rowData);
    return !validation.erroneousKeys.has(key);
  }

  /**
   * Checks whether the user pressed Enter or Escape, and approve or cancel the editing depending on the action
   * @param { event }
   * @param { props }
   * */
  onInputKeyDown = (event, props): void => {
    const mode = 'update';

    if (event.which === 13) {
      if (props.rowData.name && props.rowData.name.trim().length) {
        this.tableRef.current.onEditingApproved(
          mode,
          props.rowData,
          this.tableRef.current.state.lastEditingRow
        );
        if (mode === 'update') {
          return this.onSubmitHandler(props.rowData);
        }
      }
    }
  };

  /**
   * Render an Edit Row
   *
   * @param { props }
   * */

  /**
   * Override the default MT table body with a custom one, and render it.
   * This is needed to block the default behaviour that renders an Edit row at the end or beginning of the table body.
   *
   * @param { props }
   * */
  overrideTableBody = (props): JSX.Element => {
    return <CustomTableBody {...props} />;
  };

  /**
   * Override the default MT table head with a custom one, and render it.
   *
   * @param { props }
   * */
  overrideTableHead = (props): JSX.Element => {
    return <CustomTableHead {...props} draggable={false} />;
  };

  /**
   * Override the default MT Overlay with an empty <span> in order to 'deactivate' it
   *
   * @param { props }
   * */
  overrideOverlay = (props): JSX.Element => <span />;

  /**
   * Override the default MT table edit row with a custom one, and render it.
   *
   * @param { props }
   * */
  overrideEditRow = (props): JSX.Element => {
    const slidingIn =
      props.data && props.data.id && props.data.id === this.deletedRow ? false : true;
    const slideDuration =
      props.data && props.data.id && props.data.id === this.deletedRow
        ? deleteTransitionDuration
        : 0;

    return (
      <Slide in={slidingIn} direction={'left'} timeout={slideDuration}>
        <CustomEditRow
          {...props}
          disableEdit={(registration: any) => !registration?.name?.trim()}
          onCancelHandler={this.onCancelHandler}
          onSubmitHandler={this.onSubmitHandler}
          hideCancelButton={true}
          cellStyle={{ borderBottom: 0 }}
        />
      </Slide>
    );
  };

  /**
   * Whenever an Edit row has been canceled, this callback is called
   *
   ** */
  onCancelHandler = (): void => {
    this.resetHelperGlobals();
  };

  /**
   * Whenever an Edit row has been approved (by Enter or clicking on the Check icon), this callback is called
   *
   * @param { data }
   **/
  onSubmitHandler = (data: RowData, mode?: string): void => {
    if (mode === 'delete') {
      this.deletedRow = data.id;
    } else {
      this.updatedRow = data.id;
    }
  };

  /*
   * Reset some helper variables. This gets called when we cancel an Edit row.
   *
   * */
  resetHelperGlobals = (): void => {
    this.deletedRow = undefined;
  };

  /**
   * Runs when an Edit row for an existing row has been 'confirmed',
   * by pressing Enter or clicking on the Check icon.
   * Here we make the call to the endpoint with the new data for the existing row.
   *
   * @param { newData }
   * */
  onRowUpdate = async (newData, oldData) => {
    const { tableData, ...rowData } = newData;

    // allow amount edit only when there is a new value OR status is skipped
    if (newData.amount !== oldData.amount || newData.status === 'skipped') {
      this.props.onUpdate({ ...rowData, id: oldData.id });
    }
    scrollToEl(NEW_OR_UPDATED_ROW);
    this.resetHelperGlobals();
    return;
  };

  render() {
    const { registrations, intl } = this.props;
    return (
      <AlteredMaterialTable
        noContentMessage={intl.messages['settings.noReasons']}
        title={intl.messages['settings.reasons.tableHeader']}
        tableRef={this.tableRef}
        // @ts-ignore
        columns={this.extractColumnsFromData()}
        data={registrations}
        // @ts-ignore
        options={materialTableOptions}
        components={{
          Header: this.overrideTableHead,
          Body: this.overrideTableBody,
          OverlayLoading: this.overrideOverlay,
          EditRow: this.overrideEditRow
        }}
        editable={{
          onRowUpdate: this.onRowUpdate
        }}
        style={{
          marginTop: '40px',
          width: '100%'
        }}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { nodesHistory } = state.registration;
  return {
    nodesHistory,
    registrations: getMultipleRegistration(state)
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, RegistrationActions>) => ({
  onUpdate: (data) => dispatch(registrationDispatch.editRegistration(data)),
  skipRegistration: (data) => dispatch(registrationDispatch.skipRegistrationAction(data))
});

const styles = (theme: Theme) =>
  createStyles({
    cellAlign: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '17px',
      textTransform: 'uppercase',
      maxWidth: '350px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '180px'
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '210px'
      }
    },
    productLabel: {
      margin: '0 0 0 12px',
      fontSize: '17px',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    imageStatusOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '72px',
      height: '72px',
      opacity: '0.8',
      backgroundColor: '#dddbdb',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageIconOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '72px',
      height: '72px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    productImageContainer: {
      position: 'relative',
      height: '72px',
      width: '72px'
    },
    productImg: {
      width: '72px',
      height: '72px'
    },
    productAmount: {
      margin: '0 0 -10px 0'
    }
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(MultipleRegistrationTable)));
