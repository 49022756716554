import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Typography, Grid, CardMedia, Divider, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SunIcon from 'components/icons/SunIcon';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import { getSettings } from 'redux/ducks/settings';
import SuccessModal from 'pages/Registration/SuccessModal';
import { selectLastRegistration, resetCurrentRegistrations } from 'redux/ducks/registration';
import playSound from 'utils/playSound';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { getReturnToModule, ModuleLink, redirectToModule } from 'redux/ducks/ui';

const timeoutInMs = 5000;
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type OwnProps = StateProps & DispatchProps & InjectedIntlProps;

const useStyles = makeStyles((theme) => ({
  value: {
    // this should match dashboard card metric value
    fontWeight: 800,
    fontSize: '26px',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center'
  },
  funFactContainer: {
    width: '100%'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  factImage: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
    objectFit: 'contain',
    height: '70px'
  },
  divider: {
    width: '100%',
    marginTop: '40px',
    marginBottom: '10px'
  }
}));

const RegistrationSuccessModal: React.FunctionComponent<OwnProps> = (props) => {
  const timer = useRef<NodeJS.Timeout>();
  const classes = useStyles(props);

  const {
    intl,
    resetCurrentRegistrations,
    sound,
    currentNodes,
    lastRegistration,
    registrationStarted,
    registrationDone,
    funFact,
    returnToModule,
    currency
  } = props;

  const { name, amount, date, offline } = lastRegistration || {};
  const { nameKey, image, amount: funFactAmount } = funFact || {};

  const shouldSuccessModalShow = name !== undefined && registrationStarted && registrationDone;

  useEffect(() => {
    if (shouldSuccessModalShow) {
      sound && sound.enabled && playSound(sound.url);

      if (!returnToModule) {
        timer.current = setTimeout(() => handleClose(), timeoutInMs);
        return () => clearTimeout(timer.current);
      }
    } else {
      handleClose();
    }
  }, [lastRegistration, currentNodes]);

  const handleClose = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (registrationDone && registrationStarted) {
      resetCurrentRegistrations();
    }
  };

  const handleModuleClick = (module: ModuleLink) => {
    void redirectToModule(module);
  };

  return shouldSuccessModalShow ? (
    <SuccessModal
      open={true}
      onClose={handleClose}
      title={intl.messages['registration.done']}
      subtitle={date}
      badgeIcon={<SunIcon />}
    >
      <Grid direction='column' className={classes.funFactContainer} container alignItems={'center'}>
        <Grid alignItems='center' direction='column' item container className={classes.center}>
          <Typography variant={'h1'} component={'h1'}>
            {`${amount / 1000} kg`}
          </Typography>
          {!offline && <Typography>{intl.messages['dashboard.foodwasteEqualsTo']}</Typography>}
        </Grid>

        {!offline && nameKey && (
          <Grid alignItems='center' direction='column' item container className={classes.center}>
            <CardMedia className={classes.factImage} component='img' image={image} alt={nameKey} />
            <Typography variant={'h1'} component={'h1'} align='center'>
              {intl.formatMessage({ id: nameKey }, { value: funFactAmount, currency })}
            </Typography>
          </Grid>
        )}

        {returnToModule && (
          <>
            <Divider className={classes.divider} />
            <Grid item container direction='row' justify='space-between'>
              <Button variant='text' onClick={handleClose}>
                {intl.messages['base.stayInFoodwaste']}
              </Button>
              <Button
                variant='contained'
                size='large'
                color='primary'
                onClick={() => handleModuleClick(returnToModule)}
              >
                {intl.messages['base.backToHaccp']}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </SuccessModal>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  expectedFoodwaste: getSettings(state).currentExpectedFoodWaste,
  sound: getSettings(state).sound,
  isMultiRegistrationDone: state.registration.areRegistrationsValid,
  lastRegistration: selectLastRegistration(state),
  currentNodes: state.registration.currentNodes,
  registrationStarted: state.registration.currentRegistrations.length > 0,
  registrationDone: state.registration.currentNodes.length < 1,
  displayMultipleRegistration: state.registration.displayMultipleRegistration,
  funFact: state.registration.funFact,
  returnToModule: getReturnToModule(state),
  currency: state.settings.currency
});

const mapDispatchToProps = { resetCurrentRegistrations };

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RegistrationSuccessModal));
