import * as React from 'react';
import { Grid, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Helmet } from 'react-helmet';
import { PageTitle } from 'pages/Report/components/ReportPageLayout';
import ReportJobDialog from './components/ReportJobDialog';
import ReportMailTable from './components/ReportMailTable';
import {
  ReportMail,
  useFetchReportMails,
  useUpdateReportMail,
  useCreateReportMail,
  useDeleteReportMail,
  useFetchReportMetrics
} from 'pages/Report/Mail/api';
import MailReportEditor from './components/MailReportEditor';
import HelpText from 'components/HelpText';
export interface reportJobDialog {
  isOpen: boolean;
  report: ReportMail;
}

export interface reportMailDialog {
  isOpen: boolean;
  report?: ReportMail;
}

const Mail: React.FunctionComponent<InjectedIntlProps> = (props) => {
  const classes = useStyles();
  const { intl } = props;

  const [reportJobDialog, setReportJobDialog] = React.useState<ReportMail>(null);
  const [mailReport, setMailReport] = React.useState<reportMailDialog>({
    isOpen: false,
    report: null
  });
  const [reports, setReports] = React.useState<ReportMail[]>([]);
  const fetchReportMails = useFetchReportMails();
  const fetchReportMetrics = useFetchReportMetrics();
  const createReportMail = useCreateReportMail();
  const updateMailReport = useUpdateReportMail();
  const deleteMailReport = useDeleteReportMail();

  React.useEffect(() => {
    if (fetchReportMails.isSuccess) {
      setReports(fetchReportMails.data);
    }
  }, [fetchReportMails.isSuccess]);

  React.useEffect(() => {
    if (updateMailReport.isSuccess) {
      setReports((prev) =>
        prev.map((report) =>
          report.id === updateMailReport.data.id ? { ...report, ...updateMailReport.data } : report
        )
      );
    }
  }, [updateMailReport.isSuccess]);

  React.useEffect(() => {
    if (createReportMail.isSuccess) {
      setReports((prev) => [...prev, createReportMail.data]);
    }
  }, [createReportMail.isSuccess]);

  React.useEffect(() => {
    if (deleteMailReport.isSuccess) {
      setReports((prev) => prev.filter((report) => report.id !== deleteMailReport.data.id));
    }
  }, [deleteMailReport.isSuccess]);

  const handleCreateReport = () => {
    setMailReport({ isOpen: true, report: null });
  };

  const handleCloseReportEditor = () => {
    setMailReport({ isOpen: false, report: null });
  };

  const handleCopy = (report: ReportMail) => {
    const { id, job, name, ...mailData } = report;
    const copyMailData = Object.assign(mailData, { name: `${name} (Copy)` });
    createReportMail.mutate(copyMailData);
  };

  const handleEdit = (report: ReportMail) => {
    setMailReport({ isOpen: true, report: report });
  };

  const handleToggleActive = (report: ReportMail) => {
    const update = {
      ...report,
      active: !report.active
    };
    updateMailReport.mutate(report, update);
  };

  const handleShowReportJobEditor = (report: ReportMail) => {
    setReportJobDialog(report);
  };

  const handleDelete = (report: ReportMail) => {
    deleteMailReport.mutate(report);
  };

  const handleJobChange = (report: ReportMail) => {
    setReports((prev) =>
      prev.map((reportMail) =>
        reportMail.id === report.id ? { ...reportMail, ...report } : reportMail
      )
    );
  };

  const handleReportsChange = (report: ReportMail) => {
    setReports((prev) => [...prev, report]);
  };
  return (
    <Grid container>
      {reportJobDialog ? (
        <ReportJobDialog
          isOpen={!!reportJobDialog}
          report={reportJobDialog}
          onChange={handleJobChange}
          onClose={() => setReportJobDialog(null)}
        />
      ) : null}
      {mailReport.isOpen && (
        <MailReportEditor
          metrics={fetchReportMetrics.data || []}
          isOpen={mailReport.isOpen}
          report={mailReport.report}
          onUpdate={handleJobChange}
          onCreate={handleReportsChange}
          onClose={handleCloseReportEditor}
        />
      )}
      <Helmet title={intl.messages['report.mail.title']} />
      <PageTitle>{intl.messages['report.mail.pageTitle']}</PageTitle>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item container spacing={3} className={classes.grid} alignItems='center'>
            <Grid item container xs={6} alignItems={'center'}>
              <Typography variant='h2' className={classes.header}>
                {intl.messages['report.mail.landingTitle']}
              </Typography>
              <HelpText helpText={intl.messages['report.mail.help']}>&nbsp;</HelpText>
            </Grid>
            <Grid item container xs={6} justify={'flex-end'}>
              <Button className={classes.button} startIcon={<Add />} onClick={handleCreateReport}>
                {intl.messages['report.mail.pageAction']}
              </Button>
            </Grid>
          </Grid>
          <Grid item container spacing={3} className={classes.grid}>
            <Grid item container xs={12}>
              <ReportMailTable
                reports={reports || []}
                onCopy={handleCopy}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onEditReportJob={handleShowReportJobEditor}
                onToggleActive={handleToggleActive}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 0 // fix negative margin overflowing parent with padding when using spacing
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  infoHeader: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  button: {
    background: 'rgb(0, 150, 136)',
    color: '#ffffff',
    width: '175px',
    height: '35px',
    '&:hover': {
      background: 'rgba(0, 150, 136, 0.4)'
    }
  },
  paper: {
    width: '100%',
    padding: '20px 20px'
  }
}));

export default injectIntl(Mail);
