import * as React from 'react';
import logo from '../../../static/icons/esmiley-logo-mobile.png';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingIcon from '@material-ui/icons/Settings';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DollarIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Divider } from '@material-ui/core';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { MobileLink } from 'components/SidebarMenu/MenuLink';
import LanguageSwitcher from '../../LanguageSwitcher';
import { ThunkDispatch } from 'redux-thunk';
import { Modal, UiActions } from 'redux/ducks/ui';
import * as uiDispatch from 'redux/ducks/ui';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAuth } from 'frontend-core';
import { makeStyles } from '@material-ui/core/styles';
import { changeLocale } from 'redux/ducks/settings';
import SalesDialog from 'components/modalContent/salesDialog';
import AccountSettings from 'pages/Account';
import { useFeature } from 'flagged';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  closeDrawer: () => void;
}

type IComponentProps = OwnProps & StateProps & DispatchProps & InjectedIntlProps;

function getIdentity(state: RootState): React.ReactNode {
  const {
    settings: { useAccountNickname },
    user
  } = state;

  const { nickname, customerName, accountId, email, username } = user;

  const name = useAccountNickname && nickname ? nickname : customerName;
  if (username) {
    const id = name ? name : email ? email : username;
    return (
      <>
        <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>{id}</span>
        <span style={{ marginTop: '5px', fontSize: '0.9rem' }}>
          {user.name && user.name} ({accountId})
        </span>
      </>
    );
  }
  return '';
}

const MobileDrawer: React.FunctionComponent<IComponentProps> = (props) => {
  const { closeDrawer, intl, changeLocale, openModal, identity, subscription, level, locale } =
    props;
  const { logout } = useAuth();

  const classes = styles(props);
  const [showSetting, setSetting] = React.useState(false);
  const isStarterOrEssential = subscription.type === 'starter' || subscription.type === 'essential';
  const hasSettings = useFeature('settings');
  const hasSales = useFeature('sales/full');
  const hasProject = useFeature('project/full');

  const handleShowSales = (e: React.MouseEvent) => {
    e.preventDefault();
    closeDrawer();
    openModal({
      content: <SalesDialog />,
      title: intl.messages['sales.dialog.headline']
    });
  };

  const handleToggleSetting = () => {
    setSetting(!showSetting);
  };

  const handleLogout = (): void => {
    void logout({ locale });
  };

  const addReportLink = useMediaQuery('(max-width:290px)');
  const mobileLinks = {
    primary: [
      {
        path: '#',
        text: intl.messages['account'],
        icon: <PersonIcon />,
        onClick: handleToggleSetting,
        active: true
      },
      {
        path: '/sales',
        text: intl.messages['mobile.navigation.sales'],
        icon: <DollarIcon />,
        onClick: handleShowSales,
        active: hasSales
      }
    ],
    secondary: [
      {
        path: '/project',
        text: intl.messages['mobile.navigation.project'],
        icon: <InsertDriveFileIcon />,
        onClick: closeDrawer,
        active: hasProject
      },
      {
        path: '/report',
        text: intl.messages['mobile.navigation.reports'],
        icon: <AssessmentIcon />,
        onClick: closeDrawer,
        active: addReportLink
      },
      {
        path: '/settings',
        text: intl.messages['mobile.navigation.settings'],
        icon: <SettingIcon />,
        onClick: closeDrawer,
        active: hasSettings
      }
    ],
    tertiary: [
      {
        href: 'https://support.esmiley.co/',
        text: intl.messages['mobile.navigation.help'],
        icon: <HelpOutlineIcon />,
        onClick: closeDrawer,
        active: true
      }
    ]
  };

  return (
    <>
      <div className={showSetting ? classes.mobileDrawerHeaderTabbed : classes.mobileDrawerHeader}>
        {showSetting && (
          <>
            <ArrowBackIcon onClick={handleToggleSetting} />
            <span className={classes.accountSettingTitle}>{intl.messages['account']}</span>
          </>
        )}
        {!showSetting && (
          <>
            <img className={classes.mobileDrawerHeaderImg} src={logo} alt={'esmiley-logo'} />
            <div className={classes.mobileDrawerHeaderInfo}>{identity}</div>
          </>
        )}
      </div>
      <div className={classes.mobileDrawerContent}>
        {showSetting && <AccountSettings isMobile={true} />}
        {!showSetting && (
          <div className={classes.contentDrawer}>
            {mobileLinks.primary.map((item, index) => {
              const { active, ...restProps } = item;
              return (
                item.active && (
                  <MobileLink
                    key={`${item.text}-${index}`}
                    text={item.text}
                    icon={item.icon}
                    {...restProps}
                    isInline
                  />
                )
              );
            })}
            <LanguageSwitcher
              onChange={changeLocale}
              className={classes.mobileDrawerLanguage}
              size='small'
            />
            <Divider className={classes.mobileDrawerDivider} />
            {mobileLinks.secondary.map((item, index) => {
              const { active, ...restProps } = item;
              if (item.path === '/settings' && level < 5) {
                return null;
              } else {
                return (
                  item.active && (
                    <MobileLink
                      key={`${item.text}-${index}`}
                      text={item.text}
                      icon={item.icon}
                      {...restProps}
                      isInline
                    />
                  )
                );
              }
            })}
            {!isStarterOrEssential && <Divider className={classes.mobileDrawerDivider} />}
            {mobileLinks?.tertiary.map((item, index) => {
              const { active, ...restProps } = item;
              return (
                item.active && (
                  <MobileLink
                    key={`${item.text}-${index}`}
                    text={item.text}
                    icon={item.icon}
                    {...restProps}
                    isInline
                  />
                )
              );
            })}
            <MobileLink
              text={intl.messages['mobile.navigation.signout']}
              icon={<ExitToAppIcon />}
              onClick={handleLogout}
              path={location.pathname}
              isInline
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  useAccountNickname: state.settings.useAccountNickname,
  identity: getIdentity(state),
  level: state.user.level,
  subscription: state.user.subscription,
  locale: state.settings.locale
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, UiActions>) => ({
  changeLocale: (locale: string) => dispatch(changeLocale(locale)),
  openModal: (modal: Modal) => {
    dispatch(uiDispatch.showModal(modal));
  }
});

const styles = makeStyles({
  mobileMenu: {
    width: '100%',
    display: 'flex'
  },
  mobileDrawer: {
    height: '100%'
  },
  mobileDrawerDivider: {
    margin: '5px 10px 10px 10px'
  },
  mobileDrawerHeader: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#2196f3',
    color: '#fbfdff',
    width: '100%',
    height: '66px',
    padding: '12.5px'
  },
  mobileDrawerHeaderTabbed: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#2196f3',
    color: '#fbfdff',
    width: '100%',
    height: '46px',
    padding: '12.5px'
  },
  mobileDrawerHeaderInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100',
    marginLeft: '15px'
  },
  mobileDrawerHeaderImg: {
    width: '20px',
    height: '20px'
  },
  mobileDrawerContent: {
    color: '#707070',
    fontFamily: 'inherit',
    padding: '16px 10px 0 10px',
    marginTop: '66px',
    overflowY: 'scroll',
    height: 'calc(100% - 120px)'
  },
  mobileDrawerLanguage: {
    paddingLeft: '17.5px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  contentDrawer: {
    backgroundColor: '#FFFFFF',
    padding: '5px',
    width: '100%',
    height: 'min-content',
    boxShadow: '-3px 5px 15px -3px rgba(0,0,0,0.1)'
  },
  accountSettingTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginLeft: '20px'
  }
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MobileDrawer));
