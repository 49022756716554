import * as React from 'react';
import { MobileLink } from 'components/SidebarMenu/MenuLink';
import HomeIcon from '@material-ui/icons/Home';
import ScaleIcon from 'components/icons/ScaleIcon';
import GuestIcon from 'components/icons/GuestIcon';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MenuIcon from '@material-ui/icons/Menu';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Drawer } from '@material-ui/core';
import TutorialPopper from 'components/Tutorial/TutorialPopper';
import MobileDrawer from './MobileDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter, WithRouterProps } from 'react-router';
import { useNetworkStatus } from 'frontend-core';

interface MobileMenuProps extends InjectedIntlProps, WithRouterProps {
  enableGuestRegistrationFlow: boolean;
  handleShowSales: (e: React.MouseEvent) => void;
  handleLogout: () => void;
  helpUrl: string;
}

interface MobileLinkItem {
  path: string;
  text: string;
  icon: React.ReactElement;
  disabled?: boolean;
}

const MobileMenu: React.FunctionComponent<MobileMenuProps> = (props) => {
  const { enableGuestRegistrationFlow, location: route, intl } = props;
  const [open, setOpen] = React.useState(false);
  const { online } = useNetworkStatus();

  const classes = styles(props);
  const toggleMenu = () => {
    if (!online) {
      return;
    }
    setOpen(!open);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const xss = useMediaQuery('(max-width:290px)');
  const mobileLinks = [
    {
      path: '/',
      text: intl.messages['mobile.navigation.home'],
      icon: <HomeIcon />
    },
    {
      path: '/registration',
      text: intl.messages['mobile.navigation.waste'],
      icon: <ScaleIcon />
    },
    {
      path: '/guest-registration',
      onClick: enableGuestRegistrationFlow,
      text: intl.messages['mobile.navigation.guests'],
      icon: <GuestIcon />
    },
    {
      path: '/report',
      text: intl.messages['mobile.navigation.reports'],
      icon: <AssessmentIcon />,
      disabled: !online
    }
  ] as MobileLinkItem[];

  const miniMobileLinks = [
    {
      path: '/',
      text: intl.messages['mobile.navigation.home'],
      icon: <HomeIcon />
    },
    {
      path: '/registration',
      text: intl.messages['mobile.navigation.waste'],
      icon: <ScaleIcon />
    },
    {
      path: '/guest-registration',
      onClick: enableGuestRegistrationFlow,
      text: intl.messages['mobile.navigation.guests'],
      icon: <GuestIcon />
    }
  ] as MobileLinkItem[];

  const routePath = route.pathname.includes('/') ? route.pathname : `/${route.pathname}`;

  return (
    <>
      {open && (
        <Drawer
          PaperProps={{ className: classes.paper }}
          onChange={toggleMenu}
          variant={'permanent'}
          open={true}
        >
          <div className={classes.paperInner}>
            <MobileDrawer closeDrawer={closeDrawer} />
          </div>
          <TutorialPopper /* here because of sidebar menu's high z-index */ />
        </Drawer>
      )}
      <div className={classes.mobileWrapper}>
        {!xss &&
          mobileLinks.map((item, index) => {
            return (
              <MobileLink
                key={`${item.text}-${index}`}
                path={item.path}
                text={item.text}
                icon={item.icon}
                onClick={closeDrawer}
                disabled={item.disabled}
                isActive={routePath === item.path && !open}
              />
            );
          })}
        {xss &&
          miniMobileLinks.map((item, index) => {
            return (
              <MobileLink
                key={`${item.text}-${index}`}
                path={item.path}
                text={item.text}
                icon={item.icon}
                onClick={closeDrawer}
                disabled={item.disabled}
                isActive={routePath === item.path && !open}
              />
            );
          })}
        <MobileLink
          href={'#'}
          onClick={toggleMenu}
          text={intl.messages['mobile.navigation.menu']}
          icon={<MenuIcon />}
          isActive={open}
          disabled={!online}
        />
      </div>
    </>
  );
};

const styles = makeStyles({
  paper: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    transition:
      'width 0.2s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.2s cubic-bezier(0.23, 1, 0.32, 1)',
    zIndex: 1100,
    border: 'none'
  },
  mobileWrapper: {
    width: '100%',
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: '#f9f9f9',
    zIndex: 10000,
    height: '70px',
    color: '#707070',
    boxShadow: '0px -3px 13px 0px rgba(0,0,0,0.11)',
    fontSize: '12px'
  },
  paperInner: {
    width: '100vw',
    height: '100%',
    backgroundColor: '#F9F9F9'
  }
});

export default withRouter(injectIntl(MobileMenu));
