import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { makeStyles } from '@material-ui/styles';

interface OwnProps {
  onAccept: (event: React.MouseEvent) => void;
  onDecline: (event: React.MouseEvent) => void;
  onCancel: () => void;
  title?: string;
  content: string;
  open?: boolean;
  isLoading?: boolean;
}

type SelectPeriodModalProps = InjectedIntlProps & OwnProps;

const useStyles = makeStyles({
  dialog: {
    padding: '0px!important'
  },
  title: {
    fontSize: '16px'
  },
  cancelButton: {
    marginRight: 'auto'
  }
});

const SelectPeriodModal: React.FunctionComponent<SelectPeriodModalProps> = (props) => {
  const classes = useStyles(props);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const { intl, title, content, onDecline, onAccept, onCancel, open, isLoading } = props;

  const handleDecline = (event: React.MouseEvent) => {
    setDisabled(true);
    try {
      onDecline(event);
      setDisabled(false);
    } catch (e) {
      setDisabled(false);
    }
  };

  const handleAccept = (event: React.MouseEvent) => {
    setDisabled(true);
    try {
      onAccept(event);
      setDisabled(false);
    } catch (e) {
      setDisabled(false);
    }
  };

  return isLoading ? (
    <Dialog open={open} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogContent className={classes.dialog}>
        <LoadingPlaceholder
          classes={{ title: classes.title }}
          title={intl.messages['settings.processingSubaccounts']}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog className={'dialog yesOrNoDialog'} open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} className={classes.cancelButton}>
          {intl.messages['base.cancel']}
        </Button>
        <Button disabled={disabled} onClick={handleAccept} variant='contained'>
          {intl.messages['yes']}
        </Button>
        <Button disabled={disabled} onClick={handleDecline} variant='contained' color='primary'>
          {intl.messages['no']}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(SelectPeriodModal);
