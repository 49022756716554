import * as React from 'react';
import RegistrationsPerDay from './RegistrationsPerDay';
import getChartData from 'pages/Report/components/Chart/utils/getChartData';
import { seriesMappers, themeMapper } from './utils/chartMappers';
import { connect } from 'react-redux';
import * as reportDispatch from 'redux/ducks/reportData';
import { useEffect } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { ReportActions } from 'redux/ducks/reportFilter';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { getAvailableAccounts } from 'redux/ducks/reportFilter/selectors';
import { PointData } from 'redux/ducks/reportData';
import { groupBy } from 'utils/array';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RegistrationsPerDayContainerProps = StateProps & DispatchProps & InjectedIntlProps;

const identifier = 'registrationsPerDay';

const RegistrationsPerDayContainer: React.FunctionComponent<RegistrationsPerDayContainerProps> = (
  props
) => {
  const { fetchData, filter, intl, registrationsPerDay, accounts } = props;
  const { data: chartsData, isLoading, error } = registrationsPerDay;

  useEffect(() => {
    void fetchData(identifier);
  }, [filter.basis, filter.dimension, filter.timeRange, filter.period, filter.filter]);

  const chartOptions = React.useMemo(() => {
    const byAccountId = groupBy(accounts, 'id');
    const series = chartsData.series.map(({ points, ...rest }) => {
      const withAccountName = points.map((point: PointData<{ onVacation?: string[] }>) => {
        const { extra, ...restPoint } = point;
        if (extra?.onVacation) {
          return {
            ...restPoint,
            extra: {
              onVacation: extra.onVacation.map((accountId) => byAccountId[accountId][0]?.name)
            }
          };
        }
        return point;
      });
      return { ...rest, points: withAccountName };
    });
    return getChartData(
      {
        series,
        intl
      },
      seriesMappers,
      themeMapper
    );
  }, [chartsData, accounts]);

  return <RegistrationsPerDay chartOptions={chartOptions} isLoading={isLoading} error={error} />;
};

const mapStateToProps = (state: RootState) => ({
  registrationsPerDay: state.reportData[identifier],
  filter: state.reportFilter,
  accounts: getAvailableAccounts(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  fetchData: (identifier: reportDispatch.ReportDataIdentifier) =>
    dispatch(reportDispatch.fetchData(identifier))
});

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RegistrationsPerDayContainer));
