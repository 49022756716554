import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Subscription } from 'redux/ducks/user';

export interface PlanProps extends InjectedIntlProps {
  subscription: Subscription;
}

const Plan: React.FunctionComponent<PlanProps> = ({ intl, subscription }) => {
  return (
    <p>{intl.formatMessage({ id: 'bootstrap.plan.description' }, { plan: subscription.type })}</p>
  );
};

export default injectIntl(Plan);
