import * as React from 'react';
import {
  ReportMail,
  ReportMailCategory,
  ReportMetric,
  ReportMetricAccountGroup,
  SelectedReportFilter,
  SelectedReportMetric,
  useCreateReportMail,
  useUpdateReportMail
} from 'pages/Report/Mail/api';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import EmailEditor from 'pages/Report/Mail/components/MailReportEditor/EmailEditor';
import MetricSelector from 'pages/Report/Mail/components/MailReportEditor/MetricSelector';
import { groupById } from 'utils/normalise';
import Filter from './Filter';
import { Dimension } from 'redux/ducks/reportFilter';
import { AccountGroup } from 'pages/Report/Mail/components/AccountGroupSelector';
import classNames from 'classnames';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';

export type DraftMetric = SelectedReportMetric;

export interface SelectedMetric {
  [id: string]: { selected: boolean } & DraftMetric;
}

type StateProps = ReturnType<typeof mapStateToProps>;

export interface MailReportEditorProps {
  isOpen: boolean;
  onClose: () => void;
  report?: ReportMail;
  metrics: ReportMetric[];
  onUpdate: (report: ReportMail) => void;
  onCreate: (report: ReportMail) => void;
}

type ComponentProps = StateProps & InjectedIntlProps & MailReportEditorProps;

export interface Email {
  header: string;
  body?: string;
}

export type DraftReportFilter = Omit<
  Partial<SelectedReportFilter>,
  'departments' | 'areas' | 'categories' | 'products' | 'departmentGroups'
>;

interface State {
  name: string;
  email: Email;
  filter: DraftReportFilter;
  metrics: SelectedMetric;
  dimensions: Dimension[];
  departmentGroups?: AccountGroup[];
  category: ReportMailCategory;
}

type FilterChange = {
  [key in keyof DraftReportFilter]: DraftReportFilter[key];
};

type EmailChange = {
  [key in keyof Email]: Email[key];
};

interface InitStateProps {
  report?: ReportMail;
  metrics: ReportMetric[];
  accountId: string;
}

const defaultDimensions = ['cost', 'weight', 'co2'] as Dimension[];

const mapApiGroupToAccountGroup = (apiGroup: ReportMetricAccountGroup): AccountGroup => {
  const { departments = [], areas = [], categories = [], products = [] } = apiGroup;

  return {
    departments: departments,
    area: areas,
    category: categories,
    product: products
  };
};

const mapAccountGroupToApiGroup = (apiGroup: AccountGroup): ReportMetricAccountGroup => {
  const { departments = [], area = [], category = [], product = [] } = apiGroup;

  return {
    departments: departments,
    areas: area,
    categories: category,
    products: product
  };
};

const initState = (props: InitStateProps): State => {
  const { report, metrics, accountId } = props;
  const noSelection = metrics.reduce(
    (all, curr) => ({
      ...all,
      [curr.id]: { selected: false, id: curr.id, dimensions: curr.setting.enabledDimensions }
    }),
    {}
  );

  if (!report) {
    return {
      name: '',
      email: {
        header: ''
      },
      metrics: noSelection,
      filter: { guestTypes: [], period: 'week' },
      departmentGroups: [{ departments: [accountId], area: [], category: [], product: [] }],
      dimensions: defaultDimensions,
      category: 'overview'
    };
  }

  const {
    name,
    header,
    body,
    metrics: selectedMetrics,
    filter: { departmentGroups = [], guestTypes = [], ...filter }
  } = report;

  return {
    name,
    email: {
      header,
      body
    },
    filter: {
      guestTypes,
      ...filter
    },
    departmentGroups: departmentGroups.map(mapApiGroupToAccountGroup),
    metrics: {
      ...noSelection,
      ...groupById(
        selectedMetrics.map((m) => ({
          ...m,
          selected: true
        }))
      )
    },
    dimensions: defaultDimensions,
    category: 'all'
  };
};

type ChangeFilterAction = {
  type: 'FilterChanged';
  payload: FilterChange;
};

type ChangeEmailAction = {
  type: 'EmailChanged';
  payload: EmailChange;
};

type ChangeNameAction = {
  type: 'NameChanged';
  payload: string;
};

type ChangeMetricAction = {
  type: 'MetricChanged';
  payload: SelectedMetric;
};

type ValidateAction = {
  type: 'Validated';
  payload: State;
};

type ChangeCategoryAction = {
  type: 'CategoryChanged';
  payload: ReportMailCategory;
};

type ChangeDimensionAction = {
  type: 'DimensionChanged';
  payload: Dimension[];
};

type ChangeAccountGroupAction = {
  type: 'AccountGroupChanged';
  payload: AccountGroup[];
};

type Action =
  | ChangeCategoryAction
  | ChangeAccountGroupAction
  | ChangeDimensionAction
  | ValidateAction
  | ChangeMetricAction
  | ChangeFilterAction
  | ChangeEmailAction
  | ChangeNameAction;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'MetricChanged': {
      return { ...state, metrics: { ...state.metrics, ...action.payload } };
    }
    case 'NameChanged': {
      return {
        ...state,
        name: action.payload
      };
    }
    case 'FilterChanged': {
      const update = { ...state.filter, ...action.payload };
      return {
        ...state,
        filter: update
      };
    }
    case 'EmailChanged': {
      const update = { ...state.email, ...action.payload };
      return {
        ...state,
        email: update
      };
    }
    case 'DimensionChanged': {
      const { metrics } = state;
      return {
        ...state,
        metrics: Object.keys(metrics).reduce<SelectedMetric>(
          (all, curr) => ({
            ...all,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            [curr]: metrics[curr].selected
              ? metrics[curr]
              : { ...metrics[curr], dimensions: action.payload }
          }),
          {}
        ),
        dimensions: action.payload
      };
    }
    case 'Validated': {
      return { ...state, ...action.payload };
    }
    case 'AccountGroupChanged': {
      return { ...state, departmentGroups: action.payload };
    }
    case 'CategoryChanged': {
      return { ...state, category: action.payload };
    }
    default: {
      return state;
    }
  }
};

const stateToReport = ({
  name,
  email,
  filter,
  departmentGroups,
  metrics
}: State): Partial<ReportMail> => {
  const groups = departmentGroups.map(mapAccountGroupToApiGroup);

  return {
    name,
    header: email.header,
    body: email.body,
    filter: {
      ...filter,
      departmentGroups: groups
    } as SelectedReportFilter,
    metrics: Object.keys(metrics)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .filter((key) => metrics[key].selected)
      .map((key) => {
        const { selected, ...metric } = metrics[key];
        return metric as SelectedReportMetric;
      })
  };
};

export const MailReportEditor: React.FunctionComponent<ComponentProps> = (props) => {
  const {
    report,
    metrics: metricsProps,
    onCreate,
    onUpdate,
    isOpen,
    onClose,
    accountId,
    intl
  } = props;
  const metrics = metricsProps.filter((metric) => metric.enabled === true);

  const updateOrCreate = report
    ? intl.messages['report.mail.createReport.update']
    : intl.messages['report.mail.createReport.execute'];
  const classes = styles();
  const [state, dispatch] = React.useReducer(reducer, initState({ report, metrics, accountId }));
  const createMail = useCreateReportMail();
  const updateMail = useUpdateReportMail();

  const isValid = () => {
    const { filter, metrics, name, email } = state;
    return (
      filter.period &&
      email.header.length > 0 &&
      name.length > 0 &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      Object.keys(metrics).some((key) => metrics[key].selected)
    );
  };

  React.useEffect(() => {
    if (updateMail.isSuccess) {
      onUpdate(updateMail.data);
      onClose();
    }
  }, [updateMail.isSuccess]);

  React.useEffect(() => {
    if (createMail.isSuccess) {
      onCreate(createMail.data);
      onClose();
    }
  }, [createMail.isSuccess]);

  const handleSave = () => {
    const update = { ...report, ...stateToReport(state) };
    if (report && report.id) {
      updateMail.mutate(report, update);
    } else {
      createMail.mutate(update);
    }
  };

  const handleFilterChange = (filterChange: FilterChange) => {
    dispatch({ type: 'FilterChanged', payload: filterChange });
  };

  const handleAccountGroupChange = (groups: AccountGroup[]) => {
    dispatch({ type: 'AccountGroupChanged', payload: groups });
  };

  const handleMetricChange = (metricChange: SelectedMetric) => {
    dispatch({ type: 'MetricChanged', payload: metricChange });
  };

  const handleEmailChange = (email: EmailChange) => {
    dispatch({ type: 'EmailChanged', payload: email });
  };

  const handleNameChange = (name: string) => {
    dispatch({ type: 'NameChanged', payload: name });
  };

  const handleDimensionChange = (dimensions: Dimension[]) => {
    dispatch({ type: 'DimensionChanged', payload: dimensions });
  };

  const handleCategoryChange = (category: ReportMailCategory) => {
    dispatch({ type: 'CategoryChanged', payload: category });
  };

  return createMail.isLoading || updateMail.isLoading || createMail.isSuccess ? (
    <Dialog open={isOpen} maxWidth={'md'} disableBackdropClick disableEscapeKeyDown fullWidth>
      <DialogContent>
        <LoadingPlaceholder
          classes={{ title: classes.title }}
          title={intl.messages['report.mail.loadingPlaceholder']}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'lg'} fullWidth>
      <div className={classes.dialogTitle}>
        <DialogTitle>{intl.messages['report.mail.recipientDialog.title']}</DialogTitle>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <DialogContentText className={classes.dialogSubtitle}>
        {intl.messages['report.mail.createReport.subtitle']}
      </DialogContentText>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <div className={classes.dialogContent}>
          <Grid item container>
            <EmailEditor
              reportName={state.name}
              email={state.email}
              onChangeName={handleNameChange}
              onChangeEmail={handleEmailChange}
            />
            <Grid item container xs={12} className={classes.reportFilter}>
              <Grid item xs={12}>
                <Typography variant='h2' component='h3'>
                  {intl.messages['report.mail.createReport.filterTitle']}
                </Typography>
                <Typography variant='body1' component='p'>
                  {intl.messages['report.mail.createReport.filterDescription']}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid item container>
          <Grid
            container
            item
            xs={12}
            className={classNames(classes.filterSlot, classes.dialogContent)}
          >
            <Filter
              hideComparisonGroups
              accountGroups={state.departmentGroups}
              onAccountGroupChange={handleAccountGroupChange}
              category={state.category}
              filter={state.filter}
              dimensions={state.dimensions}
              onFilterChange={handleFilterChange}
              onDimensionChange={handleDimensionChange}
            />
          </Grid>
          <Grid item container xs={12} className={classes.dialogContent}>
            <MetricSelector
              category={state.category}
              onCategoryChange={handleCategoryChange}
              accountGroups={state.departmentGroups}
              onAccountChange={handleAccountGroupChange}
              metrics={metrics}
              selected={state.metrics}
              onMetricChange={handleMetricChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant='text' onClick={onClose}>
          {intl.messages['base.cancel']}
        </Button>
        <Button variant='contained' color='primary' onClick={handleSave} disabled={!isValid()}>
          {updateOrCreate}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = makeStyles({
  filterSlot: {
    background: '#f6f6f6'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px'
  },
  dialogContentRoot: {
    padding: 0
  },
  dialogContent: {
    padding: '15px 20px'
  },
  input: {
    height: '1px !important',
    border: '1px solid rgb(0, 150, 136) !important',
    borderRadius: '5px !important'
  },
  dialogSubtitle: {
    marginLeft: '25px',
    marginRight: '25px',
    marginTop: '-15px'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontSize: '16px'
  },
  saveAction: {
    borderColor: 'rgb(0, 150, 136)',
    color: 'rgb(0, 150, 136)',
    marginRight: '20px',
    boxShadow: '-1px 7px 15px 4px rgba(0,0,0,0.1)'
  },
  reportFilter: {
    marginTop: '40px'
  }
});

const mapStateToProps = (state: RootState) => ({
  accountId: state.user.accountId
});

export default connect<StateProps, unknown, MailReportEditorProps>(mapStateToProps)(
  injectIntl(MailReportEditor)
);
