import { createSelectorCreator, defaultMemoize } from 'reselect';
import { formatMoney, formatWeight } from 'utils/number-format';
import { BenchmarkReport, getAvailableAccounts } from 'redux/ducks/reportFilter/selectors';
import isEqual from 'lodash/isEqual';
import { RootState } from 'redux/rootReducer';
import { sum } from 'utils/array';
import { SeriesData, ReportRegistration, PointData } from 'redux/ducks/reportData/types';
import { GuestRegistration } from 'redux/ducks/guestRegistrations';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export interface AdvancedReportSalesData {
  date: string;
  account: string;
  guests: number;
  portions: number;
  income: string;
  // below formatted strings
  foodwasteAmount: string;
  foodwasteCost: string;
  foodwasteCostPerGuest: string;
  foodwasteAmountPerGuest: string;
  foodwasteCostPerPortion: string;
  foodwasteAmountPerPortion: string;
  incomePerGuest: string;
  incomePerPortion: string;
}

export interface HighestLowestEmissionByCategory {
  highest: PointData & { unit: string };
  lowest: PointData & { unit: string };
}

export const getSalesRegistrations = createDeepEqualSelector(
  (state: RootState) => state.reportData.salesRegistrations,
  (state: RootState) => ({ currency: state.settings.currency, locale: state.settings.locale }),
  getAvailableAccounts,
  (registrations, settings, accounts): AdvancedReportSalesData[] => {
    return registrations.data.map((r) => ({
      date: r.date,
      account: accounts.find((account) => account.id === r.accountId).name,
      guests: r.guests,
      portions: r.portions,
      income: formatMoney(r.income).toString(),
      foodwasteAmount: formatWeight(r.foodwasteAmount, false, 'kg'),
      foodwasteCost: formatMoney(r.foodwasteCost).toString(),
      foodwasteCostPerGuest: formatMoney(r.foodwasteCostPerGuest).toString(),
      foodwasteAmountPerGuest: formatWeight(r.foodwasteAmountPerGuest, false, 'kg'),
      foodwasteCostPerPortion: formatMoney(r.foodwasteCostPerPortion).toString(),
      foodwasteAmountPerPortion: formatWeight(r.foodwasteAmountPerPortion, false, 'kg'),
      incomePerGuest: formatMoney(r.incomePerGuest).toString(),
      incomePerPortion: formatMoney(r.incomePerPortion).toString()
    }));
  }
);

export const getTotalGuests = createDeepEqualSelector(
  (state: RootState) => state.reportData.guestRegistrations,
  (guestRegistrations) => ({
    isLoading: guestRegistrations.isLoading,
    data: sum(guestRegistrations.data.map((data) => data.amount))
  })
);

export const getAvoidableMetrics = createDeepEqualSelector(
  (state: RootState) => state.reportData.registrations.data,
  (registrations) => {
    const yesAvoidableTotal = getAvoidableTotals(registrations, 'yes');
    const noAvoidable = getAvoidableTotals(registrations, 'no');
    const partlyAvoidable = getAvoidableTotals(registrations, 'partly');
    const totalAvoidable = yesAvoidableTotal + noAvoidable + partlyAvoidable;

    const avoidable: SeriesData = {
      aggregates: { total: totalAvoidable, avg: 0, min: 0, max: totalAvoidable },
      id: 'avoidable',
      points: [
        { label: 'report.metric.overview.avoidable', value: yesAvoidableTotal / 1000 },
        {
          label: 'registration.unavoidable',
          value: noAvoidable / 1000
        },
        { label: 'registration.avoidable.partially', value: partlyAvoidable / 1000 }
      ],
      unit: 'g'
    };
    return avoidable;
  }
);

const getAvoidableTotals = (data: ReportRegistration[], target: string) => {
  return data.reduce((acc, obj) => {
    const amount = obj.avoidable === target ? obj.amount : 0;
    return acc + amount;
  }, 0);
};

export const getReportReasonsWeight = createDeepEqualSelector(
  (state: RootState) => state.reportData['foodWasteReasons'],
  (reportReasonsData) => {
    const {
      data: { series }
    } = reportReasonsData;

    const reasonsWeightData = series[1];

    return {
      ...reasonsWeightData,
      points: reasonsWeightData
        ? reasonsWeightData.points.map((value) => ({ ...value, value: value.value / 1000 }))
        : []
    };
  }
);

const getTotalByArea = (area: string, registrations: ReportRegistration[]) => {
  const registrationsByArea = registrations.filter(
    (registration) => registration.registrationPoint.namePath?.[0] === area
  );
  return registrationsByArea.reduce((a, b) => {
    return a + b.amount;
  }, 0);
};

export const getBenchmarkedRegistrations = createDeepEqualSelector(
  (state: RootState) => state.reportData.registrations.data,
  getAvailableAccounts,
  (registrations, accounts): BenchmarkReport[] => {
    const distinctAreas = [
      ...new Set(
        registrations
          .filter(
            (registration) =>
              registration.registrationPoint && registration.registrationPoint.namePath
          )
          .map((r) => r.registrationPoint.namePath?.[0])
      )
    ];
    const distinctAccounts = [...new Set(registrations.map((g) => g.accountId))];
    const accountRows = accounts.filter((a) => distinctAccounts.includes(a.id));

    return accountRows.map((account) => {
      const registrationsByAccount = registrations.filter(
        (registration) => registration.accountId === account.id
      );
      const total = registrationsByAccount.reduce((a, b) => {
        return a + b.amount;
      }, 0);

      if (total > 0 || registrationsByAccount.length > 0) {
        const totalsByArea = distinctAreas.reduce((areaObj, area) => {
          return {
            ...areaObj,
            [area]: formatWeight(getTotalByArea(area, registrationsByAccount), false, 'kg')
          };
        }, {});

        return {
          accountName: account.name,
          total: formatWeight(total, false, 'kg'),
          ...totalsByArea
        };
      }
    });
  }
);

const getTotalByGuestTypeName = (guestTypeName: string, registrations: GuestRegistration[]) => {
  const registrationsByGuestType = registrations.filter(
    (guestRegistration) => guestRegistration.guestType?.name === guestTypeName
  );
  return registrationsByGuestType.reduce((a, b) => {
    return a + b.amount;
  }, 0);
};

export const getBenchmarkedGuestRegistrations = createDeepEqualSelector(
  (state: RootState) => state.reportData.guestRegistrations.data,
  getAvailableAccounts,
  (guests, accounts): BenchmarkReport[] => {
    const distinctGuestTypes = [
      ...new Set(guests.filter((guest) => guest.guestType).map((g) => g.guestType.name))
    ];
    const distinctAccounts = [...new Set(guests.map((g) => g.accountId))];
    const accountRows = accounts.filter((a) => distinctAccounts.includes(a.id));

    return accountRows.map((account) => {
      const registrationsByAccount = guests.filter(
        (guestRegistration) => guestRegistration.accountId === account.id
      );
      const total = registrationsByAccount.reduce((a, b) => {
        return a + b.amount;
      }, 0);

      if (total > 0 || registrationsByAccount.length > 0) {
        const totalsByGuestType = distinctGuestTypes.reduce((guestTypeObj, guestType) => {
          return {
            ...guestTypeObj,
            [guestType]: getTotalByGuestTypeName(guestType, registrationsByAccount).toString()
          };
        }, {});

        return {
          accountName: account.name,
          total: total.toString(),
          ...totalsByGuestType
        };
      }
    });
  }
);

export const getHighestLowestEmissionByCategory = createDeepEqualSelector(
  (state: RootState) => state.reportData['foodWasteOverview'],
  (totalWaste): HighestLowestEmissionByCategory => {
    const {
      data: {
        series: [totalSeries]
      }
    } = totalWaste;

    if (!totalSeries || !totalSeries.series) {
      return {
        highest: { value: 0, label: '', unit: 'g' },
        lowest: { value: 0, label: '', unit: 'g' }
      };
    }

    const categories = totalSeries.series.reduce(
      (flattenedCategories: PointData[], area) =>
        flattenedCategories.concat(...(area.points ?? [])),
      []
    );
    const products = categories.reduce(
      (flattenedProducts: PointData[], category) =>
        flattenedProducts.concat(...(category.subPoints ?? [])),
      []
    );
    const sorted =
      products.length > 0
        ? products.sort((a, b) => a.value - b.value)
        : categories.sort((a, b) => a.value - b.value);
    const highest = sorted[sorted.length - 1];
    const lowest = sorted[0];

    return {
      highest: { ...highest, unit: totalSeries.unit },
      lowest: { ...lowest, unit: totalSeries.unit }
    };
  }
);
