import { SeriesData } from 'redux/ducks/reportData';
import {
  SeriesMapper,
  SubPointData,
  SubPointSeries
} from 'pages/Report/components/Chart/utils/getChartData';

const seriesMappers: () => SeriesMapper[] = () => [
  (data) =>
    data.series.map(
      (series: SeriesData): SubPointSeries => ({
        type: undefined,
        data: series.points
          .filter((point) => point.value > 0)
          .map(
            ({ value, label, subPoints, ...rest }): SubPointData => ({
              ...rest,
              subPoints: subPoints?.map(({ value, label, ...rest }) => ({
                ...rest,
                y: value,
                name: label
              })),
              y: value,
              name:
                label.toLowerCase() === 'other' ? data.intl.messages['report.terms.other'] : label
            })
          ),
        name: series.name
          ? series.name.toLowerCase() === 'other'
            ? data.intl.messages['report.terms.other']
            : series.name
          : undefined
      })
    )
];

export default seriesMappers;
