import * as React from 'react';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControlLabel
} from '@material-ui/core';
import HelpText from 'components/HelpText';
import Container from 'components/Container';
import RefreshIcon from '@material-ui/icons/Refresh';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FailedPlaceholder from 'components/FailedPlaceholder';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RootState } from 'redux/rootReducer';
import {
  updateSubAccounts as updateSubAccountsActionCreator,
  fetchSubAccounts as fetchSubAccountsActionCreator
} from '../../../../../redux/ducks/subAccounts';

import './index.scss';
import { ThunkDispatch } from 'redux-thunk';
import ChangeIndicator from './changeIndicator';
import { SubAccount, SubAccountActions } from 'redux/ducks/subAccounts/types';
import {
  update as updateSettingsActionCreator,
  getSettings
} from '../../../../../redux/ducks/settings';
import {
  NotificationActions,
  showNotification as showNotificationActionCreator
} from 'redux/ducks/notification';

import { useEffect, useMemo, useRef, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type SubscriptionSettingsProps = StateProps & DispatchProps & InjectedIntlProps;

type AccountId = SubAccount['id'];

interface AccountsDraft {
  subscribed: AccountId[];
  unsubscribed: AccountId[];
}

const parseDraftFromAccountList = (accounts: SubAccount[]): AccountId[] => {
  return accounts.map((account) => account.id);
};

const SubAccountSettings: React.FC<SubscriptionSettingsProps> = (props) => {
  const {
    fetchSubAccounts,
    updateSubAccounts,
    intl,
    unsubscribed,
    subscribed,
    state,
    useAccountNickname,
    toggleShowNickname,
    showNotification
  } = props;

  const ActionIsPending = state === 'pending';
  const pendingActionRef = useRef(false);

  useEffect(() => {
    if (state === 'loaded' && pendingActionRef.current === true) {
      pendingActionRef.current = false;
      showNotification(
        intl.messages['settings.accounts.hasBeenSaved'],
        <div className='icon'>
          <CheckIcon />
        </div>
      );
    }
  }, [state]);

  const [draft, setDraft] = useState<AccountsDraft>({
    subscribed: parseDraftFromAccountList(subscribed),
    unsubscribed: parseDraftFromAccountList(unsubscribed)
  });

  const subAccountMap = useMemo(() => {
    const map = new Map<string, SubAccount>();
    subscribed.forEach((account) => map.set(account.id, account));
    unsubscribed.forEach((account) => map.set(account.id, account));
    return map;
  }, [subscribed, unsubscribed]);

  const handleSubmit = () => {
    pendingActionRef.current = true;
    void updateSubAccounts(draft.subscribed);
  };

  const handleRefresh = () => {
    void fetchSubAccounts();
  };

  const handleAddAll = () => {
    setDraft({
      subscribed: [...draft.subscribed, ...draft.unsubscribed],
      unsubscribed: []
    });
  };

  const handleRemoveAll = () => {
    setDraft({
      unsubscribed: [...draft.subscribed, ...draft.unsubscribed],
      subscribed: []
    });
  };

  const handleUnsubscribe = (id: AccountId) => {
    setDraft((prev) => ({
      subscribed: prev.subscribed.filter((accountId) => accountId !== id),
      unsubscribed: [...prev.unsubscribed, id]
    }));
  };

  const handleSubscribe = (id: AccountId) => {
    setDraft((prev) => ({
      unsubscribed: prev.unsubscribed.filter((accountId) => accountId !== id),
      subscribed: [...prev.subscribed, id]
    }));
  };

  if (state === 'init' || state === 'loading') {
    return <LoadingPlaceholder />;
  }

  if (subscribed.length === 0 && unsubscribed.length === 0) {
    return (
      <FailedPlaceholder
        className={'placeholder empty-data'}
        title={intl.messages['report.no_data.title']}
        description={intl.messages['settings.accounts.noDataToShow']}
      />
    );
  }

  return (
    <div className='accountsSettings'>
      <div className={'settingToggle'}>
        <HelpText helpText={intl.messages['help.settings.accountNameFormat']} />
        <FormControlLabel
          control={
            <div className='control'>
              <ChangeIndicator
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  void toggleShowNickname(event.target.value === 'true');
                }}
              >
                <Select
                  className='fullWidth'
                  name='useAccountNickname'
                  value={useAccountNickname.toString()}
                >
                  <MenuItem value={'false'}>
                    {intl.messages['settings.accounts.nameDisplayFormats.company']}
                  </MenuItem>
                  <MenuItem value={'true'}>
                    {intl.messages['settings.accounts.nameDisplayFormats.nickname']}
                  </MenuItem>
                </Select>
              </ChangeIndicator>
            </div>
          }
          label={
            <span className={'useAccountNickname__label'}>
              {intl.messages['settings.accounts.displayFormatOfAccountNames']}
            </span>
          }
        />
      </div>
      <Toolbar
        className='toolBar'
        style={{ backgroundColor: 'transparent', paddingLeft: '12px', marginBottom: '24px' }}
        disableGutters
      >
        <Button disabled={ActionIsPending} startIcon={<RefreshIcon />} onClick={handleRefresh}>
          {intl.messages['base.reload']}
        </Button>
        <Button
          variant='contained'
          color='primary'
          className='btn'
          disabled={ActionIsPending}
          type='button'
          onClick={handleSubmit}
        >
          {intl.messages['base.save']}
        </Button>
      </Toolbar>
      <div>
        <Grid
          container
          spacing={10}
          style={{
            height: 'calc(80vh - 56px)'
          }}
        >
          <Grid
            item
            xs={5}
            md={5}
            style={{
              maxHeight: '100%'
            }}
          >
            <Container
              className='listContainer'
              title={
                <HelpText helpText={intl.messages['help.settings.subscribed']}>
                  {intl.messages['settings.accounts.subscribedAccounts']}
                </HelpText>
              }
            >
              <div className='listInnerContainer'>
                <List>
                  {draft.subscribed.map((accountId) => (
                    <ListItem
                      key={accountId}
                      className='listItem listItemSubscribed'
                      onClick={() => {
                        handleUnsubscribe(accountId);
                      }}
                    >
                      <ListItemText
                        primary={subAccountMap.get(accountId).name}
                        secondary={accountId}
                      />
                      <ListItemIcon>
                        <ChevronRightIcon className='icon' />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Container>
          </Grid>
          <Grid item xs={2} md={2}>
            <div className='moveAllButtonsContainer'>
              <Button startIcon={<ChevronLeftIcon />} onClick={handleAddAll}>
                {intl.messages['settings.accounts.addAll']}
              </Button>
              <Button startIcon={<ChevronRightIcon />} onClick={handleRemoveAll}>
                {intl.messages['settings.accounts.removeAll']}
              </Button>
            </div>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            style={{
              maxHeight: '100%'
            }}
          >
            <Container
              className='listContainer'
              title={
                <HelpText helpText={intl.messages['help.settings.notSubscribed']}>
                  {intl.messages['settings.accounts.notSubscribedAccounts']}
                </HelpText>
              }
            >
              <div className='listInnerContainer'>
                <List>
                  {draft.unsubscribed.map((accountId) => (
                    <ListItem
                      key={accountId}
                      className={'listItem listItemNotSubscribed'}
                      onClick={() => {
                        handleSubscribe(accountId);
                      }}
                    >
                      <ListItemIcon>
                        <ChevronLeftIcon className='icon' />
                      </ListItemIcon>
                      <ListItemText
                        primary={subAccountMap.get(accountId).name}
                        secondary={accountId}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  subscribed: state.subAccounts.subscribed,
  unsubscribed: state.subAccounts.unsubscribed,
  state: state.subAccounts.state,
  useAccountNickname: getSettings(state).useAccountNickname
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, SubAccountActions | NotificationActions>
) => ({
  showNotification: (message: string, icon: JSX.Element) =>
    dispatch(showNotificationActionCreator(message, false, icon)),
  toggleShowNickname: (enabled: boolean) =>
    dispatch(
      updateSettingsActionCreator({
        useAccountNickname: enabled
      })
    ),
  fetchSubAccounts: () => dispatch(fetchSubAccountsActionCreator()),
  updateSubAccounts: (subscribed: SubAccount['id'][]) =>
    dispatch(updateSubAccountsActionCreator(subscribed))
});
export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SubAccountSettings));
