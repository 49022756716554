/* eslint-disable */

import * as React from 'react';
import { connect } from 'react-redux';
import { InjectedIntlProps, injectIntl } from 'react-intl';

// MATERIAL TABLE
import { Column } from 'material-table';
import AlteredMaterialTable from 'components/MaterialTable';

// OVERWRITTEN MATERIAL TABLE COMPONENTS
import CustomTableBody from 'components/MaterialTable/components/customTableBody';
import CustomTableHead from 'components/MaterialTable/components/customTableHead';
import CustomEditRow from 'components/MaterialTable/components/customEditRow';

// MISC
import {
  colors,
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { WithWidth } from '@material-ui/core/withWidth';

import { scrollToEl } from 'utils/helpers';
import { isIE11 } from 'utils/browsers';
import { Slide, Grid, Typography, Card } from '@material-ui/core';
import { Registration } from 'redux/ducks/data/registrations';
import { RootState } from 'redux/rootReducer';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import {
  CreateFreeRegistration,
  editRegistrationTable,
  getFreePlanRegistrationHistory
} from 'redux/ducks/registration';
import { convertMassToViewValue, unformat } from 'utils/number-format';
import moment from 'moment';
import * as registrationDispatch from 'redux/ducks/registration';
import { formatMass } from 'components/FormattedMass';
import NumberInput from 'components/Input/NumberInput';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  onUpdate(arg: any): void;
}

// MT options (https://material-table.com/#/docs/all-props)
const materialTableOptions = {
  actionsColumnIndex: -1,
  showTitle: true,
  search: false,
  searchFieldAlignment: 'left',
  emptyRowsWhenPaging: false,
  paginationType: 'stepped',
  toolbarButtonAlignment: 'right',
  headerStyle: {
    padding: '0px',
    fontWeight: 400
  },
  rowStyle: {
    //CRAS: IE11 has an issue with transitioning opacity on table rows
    transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
    borderBottom: `1px solid ${colors.grey['200']}`,
    height: 55
  },
  pageSize: 25,
  pageSizeOptions: [10, 15, 20, 25],
  actionsCellStyle: {
    whiteSpace: 'pre'
  }
};

const styles = createStyles({
  tableContainer: {
    padding: '12px 10px',
    width: '90%',
    maxWidth: '480px',
    backgroundColor: 'white'
  }
});

type HistoryTableProps = DispatchProps &
  InjectedIntlProps &
  WithWidth &
  StateProps &
  OwnProps &
  WithStyles<typeof styles>;

const NEW_OR_UPDATED_ROW = 'new-or-updated-row';
const deleteTransitionDuration = 1000;

type ActionFunction = (row: any) => {
  onClick: (event, rowData) => void;
  icon: () => any;
  tooltip: string;
};

class StarterHistoryTable extends React.Component<HistoryTableProps, {}> {
  deletedRow: any;
  tableRef = React.createRef<any>();

  // customActions: Custom actions to be added besides the default ones 'Edit' & 'Delete', on each table row
  private customActions: ActionFunction[];

  // whitelistedKeys: Array including properties that we want to work with. Taken from old solution.
  private whitelistedKeys: string[];
  // newRow: Variable which we can use to determine if the row we're rendering is a newly added one. (Styling purposes)
  private newRow: number | string;
  // updatedRow: Just like newRow, this is used to determine if the row we're rendering has just been updated. (Styling purposes)
  private updatedRow: number | string;

  constructor(props: HistoryTableProps) {
    super(props);

    this.whitelistedKeys = ['date', 'amount', 'avoidable'];
  }

  componentDidMount(): void {
    window.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keyup', this.onKeyUp);
  }

  /**
   * KeyUp handler: Cancels the Edit Row
   * */
  onKeyUp = (event): void => {
    if (event.which === 27) {
      // If Esc key is pressed
      const mode = this.tableRef.current.state.showAddRow ? 'add' : 'update';

      this.tableRef.current.onEditingCanceled(mode);
      this.onCancelHandler();
    }
  };

  /**
   * Takes the registration-points data coming from the endpoint, parses it,
   * and starts setting up the columns based on the fields.
   * @returns { Column[] } - array of columns that will be passed as a prop to MT
   * */
  extractColumnsFromData = (): Column<Registration>[] => {
    const enabledColumns = this.whitelistedKeys;

    return enabledColumns.map((key) => {
      switch (key) {
        case 'date':
          return this.setupDateColumn(key);
          break;
        case 'avoidable':
          return this.setupAvoidableColumn(key);
          break;
        case 'amount':
          return this.setupAmountColumn(key);
          break;

        default:
          return this.setupDefaultColumn(key);
      }
    });
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @returns { Column } - object including properties needed to custom-render the field in the table
   * */
  setupDefaultColumn = (key): Column<Registration> => {
    const { intl } = this.props;

    return {
      title: intl.messages[key],
      field: key
    };
  };

  setupAvoidableColumn = (key): Column<Registration> => {
    const { intl } = this.props;
    const getIntlKey = (avoidable) =>
      avoidable === 'partly' ? 'registration.avoidable.partially' : avoidable;
    const renderValue = (intlKey) => (intlKey ? intl.messages[intlKey] : '-');

    return {
      title: intl.messages['base.avoidable'],
      field: key,
      cellStyle: {
        position: 'relative',
        width: '30%',
        whiteSpace: 'pre',
        height: '70px'
      },
      headerStyle: {
        paddingLeft: 15,
        width: '30%'
      },
      render: this.renderAvoidableColumn,
      editComponent: (props) => this.renderAvoidableEditMode(props, key),
      customSort: (a, b) =>
        renderValue(getIntlKey(a.avoidable)).localeCompare(renderValue(getIntlKey(b.avoidable))),
      defaultSort: 'desc'
    };
  };

  renderAvoidableColumn = (rowData): string | JSX.Element => {
    const { intl } = this.props;
    const intlKey =
      rowData.avoidable === 'partly' ? 'registration.avoidable.partially' : rowData.avoidable;
    this.newRow = this.newRow === rowData.id ? undefined : this.newRow;

    return (
      <>
        <span>{rowData.avoidable ? intl.messages[intlKey] : '-'}</span>
      </>
    );
  };

  renderAvoidableEditMode = (props, key): JSX.Element => {
    const { intl } = this.props;
    const { value = '' } = props;
    return (
      <FormControl
        style={{
          width: '100%',
          marginTop: -6,
          verticalAlign: 'bottom',
          height: '48px'
        }}
      >
        <InputLabel id='avoidable-label' title={intl.messages['base.avoidable']} shrink required>
          {intl.messages['base.avoidable']}
        </InputLabel>
        <Select
          required
          autoWidth
          autoFocus
          value={value}
          name='avoidable'
          labelId='avoidable-label'
          onChange={(e, child: React.ReactNode) => {
            props.onChange(e.target.value);
          }}
        >
          {['yes', 'partly', 'no'].map((element: string, index: number) => (
            <MenuItem value={element} key={`label_${index}`} selected={element === value}>
              {element === 'partly'
                ? intl.messages['registration.avoidable.partially']
                : intl.messages[element]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @param { key }
   * @returns { Column } - object including properties needed to custom-render the field in the table
   * */
  setupDateColumn = (key): Column<Registration> => {
    const { intl } = this.props;

    return {
      title: intl.messages[key],
      field: key,
      cellStyle: {
        position: 'relative',
        width: '30%',
        whiteSpace: 'pre',
        height: '70px'
      },
      editable: 'never',
      headerStyle: {
        paddingLeft: 15,
        width: '30%'
      },
      render: this.renderDateColumn,
      defaultSort: 'desc',
      customSort: (a, b) => {
        const diff = new Date(a.date).valueOf() - new Date(b.date).valueOf();
        if (diff !== 0) {
          return diff;
        }

        return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
      }
    };
  };

  /**
   * Renders the data for the 'Name' column
   * @param { rowData } - row data
   * @returns { string | JSX.Element } - returns the value needed to be rendered, or the value together with an icon if it's a newly added row
   * */
  renderDateColumn = (rowData): string | JSX.Element => {
    const isNewRow = this.newRow === rowData.id;
    const transitionDuration = isNewRow ? '0.3' : '1';
    const opacityValue = isNewRow ? 1 : 0;
    const checkIconStyle = {
      transition: 'opacity ' + transitionDuration + 's ease-out 1s',
      opacity: opacityValue,
      width: 20,
      height: 20,
      verticalAlign: 'bottom',
      marginLeft: 10
    };

    this.newRow = this.newRow === rowData.id ? undefined : this.newRow;

    return (
      <>
        <span>{moment(rowData.date).format('L')}</span>
      </>
    );
  };

  setupAmountColumn = (key): Column<Registration> => {
    const { intl } = this.props;

    return {
      title: `${intl.messages[key]} kg`,
      field: key,
      cellStyle: {
        position: 'relative',
        width: '5%',
        whiteSpace: 'pre',
        height: '70px'
      },
      headerStyle: {
        paddingLeft: 15,
        width: '5%',
        textAlign: 'end',
        whiteSpace: 'nowrap'
      },
      render: this.renderAmountColumn,
      editComponent: (props) => this.renderAmountEditMode(props, key)
    };
  };

  renderAmountColumn = (rowData): string | JSX.Element => {
    this.newRow = this.newRow === rowData.id ? undefined : this.newRow;

    return (
      <>
        <span>{rowData.amount ? convertMassToViewValue(rowData.amount) : '-'}</span>
      </>
    );
  };

  /**
   * Renders the custom edit component for the 'Name' column
   * @param { props } - props
   * @param { key } - the key
   * @returns { JSX.Element } - the element that should be rendered instead of the default edit component provided by MT
   * */
  renderAmountEditMode = (props, key): JSX.Element => {
    const { intl } = this.props;
    let amountValue = props.value;

    let floatValue = amountValue ? amountValue / 1000 : '';

    return (
      <NumberInput
        required
        component={TextField}
        InputLabelProps={{ shrink: true, title: intl.messages[`${key}`] }}
        type={'text'}
        min={0.01}
        inputMode='decimal'
        value={floatValue}
        allowNegative={false}
        name={`${key}_field`}
        style={{ marginTop: '-6px' }}
        label={intl.messages[`${key}`]}
        onKeyDown={(event) => this.onInputKeyDown(event, props)}
        onChange={(e) => {
          props.onChange(unformat(e.target.value) * 1000);
        }}
      />
    );
  };

  /**
   * Checks whether the user pressed Enter or Escape, and approve or cancel the editing depending on the action
   * @param { event }
   * @param { props }
   * */
  onInputKeyDown = (event, props): void => {
    const mode = 'update';

    if (event.which === 13) {
      if (props.rowData.amount) {
        this.tableRef.current.onEditingApproved(
          mode,
          props.rowData,
          this.tableRef.current.state.lastEditingRow
        );
        return this.onSubmitHandler(props.rowData);
      }
    }
  };

  /**
   * Render an Edit Row
   *
   * @param { props }
   * */

  /**
   * Override the default MT table body with a custom one, and render it.
   * This is needed to block the default behaviour that renders an Edit row at the end or beginning of the table body.
   *
   * @param { props }
   * */
  overrideTableBody = (props): JSX.Element => {
    return <CustomTableBody {...props} />;
  };

  /**
   * Override the default MT table head with a custom one, and render it.
   *
   * @param { props }
   * */
  overrideTableHead = (props): JSX.Element => {
    return <CustomTableHead {...props} draggable={false} />;
  };

  /**
   * Override the default MT Overlay with an empty <span> in order to 'deactivate' it
   *
   * @param { props }
   * */
  overrideOverlay = (props): JSX.Element => <span />;

  /**
   * Override the default MT table edit row with a custom one, and render it.
   *
   * @param { props }
   * */
  overrideEditRow = (props): JSX.Element => {
    const slidingIn =
      props.data && props.data.id && props.data.id === this.deletedRow ? false : true;
    const slideDuration =
      props.data && props.data.id && props.data.id === this.deletedRow
        ? deleteTransitionDuration
        : 0;

    return (
      <Slide in={slidingIn} direction={'left'} timeout={slideDuration}>
        <CustomEditRow
          {...props}
          disableEdit={(registration: any) => !registration.avoidable || !registration.amount}
          onCancelHandler={this.onCancelHandler}
          onSubmitHandler={this.onSubmitHandler}
        />
      </Slide>
    );
  };

  /**
   * Whenever an Edit row has been canceled, this callback is called
   *
   ** */
  onCancelHandler = (): void => {
    this.resetHelperGlobals();
  };

  /**
   * Whenever an Edit row has been approved (by Enter or clicking on the Check icon), this callback is called
   *
   * @param { data }
   **/
  onSubmitHandler = (data: Registration, mode?: string): void => {
    if (mode === 'delete') {
      this.deletedRow = data.id;
    } else {
      this.updatedRow = data.id;
    }
  };

  /*
   * Reset some helper variables. This gets called when we cancel an Edit row.
   *
   * */
  resetHelperGlobals = (): void => {
    this.deletedRow = undefined;
  };

  /*
   * Reset some helper variables. This gets called when we cancel an Edit row.
   *
   * */

  /**
   * Clicking on a row triggers the Edit mode on that specific row
   *
   * @param { event }
   * @param { rowData }
   * */
  onRowClick = async (event, rowData) => {
    if (
      !event.target.classList.contains('tooltip') &&
      !event.target.parentNode.classList.contains('tooltip')
    ) {
      this.tableRef.current.dataManager.changeRowEditing(rowData, 'update');
      this.hideAddNewRow();
      return;
    }
  };

  /**
   * Hides any existing edit rows which have the function of adding a new row
   *
   * */
  hideAddNewRow = () => {
    this.resetHelperGlobals();
    this.tableRef.current.setState({
      ...this.tableRef.current.dataManager.getRenderState(),
      showAddRow: false
    });
  };

  /**
   * Runs when an Edit row for an existing row has been 'confirmed',
   * by pressing Enter or clicking on the Check icon.
   * Here we make the call to the endpoint with the new data for the existing row.
   *
   * @param { newData }
   * */
  onRowUpdate = async (newData, oldData) => {
    const { tableData, ...rowData } = newData;
    // new registration has no registration point
    if (!rowData.registrationPoint) {
      this.props.register({ ...rowData, weight: formatMass(rowData.amount) });
    } else {
      this.props.onUpdate({ ...rowData, id: oldData.id });
    }
    scrollToEl(NEW_OR_UPDATED_ROW);
    this.resetHelperGlobals();
    return;
  };

  render() {
    const { registrations, intl, loading, classes } = this.props;

    return loading === true ? (
      <LoadingPlaceholder />
    ) : (
      <Grid container justify={'center'}>
        <Card className={classes.tableContainer}>
          <Typography align='center' component='h1' variant='h3'>
            {intl.messages['history']}
          </Typography>
          <AlteredMaterialTable
            tableRootStyle={{ minWidth: '460px' }}
            noContentMessage={intl.messages['registration.noregistrations']}
            tableRef={this.tableRef}
            // @ts-ignore
            columns={this.extractColumnsFromData()}
            title={''}
            data={registrations}
            // @ts-ignore
            options={materialTableOptions}
            onRowClick={this.onRowClick}
            components={{
              Header: this.overrideTableHead,
              Body: this.overrideTableBody,
              OverlayLoading: this.overrideOverlay,
              EditRow: this.overrideEditRow
            }}
            editable={{
              onRowUpdate: this.onRowUpdate
            }}
            style={{
              width: '100%'
            }}
          />
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { registrations } = state.data;
  const freeRegistrations = getFreePlanRegistrationHistory(state);
  return {
    loading: registrations.loading && freeRegistrations.length === 0,
    registrations: freeRegistrations
  };
};

const mapDispatchToProps = (dispatch) => ({
  register: (registration: CreateFreeRegistration) =>
    dispatch(registrationDispatch.registerFreePlan(registration)),
  onUpdate: (data) => {
    dispatch(editRegistrationTable(data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(StarterHistoryTable)));
