import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { connect } from 'react-redux';
import { WithRouterProps, withRouter } from 'react-router';
import { RootState } from 'redux/rootReducer';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import esmileyLogo from 'static/img/esmiley-logo-white.svg';
import { Icon } from 'components/Icon';
import StepOne from 'pages/Signup/StepOne';
import StepTwo from 'pages/Signup/StepTwo';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import classNames from 'classnames';
import { SignUpData } from 'redux/ducks/signup/types';
import StepThree from 'pages/Signup/StepThree';
import StepFour from 'pages/Signup/StepFour';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEffect } from 'react';

interface SignUpPageProps {
  loading: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
export type OwnProps = StateProps & InjectedIntlProps & WithRouterProps & SignUpPageProps;

const initialFormData = {
  country: 'dk',
  company: '',
  email: '',
  password: '',
  phone: '',
  prefix: '+45',
  code: '',
  newsletter: false,
  firstName: '',
  lastName: ''
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      background: 'linear-gradient(180deg, rgba(59,98,133,1) 0%, rgba(0,130,197,1) 100%)',
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    logoContainer: {
      width: '90%',
      position: 'relative',
      maxWidth: '400px'
    },
    backButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      color: '#fff'
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      '& .SVGInline': {
        width: '200px'
      }
    },
    loaderContainer: {
      width: '90%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '6px',
      padding: '12px 10px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      maxWidth: '400px'
    },
    spinner: {
      backgroundColor: theme.palette.background.paper
    },
    greenBackground: {
      background: 'linear-gradient(180deg, rgba(1,88,84,1) 0%, rgba(1,138,133,1) 100%)'
    }
  };
});

const SignUpPage: React.FunctionComponent<OwnProps> = (props: OwnProps) => {
  const classes = useStyles(props);
  const { loading } = props;

  const [step, setStep] = React.useState<number>(1);
  const [formData, setFormData] = React.useState<SignUpData>(initialFormData);

  useEffect(() => {
    const { location, router } = props;
    router.push(`/${location.pathname}`);
    window.addEventListener('popstate', () => handleGoBack());
    return () => {
      window.removeEventListener('popstate', () => handleGoBack());
    };
  }, []);

  const handleEdit = (value: string, key: string) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleGoBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <Grid
      item
      xs={12}
      className={classNames(classes.container, {
        [classes.greenBackground]: loading
      })}
    >
      <div className={classes.logoContainer}>
        {step > 1 && loading === false && (
          <IconButton className={classes.backButton} onClick={handleGoBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Icon icon={esmileyLogo} className={classes.logo} />
      </div>

      {step === 1 && loading === false && (
        <StepOne onStepChange={setStep} onChange={handleEdit} data={formData} />
      )}

      {step === 2 && loading === false && (
        <StepTwo onStepChange={setStep} onChange={handleEdit} data={formData} />
      )}

      {step === 3 && loading === false && (
        <StepThree onStepChange={setStep} onChange={handleEdit} data={formData} />
      )}

      {step === 4 && loading === false && (
        <StepFour onStepChange={setStep} onChange={handleEdit} data={formData} />
      )}

      {loading && (
        <div className={classes.loaderContainer}>
          <LoadingPlaceholder className={classes.spinner} simple />
        </div>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.signup.loading
});

export default connect<StateProps, unknown, SignUpPageProps>(
  mapStateToProps,
  null
)(injectIntl(withRouter(SignUpPage)));
