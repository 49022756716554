import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { formatWeight, formatMoney, unformat } from 'utils/number-format';
import { Basis, Dimension } from 'redux/ducks/reportFilter';
import withLoading, { WithLoadingProps } from 'components/LoadingPlaceholder/withLoading';
import GrowthIndicator from 'components/metrics/GrowthIndicator';
import { getGrowth } from 'components/metrics/utils';

interface ComponentProps extends InjectedIntlProps, WithLoadingProps {
  title?: React.ReactNode;
  icon?: React.ReactElement;
  value: number | string;
  unit?: string;
  message?: {
    text: string | React.ReactElement;
    progressValue?: number;
    invertedProgress?: boolean;
    customIcon?: React.ReactNode;
  };
  formatValue?: boolean;
  dimension?: Dimension;
  basis?: Basis;
  disabled?: boolean;
  isTrendInverted?: boolean;
}

const Fact: React.FunctionComponent<ComponentProps> = (props) => {
  const classes = useStyles(props);
  const {
    title,
    icon,
    value,
    dimension,
    basis,
    unit,
    formatValue,
    message,
    intl,
    isTrendInverted
  } = props;
  const { progressValue = 0, text, invertedProgress, customIcon } = message || {};
  //FIXME: Figure out a better way to format the values, cause this ain't pretty
  const formattedValue =
    typeof value === 'number'
      ? formatValue
        ? dimension === 'cost'
          ? formatMoney(value).toString()
          : basis === 'per-guest'
          ? formatWeight(value, true, 'g', 0)
          : formatWeight(value, undefined, undefined, 0)
        : intl.formatNumber(value, { maximumFractionDigits: 2 }) + (unit ? ' ' + unit : '')
      : value;
  return (
    <div className={classes.container}>
      {title && (
        <Typography variant={'h3'} className={classes.title} align={'center'}>
          {title}
        </Typography>
      )}
      {icon && React.cloneElement(icon, { className: classes.icon })}
      <Typography component={'span'} className={classes.value} align={'center'}>
        {formattedValue}
        {dimension && dimension === 'co2' && (
          <sup className={classes.subValue}>
            CO<sub>2</sub>
          </sup>
        )}
      </Typography>
      <div className={classes.progress}>
        {customIcon
          ? customIcon
          : progressValue !== 0 && (
              <GrowthIndicator
                isInverted={isTrendInverted}
                value={progressValue}
                growth={getGrowth(unformat(value), progressValue, { inverted: invertedProgress })}
              />
            )}
        <Typography component='div' className={classes.progressText} align={'center'}>
          {text}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, ComponentProps>((theme) => ({
  container: (props) => ({
    opacity: props.disabled && 0.1,
    marginTop: theme.spacing(0.5)
  }),
  title: {
    fontSize: '1rem',
    lineHeight: '1.2',
    margin: theme.spacing(0.5, 0, 2),
    [theme.breakpoints.up('sm')]: {
      minHeight: '2.5rem'
    }
  },
  value: {
    fontSize: theme.typography.pxToRem(35),
    lineHeight: theme.typography.pxToRem(42),
    fontWeight: 900,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    display: 'block'
  },
  subValue: {
    color: theme.palette.text.secondary
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    marginTop: 5
  },
  progressText: {
    color: theme.palette.grey[400]
  },
  progressValue: {
    fontWeight: 900,
    color: theme.palette.text.secondary
  },
  icon: {
    margin: '0 auto 10px',
    textAlign: 'center',

    '& svg': {
      maxHeight: '50px',
      width: 62,
      fill: theme.palette.text.primary
    }
  },
  arrow: {
    display: 'inline-block',
    marginRight: 5,
    verticalAlign: 'text-top',
    transform: 'rotate(180deg)',
    '& svg': {
      height: 14,
      width: 12
    }
  },
  arrowDown: {
    transform: 'rotate(0deg)',
    verticalAlign: 'text-bottom'
  },
  arrowNeg: {
    '& svg': {
      fill: theme.palette.error.main
    }
  },
  arrowPoz: {
    '& svg': {
      fill: theme.palette.success.main
    }
  },
  loader: {
    width: 40,
    height: 40,
    display: 'block',
    margin: '50px auto 0'
  }
}));
export default injectIntl(withLoading(Fact));
