import * as React from 'react';
import KeyPad from 'components/Keypad';
import { API_DATE_FORMAT } from 'utils/datetime';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { CreateGuestRegistration } from 'redux/ducks/guestRegistrations/types';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import InlineDatePicker from 'components/InlineDatePicker';
import isRegistrationLockedForDate from 'pages/Registration/utils/isRegistrationLocked';

interface GuestRegistrationFormProps extends InjectedIntlProps {
  className: string;
  guestRegistration?: Partial<CreateGuestRegistration>;
  options?: {
    date?: {
      enabled: boolean;
    };
    keypad?: {
      enabled: boolean;
      label?: React.ReactNode;
    };
  };
  onChange: (data: Partial<CreateGuestRegistration>) => void;
  onSubmit: (data: CreateGuestRegistration) => void;
  lockedDays?: number;
}

const GuestRegistrationForm: React.FunctionComponent<GuestRegistrationFormProps> = (
  props: GuestRegistrationFormProps
) => {
  const { onChange, onSubmit, guestRegistration, className, options, lockedDays, intl } = props;
  const { amount, date } = guestRegistration;
  const formRef = React.useRef<HTMLDivElement>(null);
  const previousDateRef = React.useRef<HTMLButtonElement>(null);
  const nextDateRef = React.useRef<HTMLButtonElement>(null);
  const submitRegistrationRef = React.useRef<HTMLButtonElement>(null);
  const classes = styles(props);
  const submitDisabled = amount === null || amount === undefined || amount < 0;

  function handleSubmit(e: React.MouseEvent) {
    if (submitDisabled) {
      return;
    }
    e.preventDefault();
    onSubmit(guestRegistration as CreateGuestRegistration);
  }

  function handleDateChange(date: Moment) {
    onChange({ ...guestRegistration, date: date.format(API_DATE_FORMAT) });
  }

  function handleAmountChange(amount: number) {
    onChange({ ...guestRegistration, amount });
  }

  React.useEffect(() => {
    const handleLeftArrowPress = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        previousDateRef.current?.click();
      }
    };

    const handleRightArrowPress = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        nextDateRef.current?.click();
      }
    };

    const handleEnterPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submitRegistrationRef.current?.click();
      }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (e.target.contains(formRef.current) || formRef.current.contains(e.target)) {
        handleRightArrowPress(e);
        handleLeftArrowPress(e);
        handleEnterPress(e);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  React.useEffect(() => {
    if (options.keypad.enabled && formRef.current) {
      formRef.current.focus();
    }
  }, [options.keypad.enabled, formRef.current]);

  return (
    <div ref={formRef} className={classNames(classes.root, { [className]: !!className })}>
      <InlineDatePicker
        className={classes.calendar}
        value={moment(date)}
        onChange={handleDateChange}
        refs={{ leftArrowButton: previousDateRef, rightArrowButton: nextDateRef }}
        shouldDisableDate={(date) =>
          isRegistrationLockedForDate(new Date(date.toDate()), lockedDays)
        }
      />
      <KeyPad
        label={options.keypad.label}
        onChange={handleAmountChange}
        disabled={!options.keypad.enabled}
        value={amount}
      />
      <Button
        ref={submitRegistrationRef}
        color='primary'
        variant='contained'
        className={classes.button}
        onClick={handleSubmit}
        disabled={submitDisabled}
      >
        {intl.messages['registration.btn']}
      </Button>
    </div>
  );
};

GuestRegistrationForm.defaultProps = {
  options: {
    date: { enabled: true },
    keypad: {
      enabled: true
    }
  }
};

const fixedHeightQuery = '@media screen and (min-height: 1024px)';

const styles = makeStyles<Theme, GuestRegistrationFormProps>((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    backgroundColor: '#333333',
    padding: '2vh',
    height: '100%',
    '& > * + * ': {
      marginTop: '2vh'
    },
    [fixedHeightQuery]: {
      padding: '16px',
      '& > * + * ': {
        marginTop: '16px'
      }
    }
  },
  button: {
    width: '100%',
    borderRadius: '5px!important',
    marginBottom: 'auto'
  },
  calendar: {
    backgroundColor: '#595959',
    '& .MuiButton-root': {
      color: theme.palette.common.white,
      fill: theme.palette.common.white
    }
  }
}));

export default injectIntl(GuestRegistrationForm);
