import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Button } from '@material-ui/core';

const AutoActivateButtonInternal: React.FC<InjectedIntlProps & { onTimeout: () => void }> = ({
  onTimeout,
  intl
}) => {
  const [counter, setCounter] = React.useState(15);

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setCounter((prev) => {
        if (prev === 0) {
          clearInterval(timerId);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  React.useEffect(() => {
    if (counter === 0) {
      onTimeout();
    }
  }, [counter]);

  return (
    <Button variant='outlined' onClick={onTimeout}>
      {intl.formatMessage({ id: 'label.toAutoUpdate' }, { counter })}
    </Button>
  );
};

export const AutoActivateButton = injectIntl(AutoActivateButtonInternal);
