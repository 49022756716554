import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from 'redux/rootReducer';
import { FrequencyTarget, getSettings, OverrideSettings } from 'redux/ducks/settings';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel
} from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { update as updateSettings } from 'redux/ducks/settings';
import SelectPeriodModal from 'pages/Settings/components/settings/goals/SelectPeriodModal';
import CardHeader from 'pages/Settings/components/settings/goals/CardHeader';
import { API_DATE_FORMAT } from 'utils/datetime';
import HelpText from 'components/HelpText';
import LockIcon from '@material-ui/icons/Lock';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type GoalsProps = StateProps & DispatchProps & InjectedIntlProps;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column wrap'
  },
  content: {
    flexGrow: 1
  },
  dayList: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    maxWidth: '600px'
  },
  dayGroup: {
    display: 'flex',
    flexFlow: 'column wrap'
  },
  dayGroupMiddle: {
    [theme.breakpoints.down(500)]: {
      marginRight: '100%',
      order: 2
    }
  },
  cardActions: {
    '& > :last-child': { marginLeft: 'auto' }
  },
  formLabel: {
    fontWeight: 600
  },
  switch: {
    display: 'flex',
    alignItems: 'center'
  },
  lockBox: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const dayGroups = [
  [1, 2, 3],
  [4, 5],
  [6, 0]
];

const days = [1, 2, 3, 4, 5, 6, 0];
const defaultFrequency: FrequencyTarget = { days: [] };

const frequencyToDOW = (
  frequency: FrequencyTarget = defaultFrequency
): { [dow: number]: boolean } =>
  days.reduce(
    (all, current) => ({
      ...all,

      [current]: frequency.days.some((day) => day === current)
    }),
    {}
  );

const FrequencyCard: React.FunctionComponent<GoalsProps> = (props) => {
  const classes = useStyles(props);
  const {
    frequency,
    frequencyHistory,
    intl,
    updateSettings,
    overrideChildSettings,
    hasSubscribedAccounts,
    lockedByAdmin
  } = props;

  const [frequencyDraft, setFrequencyDraft] = React.useState(frequencyToDOW(frequency));
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [overrideState, setOverrideState] = React.useState<undefined | 'show' | 'loading'>();

  const hasChanged = React.useCallback(() => {
    const freqDow = frequencyToDOW(frequency);
    return Object.keys(frequencyDraft).some((dow) => freqDow[dow] !== frequencyDraft[dow]);
  }, [frequency, frequencyDraft]);

  React.useEffect(() => {
    setFrequencyDraft(frequencyToDOW(frequency));
  }, [frequency]);

  React.useEffect(() => {
    if (overrideState) {
      setOverrideState(undefined);
    }
  }, [overrideChildSettings]);

  const handleFrequencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: dow } = e.target;
    setFrequencyDraft((prev) => ({ ...prev, [dow]: !prev[dow] }));
  };

  const handleUndo = () => {
    setFrequencyDraft(frequencyToDOW(frequency));
  };

  // common logic, could be extracted to an util
  const handleSave = (overridePrevious: boolean) => {
    const nextFrequency = Object.keys(frequencyDraft)
      .filter((day) => frequencyDraft[day])
      .map((day) => parseInt(day));
    if (overridePrevious) {
      const from = moment(new Date(0)).format(API_DATE_FORMAT);
      updateSettings({ expectedFrequency: [{ from, days: nextFrequency }] });
    } else {
      const from = moment().format('YYYY-MM-DD');
      updateSettings({
        expectedFrequency: [
          ...frequencyHistory.filter((slot) => slot.from !== from),
          { from, days: nextFrequency }
        ]
      });
    }
    setShowModal(false);
  };

  const handleOverride = (overridePrevious: boolean) => {
    setOverrideState('loading');
    const nextOverrideChildSettings = {
      ...overrideChildSettings,
      expectedFrequency: {
        enabled: true,
        overridePrevious
      }
    } as OverrideSettings;

    updateSettings({
      overrideChildSettings: nextOverrideChildSettings
    });
  };

  const handleSelectPeriod = () => {
    setShowModal(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <CardHeader
          title={intl.messages['benchmarks.frequencyRegistrations']}
          titleHelpIcon={
            <HelpText helpText={intl.messages['benchmarks.frequencyRegistrations.description']} />
          }
        />
        <div className={classes.dayList}>
          {dayGroups.map((days, index) => (
            <div
              key={`day_group_${index}`}
              className={classNames(classes.dayGroup, { [classes.dayGroupMiddle]: index === 1 })}
            >
              {days.map((day) => (
                <FormControlLabel
                  key={`s_day_${day}`}
                  control={
                    <Checkbox
                      disabled={lockedByAdmin.expectedFrequency}
                      color='primary'
                      name={`day-${day}`}
                      value={day}
                      checked={frequencyDraft[day]}
                      onChange={handleFrequencyChange}
                    />
                  }
                  label={moment.weekdays(day)}
                />
              ))}
            </div>
          ))}
        </div>
      </CardContent>
      <CardActions
        className={classNames({
          [classes.cardActions]: lockedByAdmin.expectedFrequency || hasSubscribedAccounts
        })}
      >
        <Button
          variant='text'
          onClick={handleUndo}
          disabled={lockedByAdmin.expectedFrequency || !hasChanged()}
        >
          {intl.messages['base.undo']}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSelectPeriod}
          disabled={lockedByAdmin.expectedFrequency || !hasChanged()}
        >
          {intl.messages['base.save']}
        </Button>
        {lockedByAdmin.expectedFrequency && (
          <Chip
            variant='outlined'
            label={intl.messages['settings.targets.locked']}
            icon={<LockIcon fontSize='small' />}
          />
        )}
        {hasSubscribedAccounts && (
          <Button
            disabled={hasChanged()}
            color='primary'
            variant='contained'
            onClick={() => setOverrideState('show')}
          >
            {intl.messages['settings.targets.applyToSubaccounts']}
          </Button>
        )}
      </CardActions>
      <SelectPeriodModal
        title={intl.messages['benchmarks.frequencyRegistrations']}
        content={intl.messages['dashboard.widget.settings.applySettingsForPreviousPeriods.msg']}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onAccept={() => {
          handleSave(true);
        }}
        onDecline={() => {
          handleSave(false);
        }}
      />
      <SelectPeriodModal
        title={intl.messages['settings.targets.modal.overrideFrequency.title']}
        content={intl.messages['settings.targets.modal.overrideContent']}
        open={!!overrideState}
        isLoading={overrideState === 'loading'}
        onCancel={() => setOverrideState(undefined)}
        onAccept={() => {
          handleOverride(true);
        }}
        onDecline={() => {
          handleOverride(false);
        }}
      />
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  frequency: getSettings(state).currentExpectedFrequency,
  frequencyHistory: getSettings(state).expectedFrequency || [],
  overrideChildSettings: state.settings.overrideChildSettings || {},
  hasSubscribedAccounts: state.subAccounts.subscribed.length !== 0,
  lockedByAdmin: getSettings(state).lockedByAdmin
});

const mapDispatchToProps = {
  updateSettings
};
export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FrequencyCard));
