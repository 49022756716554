import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Line } from 'pages/Report/components/Chart';
import { Options } from 'highcharts';
import ErrorMessage from 'pages/Report/components/ErrorMessage';
import { ReportChart, SeriesResponse } from 'redux/ducks/reportData';

interface ComponentProps extends InjectedIntlProps {
  chartOptions: Options;
  trendFoodWaste: ReportChart<SeriesResponse>;
}

const Trend: React.FunctionComponent<ComponentProps> = ({ chartOptions, trendFoodWaste }) => {
  const { error } = trendFoodWaste;

  return (
    <Box mt={4}>
      {error ? (
        <ErrorMessage error={error} />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            {chartOptions && <Line chartOptions={chartOptions} />}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default injectIntl(Trend);
