import { SubAccount } from 'redux/ducks/subAccounts/types';
import { RootState } from 'redux/rootReducer';
import { SettingsState } from 'redux/ducks/settings';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// pointless, should be handled at the 'edge' ui view
const parseAccounts = (accounts: SubAccount[] = [], useNickname: boolean) =>
  accounts
    .map((account) => ({
      nickname: account.nickname,
      name: useNickname ? account.nickname || account.name : account.name,
      id: account.id
    }))
    .sort((a, b) => (a['name'] < b['name'] ? -1 : 1));

const useNicknameSelector = (state: RootState) => state.settings.useAccountNickname;
const subscribedSelector = (state: RootState) => state.subAccounts.subscribed;
const unsubscribedSelector = (state: RootState) => state.subAccounts.unsubscribed;

export const selectSubscribedAccounts = createDeepEqualSelector(
  useNicknameSelector,
  subscribedSelector,
  (useNickname, subscribed) => {
    return parseAccounts(subscribed, useNickname);
  }
);

export const selectSubAccounts = createDeepEqualSelector(
  useNicknameSelector,
  subscribedSelector,
  unsubscribedSelector,
  (useNickname, subscribed, unsubscribed) => ({
    subscribed: parseAccounts(subscribed, useNickname),
    unsubscribed: parseAccounts(subscribed, useNickname)
  })
);
