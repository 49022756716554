import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Button, Divider, Grid, ListItemText, makeStyles } from '@material-ui/core';
import SortIcon from 'components/icons/SortIcon';
import Select from 'pages/Report/components/Select';
import SelectItem from 'pages/Report/components/Select/components/SelectItem';
import { Spinner } from 'components/LoadingPlaceholder';

interface GuestTypeSelectProps {
  onChange: (names: string[]) => void;
  available: string[];
  selected: string[];
}

const SelectAll = 'all';

// basically a copy paste of RegistrationPointSelect,
// could be refactored to share logic
interface DraftSelection {
  selection: string[];
  changed?: boolean;
}

const GuestTypeSelect: React.FunctionComponent<GuestTypeSelectProps & InjectedIntlProps> = (
  props
) => {
  const { intl, available, selected, onChange } = props;
  const classes = useStyles(props);

  const [draft, setDraft] = React.useState<DraftSelection>({
    selection: selected,
    changed: false
  });

  const [pending, setPending] = React.useState(false);

  React.useEffect(() => {
    setDraft({ selection: selected, changed: false });
    setPending(false);
  }, [available, selected]);

  const renderValue = React.useCallback(() => {
    if (selected.length === 0) {
      return intl.messages['report.filter.no_selection'];
    }

    return selected.join(',');
  }, [selected]);

  const handleDraftChange = (selection: string[]) => {
    const changed =
      selected.length !== selection.length || selected.some((id) => !selection.includes(id));
    setDraft({ selection, changed });
  };

  const handleChange = (e: React.ChangeEvent<any>, value: string, isChecked?: boolean) => {
    if (value === SelectAll) {
      handleDraftChange([]);
    } else {
      const selectedValues = isChecked
        ? draft.selection.filter((selectedName) => selectedName !== value)
        : [...draft.selection, value];
      handleDraftChange(selectedValues);
    }
  };

  const handleApplyChanges = () => {
    if (pending || !draft.changed) {
      return;
    }
    setPending(true);
    onChange(draft.selection);
  };

  const handleResetChanges = () => {
    setDraft({ selection: selected, changed: false });
  };

  return (
    <Select
      renderValue={renderValue}
      buttonProps={{
        fullWidth: true,
        disabled: available.length === 0,
        startIcon: <SortIcon className={classes.icon} />
      }}
      menuProps={{
        MenuListProps: {
          style: { minWidth: '220px' },
          disablePadding: true
        }
      }}
    >
      <SelectItem
        disabled={draft.selection.length === 0}
        selected={draft.selection.length === 0}
        onClick={(e: React.ChangeEvent) => handleChange(e, SelectAll)}
        checkbox
      >
        <ListItemText>{intl.messages['report.filter.no_selection']}</ListItemText>
      </SelectItem>
      <Divider />
      {available.map((guestTypeName: string) => {
        const isSelected = draft.selection.some((name) => name === guestTypeName);
        return (
          <SelectItem
            onClick={(e: React.ChangeEvent) => handleChange(e, guestTypeName, isSelected)}
            key={guestTypeName}
            selected={isSelected}
            checkbox
          >
            <ListItemText>{guestTypeName}</ListItemText>
          </SelectItem>
        );
      })}
      {draft.changed && (
        <SelectItem
          disableLookup
          disableRipple
          disableHover
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          <Grid container item justify='space-between'>
            <Button variant='text' color='primary' onClick={handleResetChanges}>
              {intl.messages['base.undo']}
            </Button>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleApplyChanges}
              disabled={pending}
            >
              {pending ? <Spinner size='xs' /> : intl.messages['base.apply']}
            </Button>
          </Grid>
        </SelectItem>
      )}
    </Select>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    '& > path:nth-child(2)': {
      color: theme.palette.primary.main
    }
  }
}));

export default injectIntl(GuestTypeSelect);
