import * as React from 'react';
import { CardHeader, Card, CardContent, Typography } from '@material-ui/core';
import { injectIntl, InjectedIntlProps, FormattedHTMLMessage } from 'react-intl';
import Trend from './components/Trend';

const EmissionOverview: React.FunctionComponent<InjectedIntlProps> = () => {
  return (
    <Card>
      <CardHeader title={<FormattedHTMLMessage id='report.sustainability.overview.title' />} />
      <CardContent>
        <Typography variant={'body1'} paragraph={true}>
          <FormattedHTMLMessage id='report.sustainability.overview.description' />
        </Typography>
        <Trend />
      </CardContent>
    </Card>
  );
};

export default injectIntl(EmissionOverview);
