import * as React from 'react';
import { connect } from 'react-redux';
import { Donut } from 'pages/Report/components/Chart';
import { Basis } from 'redux/ducks/reportFilter';
import donutChartOptions from 'pages/Report/components/Chart/Donut/options';
import { Typography } from '@material-ui/core';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import createValueFormatter from 'pages/Report/utils/createValueFormatter';
import { chartColors } from 'pages/Report/Accounts/utils/constants';
import { RootState } from 'redux/rootReducer';
import ChartTitle from 'pages/Report/Accounts/components/FoodWastePerAccountFilter/ChartTitle';
import { makeStyles } from '@material-ui/core/styles';
type StoreProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  basis: Basis;
}

interface ComponentProps extends StoreProps, OwnProps {}

const useStyles = makeStyles((theme) => ({
  chartTitle: {
    marginTop: 25
  },
  subChartTitle: {
    color: theme.palette.text.secondary
  }
}));

const identifier = 'foodWastePerAccount';

const FoodWastePerAccountFilter: React.FunctionComponent<ComponentProps & InjectedIntlProps> = (
  props
) => {
  const classes = useStyles(props);
  const { perAccountSeries, intl } = props;
  const { data: chartsData, isLoading, dimension, basis } = perAccountSeries;
  const pointFormatter = createValueFormatter(dimension, basis);
  const foodWastePerAccountFilter =
    chartsData &&
    chartsData.series &&
    chartsData.series.find((series) => series.id === 'groupTotalSeries');
  const exactFoodWastePerAccountFilter =
    foodWastePerAccountFilter &&
    foodWastePerAccountFilter.series &&
    foodWastePerAccountFilter.series.find((series) => series.id === 'totalAmountSeries');
  const foodWasteRatiosPerAccountFilter =
    foodWastePerAccountFilter &&
    foodWastePerAccountFilter.series &&
    foodWastePerAccountFilter.series.find((series) => series.id === 'totalRatioSeries');
  const otherPoint =
    exactFoodWastePerAccountFilter &&
    exactFoodWastePerAccountFilter.points.find((point) => point.label.toLowerCase() === 'other');
  const foodWasteInSelectedAccounts =
    exactFoodWastePerAccountFilter &&
    exactFoodWastePerAccountFilter.aggregates.total - (otherPoint ? otherPoint.value : 0);

  const areaAccountsData = chartsData.series
    .filter((s) => s.id === 'areaGroups')
    .flatMap((s) => s.series)
    .find((s) => s.id === 'foodwastePerGuestOverviewByAreas');
  const accountTotal = areaAccountsData ? areaAccountsData.aggregates.total : 0;

  return (
    <>
      {basis === 'total' ? (
        <Donut
          options={{
            colors: chartColors,
            total: exactFoodWastePerAccountFilter
              ? exactFoodWastePerAccountFilter.aggregates.total
              : undefined,
            exactValues: exactFoodWastePerAccountFilter,
            showExactValues: true
          }}
          seriesData={[foodWasteRatiosPerAccountFilter]}
          chartOptions={donutChartOptions}
          isLoading={isLoading}
        />
      ) : null}
      <div className={classes.chartTitle}>
        <ChartTitle variant={'h1'} component={'p'} align={'center'} isLoading={isLoading}>
          {exactFoodWastePerAccountFilter &&
            (basis === 'per-guest'
              ? pointFormatter.format(accountTotal, exactFoodWastePerAccountFilter.unit)
              : pointFormatter.format(
                  foodWasteInSelectedAccounts,
                  exactFoodWastePerAccountFilter.unit
                ))}
          {dimension === 'co2' && (
            <sup className={classes.subChartTitle}>
              CO<sub>2</sub>
            </sup>
          )}
        </ChartTitle>
        <Typography variant={'body1'} align={'center'}>
          {basis === 'per-guest'
            ? intl.messages['report.accounts.avgFWPerGuestInSelectedAccounts']
            : intl.messages['report.accounts.totalFoodwasteInSelectedAccounts']}
        </Typography>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  perAccountSeries: state.reportData[identifier]
});

export default connect<StoreProps, unknown, OwnProps>(mapStateToProps)(
  injectIntl(FoodWastePerAccountFilter)
);
