import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as registrationDispatch from 'redux/ducks/registration';
import { RegistrationActions } from 'redux/ducks/registration';
import StepInfo from './StepInfo';
import * as React from 'react';
import { connect } from 'react-redux';
import { Slide, Grid, Stepper, Step, StepButton } from '@material-ui/core';
import classNames from 'classnames';
import registrationPointPlaceholder from 'static/img/product_placeholder.png';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import TextAreaInput from 'components/TextAreaInput';
import { makeStyles } from '@material-ui/core/styles';
import MultipleRegistrationTable from 'pages/Registration/Register/RegisterMultiple/MultipleRegistrationTable';
import SelectReason from './SelectReason/index';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getActiveReasons } from 'redux/ducks/reasons/selectors';
import { Reason } from 'redux/ducks/reasons';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  error: boolean;
}

export interface IComponentProps extends InjectedIntlProps, OwnProps, StateProps, DispatchProps {}

const useStyles = makeStyles((theme) => ({
  comment: {
    marginTop: theme.spacing(2)
  },
  commentLabel: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: theme.palette.common.black
  },
  reasonGridError: {
    boxSizing: 'border-box',
    border: '2px solid #F44236',
    padding: '0px 10px 10px 10px'
  },
  reasonGrid: {
    padding: '0px 10px 10px 10px'
  },
  stepperWrapper: {
    paddingTop: '10px',
    paddingBottom: '10px',
    marginLeft: '-15px'
  },
  stepper: {
    overflowX: 'auto',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    padding: 0
  }
}));

const Overview: React.FunctionComponent<IComponentProps> = (props) => {
  const classes = useStyles(props);
  const {
    enableRegistrationComments,
    enableRegistrationReason,
    comment,
    setComment,
    nodesHistory,
    updateStepper,
    intl,
    setReason,
    error,
    selectedReason,
    step,
    reasons,
    isMultiView
  } = props;
  const onCommentChange = (value: string) => {
    setComment(value);
  };

  const StepConnector = () => <ChevronRightIcon />;

  const selectWrapperClass = classNames({
    [classes.reasonGridError]: enableRegistrationReason && error && selectedReason,
    [classes.reasonGrid]: !error && selectedReason
  });

  const handleUpdateStep = (index: number, name: string) => {
    updateStepper(index, name);
  };
  return (
    <Grid container spacing={4} justify='center' className='overview'>
      <Grid item xs={12} className={classes.stepperWrapper}>
        <Stepper
          activeStep={step}
          nonLinear
          connector={<StepConnector />}
          className={classes.stepper}
        >
          {nodesHistory.map((item, index: number) => {
            const name = Object.keys(item)[0];
            return (
              <Slide in={true} direction={'right'} timeout={200} key={`${name}${index}`}>
                <Step>
                  <StepButton onClick={() => handleUpdateStep(index, name)} completed={true}>
                    {name}
                  </StepButton>
                </Step>
              </Slide>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12} className={selectWrapperClass}>
        {enableRegistrationReason ? (
          <SelectReason
            onSelect={setReason}
            reasons={reasons}
            error={error}
            selected={selectedReason}
          />
        ) : isMultiView === true ? (
          <Grid item xs={12}>
            <MultipleRegistrationTable />
          </Grid>
        ) : (
          nodesHistory.map((item, index) => {
            const name = Object.keys(item)[0];
            const registrationPoint = nodesHistory[index][name].filter((registrationPoint) => {
              return registrationPoint.name === name;
            });
            return (
              <Slide in={true} timeout={200} key={`${name}${index}`}>
                <StepInfo
                  step={index}
                  numOfSteps={nodesHistory.length - 1}
                  className={classNames('step-info')}
                  completed={true}
                  onClick={() => handleUpdateStep(index, name)}
                  image={
                    registrationPoint[0].image
                      ? registrationPoint[0].image
                      : registrationPointPlaceholder
                  }
                  description={intl.messages[registrationPoint[0].label]}
                  title={name ? name : intl.messages['registration.error.no_product']}
                />
              </Slide>
            );
          })
        )}
      </Grid>
      {enableRegistrationComments && (
        <Grid item xs={12}>
          <TextAreaInput
            className={classes.comment}
            name='comments'
            label={intl.messages['base.comments']}
            InputLabelProps={{ shrink: true, className: classes.commentLabel }}
            fullWidth
            multiline
            rows={5}
            rowsMax={50}
            value={comment}
            placeholder={intl.messages['base.comments.placeholder']}
            onChange={onCommentChange}
            maxLength={255}
          />
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state: RootState) => {
  const { step, nodesHistory, date, comment, reasonId } = state.registration;
  const { enableRegistrationComments, enableRegistrationReason } = state.settings;
  const reasons = getActiveReasons(state);

  return {
    step,
    nodesHistory,
    date,
    comment,
    enableRegistrationComments,
    isMultiView: state.registration.displayMultipleRegistration,
    selectedReason: reasonId ? reasons.find((r) => r.id === reasonId) : undefined,
    enableRegistrationReason,
    reasons
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, RegistrationActions>) => ({
  updateStepper: (index: number, property: string) => {
    dispatch(registrationDispatch.updateStepper(index, property));
  },
  setDate: (date: Date) => {
    dispatch(registrationDispatch.setDate(date));
  },
  setComment: (comment: string) => dispatch(registrationDispatch.setComment(comment)),
  setReason: (reason: Reason) => dispatch(registrationDispatch.setReason(reason.id))
});
export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Overview));
