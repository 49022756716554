import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  getAlarms,
  getSettings,
  SavedSettings,
  SettingsActions,
  update
} from 'redux/ducks/settings';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { UiActions } from 'redux/ducks/ui';
import { Paper, Grid, Select, MenuItem, Divider } from '@material-ui/core';
import { CURRENCIES } from 'utils/number-format';
import HelpText from 'components/HelpText';
import TimezonePicker from './timezonePicker';
import { makeStyles } from '@material-ui/core/styles';
import ContactUsDialog from 'src/components/ContactUsDialog';
import Helmet from 'react-helmet';
import SubscriptionExpirationCounter from 'pages/Account/SubscriptionExpirationCounter';
import { withOffline } from 'components/WithOffline';
import { selectSubscribedAccounts } from 'redux/ducks/subAccounts/selectors';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  isMobile?: boolean;
}

type AccountProps = StateProps & DispatchProps & InjectedIntlProps & OwnProps;

const useStyles = makeStyles((theme) => {
  return {
    field: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px'
    },
    fieldInput: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: 'bold',
      width: '100%'
    },
    fieldInputCurrency: {
      marginTop: '-5px',
      marginBottom: '10px'
    },
    select: {
      width: '100%',
      border: '1px solid #57cbbf',
      paddingLeft: '15px',
      paddingRight: '25px',
      height: '45px',
      borderRadius: '3px'
    },
    currencyControl: {
      '& svg': {
        color: '#4cab9f',
        marginRight: '10px'
      }
    },
    infoWrapper: {
      padding: '25px',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100px'
    },
    planWrapper: {
      minHeight: '100px'
    },
    container: {
      marginTop: '20px'
    },
    containerMobile: {
      padding: '2px',
      marginTop: '-20px',
      marginBottom: '40px'
    },
    companyName: {
      color: '#626262',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      width: '100%'
    },
    companyPhone: {
      marginTop: '10px',
      fontSize: '0.95rem'
    },
    planRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '25px 25px 0px 25px'
    },
    progressRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0px 25px 0px 25px'
    },
    planSubRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '5px 25px 25px 25px'
    },
    planTitle: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      color: '#626262'
    },
    planPrice: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      color: '#626262'
    },
    planAction: {
      color: '#008b87',
      fontWeight: 'bold',
      fontSize: '0.83rem',
      cursor: 'pointer'
    },
    planUnit: {
      fontWeight: 'normal',
      fontSize: '0.82rem',
      color: '#626262'
    },
    planDivider: {
      marginLeft: '25px',
      marginRight: '25px'
    },
    planUsers: {
      fontSize: '0.88rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '15px 25px 25px 25px'
    },
    planUserTotal: {
      fontWeight: 'bold',
      color: '#626262'
    },
    currencyControlFix: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '-70px'
      }
    }
  };
});

const Account: React.FunctionComponent<AccountProps> = (props) => {
  const { intl, user, accounts, currency, alarm, isMobile, updateSettings } = props;
  const { subscription } = user;
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const classes = useStyles();
  const handleDialogOpen = () => {
    setOpen(true);
  };
  const isStarterOrEssential = subscription.type === 'starter' || subscription.type === 'essential';

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <div className={isMobile ? classes.containerMobile : classes.container}>
      <ContactUsDialog isOpen={isOpen} onClose={handleDialogClose} />
      <Helmet title={intl.messages['account']} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper className={classes.planWrapper}>
            <div className={classes.planRow}>
              <span className={classes.planTitle}>
                {intl.messages[`plan.${subscription.type}`]}
              </span>
            </div>
            {isStarterOrEssential && (
              <div className={classes.planSubRow}>
                <span onClick={handleDialogOpen} className={classes.planAction}>
                  {intl.messages['plan.action']}
                </span>
                <span className={classes.planUnit}>{intl.messages['plan.unit']}</span>
              </div>
            )}
            {subscription.endDate ? (
              <div className={classes.progressRow}>
                <SubscriptionExpirationCounter subscription={subscription} />
              </div>
            ) : null}
            <Divider className={classes.planDivider} />
            <div className={classes.planUsers}>
              <span>{intl.messages['plan.users']}</span>
              <span
                className={classes.planUserTotal}
              >{`1/${accounts.length} ${intl.messages['plan.user']}`}</span>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper className={classes.infoWrapper}>
            <span className={classes.companyName}>{user.name}</span>
            <span className={classes.companyPhone}>{user.phone}</span>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper>
            <div className={classes.field}>
              <label htmlFor='timeZone' className={classes.fieldInput}>
                <span>{intl.messages['timezone']}</span>
                <HelpText size={'small'} helpText={intl.messages['help.settings.swapTimezones']} />
              </label>
              <div id='timeZone'>
                <TimezonePicker
                  value={alarm.zone}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    void updateSettings({ alarms: { ...alarm, zone: e.target.value } });
                  }}
                />
              </div>
            </div>
            <div className={classes.field}>
              <label htmlFor='currency' className={classes.fieldInput}>
                <span>
                  <FormattedMessage id='settings.basic.currency' />
                </span>
                <HelpText
                  helpText={intl.messages['help.settings.primary.currency']}
                  size={'small'}
                />
              </label>
              <small className={classes.fieldInputCurrency}>
                {intl.messages[`currency.${currency}`]}
              </small>
              <div className={classes.currencyControl}>
                <Select
                  className={classes.select}
                  required={true}
                  value={currency}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    void updateSettings({ currency: event.target.value });
                  }}
                  name='currency'
                  MenuProps={{ classes: { paper: classes.currencyControlFix } }}
                >
                  {CURRENCIES.map((element: string, index: number) => (
                    <MenuItem value={element} key={`currency_${index}`}>
                      {element}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currency: getSettings(state).currency,
  accounts: selectSubscribedAccounts(state),
  user: state.user,
  alarm: getAlarms(state)
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, UiActions | SettingsActions>
) => ({
  updateSettings: (data: Partial<SavedSettings>) => dispatch(update(data))
});

export default withOffline(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Account))
);
