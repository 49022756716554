import * as React from 'react';
import { RootState } from 'redux/rootReducer';
import { fetchTips } from 'redux/ducks/tips';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import TipCard from 'pages/Dashboard/TipOfTheWeekCard/TipCard';
import LoadingPlaceHolder from 'components/LoadingPlaceholder';
import { makeStyles } from '@material-ui/core/styles';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type TipOfTheWeekCardProps = StateProps & DispatchProps & InjectedIntlProps;

const useStyles = makeStyles({ root: { height: '100%' } });

const TipOfTheWeekCard: React.FunctionComponent<TipOfTheWeekCardProps> = (props) => {
  const classes = useStyles(props);
  const { tips, hasTipsLoaded, isLoading, fetchTips } = props;

  React.useEffect(() => {
    if (!hasTipsLoaded && !isLoading) {
      void fetchTips();
    }
  }, []);

  return hasTipsLoaded && !isLoading ? (
    <TipCard tips={tips} classes={{ mediaCard: classes.root }} />
  ) : (
    <LoadingPlaceHolder />
  );
};

const mapStateToProps = (state: RootState) => ({
  tips: state.tips.tips,
  hasTipsLoaded: state.tips.loaded,
  isLoading: state.tips.initializing
});

const mapDispatchToProps = {
  fetchTips
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TipOfTheWeekCard));
