import * as React from 'react';
import Helmet from 'react-helmet';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import RegistrationSettings from './components/settings/registrations';
import SubAccountSettings from './components/settings/subAccounts';
import AlarmsPanel from './components/settings/alarms';
import GuestTypes from './components/settings/guestTypes';
import Filters from './components/filters';
import * as registrationPointsDispatch from 'redux/ducks/data/registrationPoints';
import { RootState } from 'redux/rootReducer';
import { getSettings, SettingsActions } from 'redux/ducks/settings';
import { ThunkDispatch } from 'redux-thunk';
import { UiActions } from 'redux/ducks/ui';
import { DataRegistrationPointsActions } from 'redux/ducks/data/registrationPoints';
import './index.scss';
import Goals from 'pages/Settings/components/settings/goals';
import ScaleOptions from 'pages/Settings/components/settings/scaleOptions';
import { withRouter, RouteComponentProps } from 'react-router';
import ReasonsContainer from 'pages/Settings/components/settings/registrations/components/reasonTable';
import { Feature } from 'flagged';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = createStyles({
  settingsContainer: {
    flex: '1 75%',
    maxWidth: '100%',
    minWidth: '300px',
    overflow: 'visible'
  }
});

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface ComponentProps
  extends StoreProps,
    DispatchProps,
    RouteComponentProps<any, any>,
    InjectedIntlProps,
    WithStyles {}

const SETTING_SECTIONS = [
  {
    intlKey: 'registrationPoints',
    component: <RegistrationSettings contentType='registrationPoint' />
  },
  { intlKey: 'registrationGoals', component: <Goals /> },
  { intlKey: 'scaleOptions', component: <ScaleOptions /> },
  { intlKey: 'accounts', component: <SubAccountSettings /> },
  { intlKey: 'alarms', component: <AlarmsPanel /> },
  { intlKey: 'settings.guestType.other', component: <GuestTypes /> }
];

export const SettingsPage: React.FC<ComponentProps> = (props) => {
  const { intl, level, router, getRegistrationPointData, classes } = props;
  const [selectedSection, setSelectedSection] = React.useState(0);

  React.useEffect(() => {
    if (level < 4) {
      router.push('/');
    } else {
      void getRegistrationPointData();
    }
  }, []);

  const handleFilterChange = (index: number) => {
    setSelectedSection(index);
  };

  return level && level < 4 ? null : (
    <div className='settingsPageContainer'>
      <Helmet title={intl.messages['settings.headline']} />
      <Feature name='settings/full'>
        <Filters
          items={SETTING_SECTIONS.map((filter, index) => ({
            label: intl.messages[filter.intlKey],
            active: index === selectedSection
          }))}
          changeFilter={handleFilterChange}
        />
        <div className='settingsContent'>
          {SETTING_SECTIONS[selectedSection] ? SETTING_SECTIONS[selectedSection].component : null}
        </div>
      </Feature>
      <Feature name='settings/free'>
        <div className={classes.settingsContainer}>
          <ReasonsContainer
            style={{
              width: '100%'
            }}
          />
        </div>
      </Feature>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  settingsData: getSettings(state),
  level: state.user.level
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    unknown,
    unknown,
    SettingsActions | UiActions | DataRegistrationPointsActions
  >
) => ({
  getRegistrationPointData: () => dispatch(registrationPointsDispatch.findTree())
});

const SettingsPageConnect = connect<StoreProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles)(withRouter(SettingsPage))));

export default SettingsPageConnect;
