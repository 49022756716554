import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import {
  Box,
  TextField,
  InputLabel,
  Typography,
  Divider,
  Button,
  Link,
  InputAdornment,
  Select,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { eSmileyBlue } from 'styles/palette';
import { SignupActions, SignUpData } from 'redux/ducks/signup/types';
import { onSubmitForm } from 'utils/helpers';
import { COUNTRIES } from 'utils/countries';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/rootReducer';
import * as signupDispatch from 'redux/ducks/signup';
import { connect } from 'react-redux';

interface SignupPageProps {
  onStepChange: React.Dispatch<React.SetStateAction<number>>;
  data: SignUpData;
  onChange: (value: string, target: string) => void;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type OwnProps = InjectedIntlProps & SignupPageProps & DispatchProps;

const useStyles = makeStyles((theme) => ({
  input: {
    border: '1px solid ' + theme.palette.grey.A100,
    borderRadius: '6px',
    padding: '6px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none'
      }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '12px 0 0 0'
  },
  inputField: {
    margin: '36px 0 8px 0'
  },
  label: { ...theme.typography.h3, fontWeight: theme.typography.fontWeightBold },
  arrow: {
    ...theme.typography.h1,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    color: theme.palette.text.primary,
    margin: '0 0 8px 0'
  },
  signInLabel: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightLight,
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0 0 0',
    flexWrap: 'wrap'
  },
  formContainer: {
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px',
    padding: '12px 10px',
    height: '550px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    maxWidth: '400px',
    marginBottom: '20px'
  },
  container: {
    background: 'linear-gradient(180deg, rgba(2,93,136,1) 0%, rgba(0,130,197,1) 100%)',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  divider: {
    margin: '18px 0 12px 0',
    color: theme.palette.grey.A100
  },
  textLink: {
    ...theme.typography.subtitle2,
    margin: '0 0 0 3px',
    fontWeight: theme.typography.fontWeightBold,
    color: eSmileyBlue
  },
  button: {
    backgroundColor: eSmileyBlue,
    color: theme.palette.background.paper,
    height: '60px',
    textTransform: 'none',
    fontSize: '16px'
  },
  footerText: {
    fontSize: '12px'
  },
  select: {
    color: theme.palette.grey.A400,
    border: 0,
    width: '45px'
  },
  flagIcon: {
    margin: '4px 0 0 0'
  },
  headerLabel: {
    color: theme.palette.background.default,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px'
  }
}));

const SignUpStepThree: React.FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { intl, data, onChange, onStepChange, handleSmsVerification } = props;
  const { phone, prefix } = data;

  const handleSubmit = () => {
    onStepChange(4);
    handleSmsVerification(`${prefix} ${phone}`);
  };

  return (
    <Box className={classes.formContainer} component='form' onSubmit={onSubmitForm(handleSubmit)}>
      <div>
        <Typography className={classes.headerLabel} variant='body1' component='h1'>
          {intl.messages['signup.secureMessage']}
        </Typography>
        <div className={classes.form}>
          <Typography>
            {intl.messages['signup.verification.message']}
            <strong> {intl.messages['signup.verificationSms']}</strong>.
          </Typography>
          <div className={classes.inputField}>
            <InputLabel className={classes.label} shrink>
              {intl.messages['phoneNumber']}
            </InputLabel>

            <TextField
              fullWidth
              required
              className={classes.input}
              placeholder={intl.messages['phoneNumber']}
              value={phone}
              type={'number'}
              onChange={(e) => onChange(e.target.value, 'phone')}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Select
                      required
                      value={prefix}
                      disableUnderline
                      className={classes.select}
                      onChange={(event) => onChange(event.target.value as string, 'prefix')}
                    >
                      {COUNTRIES.map((element, index) => (
                        <MenuItem
                          value={element.prefix}
                          key={`country_${index}`}
                          selected={element.prefix === prefix}
                        >
                          <img
                            loading='lazy'
                            width='20'
                            src={`https://flagcdn.com/w20/${element.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${element.code.toLowerCase()}.png 2x`}
                            alt='country flag'
                            className={classes.flagIcon}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography>{prefix}</Typography>
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <Divider className={classes.divider} />

        <Button type='submit' fullWidth variant='contained' className={classes.button}>
          {intl.messages['signup.sendCode']}
        </Button>

        <div className={classes.signInLabel}>
          <Typography variant='body1' component='p' noWrap>
            {intl.messages['base.tos.message']}
          </Typography>
          <Link
            noWrap
            target='_blank'
            href={intl.messages['url.termsAndConditions']}
            className={classes.textLink}
            underline='none'
          >
            {intl.messages['base.tos']}
          </Link>
        </div>
      </div>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, SignupActions>) => ({
  handleSmsVerification: (phone: string) => {
    void dispatch(signupDispatch.sendVerificationCode(phone));
  }
});

export default connect<unknown, DispatchProps, SignupPageProps>(
  null,
  mapDispatchToProps
)(injectIntl(SignUpStepThree));
