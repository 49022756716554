import './index.scss';
import Container from 'components/Container';
import {
  createStyles,
  withStyles,
  WithStyles,
  colors,
  Slide,
  Typography,
  Tooltip
} from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import FormattedMessage from 'components/FormattedMessage';
import * as React from 'react';
import { RegistrationPoint } from 'redux/ducks/data/registrationPoints';
import { Registration } from 'redux/ducks/data/registrations';
import AlteredMaterialTable from 'components/MaterialTable';
import { Column, EditComponentProps } from 'material-table';
import { isIE11 } from 'utils/browsers';
import { IPatchObject } from 'redux/ducks/data/registrations';
import { getFullRegistrationPath, scrollToEl } from 'utils/helpers';
import CustomTableBody from 'components/MaterialTable/components/customTableBody';
import CustomTableHead from 'components/MaterialTable/components/customTableHead';
import CustomEditRow from 'components/MaterialTable/components/customEditRow';
import moment from 'moment';
import classNames from 'classnames';
import { formatMoney, formatWeight } from 'utils/number-format';
import InfoIcon from '@material-ui/icons/Info';
import { GuestRegistration } from 'redux/ducks/guestRegistrations';
import NumberInput from 'components/Input/NumberInput';
import isRegistrationLockedForDate from 'pages/Registration/utils/isRegistrationLocked';
import CustomTableAction from 'components/MaterialTable/components/customTableAction';
import { SyncButton } from 'pages/Registration/SyncButton';

const styles = createStyles({
  nameCell: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  infoIcon: {
    color: '#999',
    width: 16,
    height: 16,
    verticalAlign: 'bottom',
    marginLeft: '10px'
  },
  statusDot: {
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    width: '10px',
    height: '10px',
    position: 'relative'
  },
  deleteButton: {
    width: 'initial',
    height: 'initial',
    padding: 0,
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  offlineBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  offlineBoxText: {}
});

export interface HistoryColumn {
  property: keyof Registration;
  translationKey: string;
  alignRight?: boolean;
  hidden?: boolean;
  numeric?: boolean;
}

interface OwnProps {
  registrations: Registration[];
  deleteHandler: (id: string) => void;
  updateHandler: (id: string, patchObject: IPatchObject[]) => void;
  registrationPointsMap: Map<string, RegistrationPoint>;
  lockedDays?: number;
}

type HistoryProps = InjectedIntlProps & OwnProps & WithStyles<typeof styles>;

type EditedRowData = Registration & {
  tableData: {
    id: number;
    editing?: boolean;
  };
};

export interface OverrideEditRowProps {
  data: Registration;
  mode: 'delete' | 'edit';
  icons: any;
}

const NEW_OR_UPDATED_ROW = 'new-or-updated-row';
const deleteTransitionDuration = 1000;

const materialTableOptions = {
  actionsColumnIndex: -1,
  showTitle: false,
  search: false,
  searchFieldAlignment: 'left',
  emptyRowsWhenPaging: false,
  paginationType: 'stepped',
  toolbarButtonAlignment: 'right',
  headerStyle: {
    backgroundColor: colors.grey['50']
  },
  rowStyle: {
    //CRAS: IE11 has an issue with transitioning opacity on table rows
    transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
    borderBottom: `1px solid ${colors.grey['200']}`,
    height: 55
  },
  pageSize: 25,
  pageSizeOptions: [10, 15, 20, 25],
  actionsCellStyle: {
    whiteSpace: 'pre'
  }
};

class History extends React.Component<HistoryProps> {
  deletedRow: any;
  tableRef = React.createRef<any>();
  private updatedRow: number | string;

  constructor(props: HistoryProps) {
    super(props);
  }

  renderColumns = (columns: HistoryColumn[]): Column<Registration>[] => {
    return columns.map(({ property, translationKey }) => {
      switch (property) {
        case 'date':
          return this.setupDateColumn(property, translationKey);
        case 'registrationPoint':
          return this.setupRegistrationPointColumn(property, translationKey);
        case 'amount':
          return this.setupAmountColumn(property, translationKey);
        case 'cost':
          return this.setupCostColumn(property, translationKey);
        case 'co2':
          return this.setupCo2Column(property, translationKey);
        default:
          return;
      }
    });
  };

  setupDateColumn = (key: keyof Registration, intlKey: string): Column<Registration> => {
    const { intl, classes } = this.props;

    return {
      title: intl.messages[intlKey],
      field: key,
      editable: 'never',
      render: (row) =>
        row.date === 'total' ? (
          <Typography variant='body2'>{intl.messages['report.registration_list.total']}</Typography>
        ) : (
          <>
            <Typography variant='body2'>{moment(row.date).format('L')}</Typography>
            {row.offline && (
              <div className={classes.offlineBox}>
                <Typography className={classes.offlineBoxText} variant='caption'>
                  {intl.messages['registration.waitingForConnection']}
                </Typography>
              </div>
            )}
          </>
        )
    };
  };

  setupRegistrationPointColumn = (
    key: keyof Registration,
    intlKey: string
  ): Column<Registration> => {
    const { intl, classes } = this.props;

    return {
      title: <FormattedMessage id={intlKey} html />,
      field: key,
      editable: 'never',
      customSort: (a, b) =>
        a.registrationPoint?.name.localeCompare(b.registrationPoint?.name, intl.locale, {
          sensitivity: 'base'
        }),
      render: (row) => {
        const { registrationPoint } = row;
        if (!registrationPoint) {
          return '';
        }
        const regPointStatus = registrationPoint.deletedAt
          ? 'deleted'
          : registrationPoint.active
          ? 'active'
          : 'inactive';
        const regPointTooltipTitle = <FormattedMessage id={`settings.status.${regPointStatus}`} />;
        const regPointPath = getFullRegistrationPath(this.props.registrationPointsMap, {
          id: row.id
        }).map((p) => p.name);
        return (
          <div className={classes.nameCell}>
            <Tooltip title={regPointTooltipTitle} enterTouchDelay={50} placement={'bottom'}>
              <span className={classNames(classes.statusDot, regPointStatus)} />
            </Tooltip>
            {registrationPoint.name}
            {regPointPath.length > 1 && (
              <Tooltip title={regPointPath.join(' ➔ ')} enterTouchDelay={50} placement={'right'}>
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            )}
          </div>
        );
      }
    };
  };

  setupAmountColumn = (key: keyof Registration, intlKey: string): Column<Registration> => {
    return {
      title: <FormattedMessage id={intlKey} html />,
      field: key,
      type: 'numeric',
      render: (row) => formatWeight(row.amount),
      editComponent: (props) => this.renderAmountEditMode(props, intlKey)
    };
  };

  renderAmountEditMode = (
    props: EditComponentProps<GuestRegistration>,
    key: string
  ): JSX.Element => {
    const formattedValue = typeof props.value === 'string' ? props.value : props.value / 1000;
    return (
      <NumberInput
        type={'text'}
        required
        value={formattedValue}
        name={`${key}_field`}
        allowNegative={false}
        min={0.1}
        thousandSeparator={false}
        label={<FormattedMessage id={key} />}
        onChange={(e) => {
          if (e.target.value.length > 0) {
            props.onChange(Number(e.target.value) * 1000);
          } else {
            props.onChange(e.target.value);
          }
        }}
      />
    );
  };

  setupCostColumn = (key: keyof Registration, intlKey: string): Column<Registration> => {
    return {
      title: <FormattedMessage id={intlKey} html />,
      field: key,
      editable: 'never',
      type: 'numeric',
      render: (row) => (row.offline ? '-' : formatMoney(row.cost).toString())
    };
  };

  setupCo2Column = (key: keyof Registration, intlKey: string): Column<Registration> => {
    return {
      title: <FormattedMessage id={intlKey} html />,
      field: key,
      editable: 'never',
      type: 'numeric',
      render: (row) => (row.offline ? '-' : formatWeight(row.co2))
    };
  };

  resetHelperGlobals = (): void => {
    this.deletedRow = undefined;
  };

  onRowUpdate = async (newData: EditedRowData, oldData: EditedRowData) => {
    if (newData.amount > 0.1) {
      const amountToUpdate = Number(newData.amount);
      const patchObject = [{ op: 'replace', path: '/amount', value: amountToUpdate }];
      this.props.updateHandler(oldData.id, patchObject);
      scrollToEl(NEW_OR_UPDATED_ROW);
      this.resetHelperGlobals();
      return;
    }
  };

  onRowDelete = async (data: Registration) => {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        this.props.deleteHandler(data.id);
        this.updatedRow = undefined;
        this.resetHelperGlobals();
        resolve();
      }, deleteTransitionDuration - 200);
    });
  };

  overrideTableBody = (props): JSX.Element => {
    return <CustomTableBody {...props} />;
  };

  overrideTableHead = (props): JSX.Element => {
    return <CustomTableHead {...props} draggable={false} />;
  };

  overrideOverlay = (): JSX.Element => <span />;

  overrideEditRow = (props: OverrideEditRowProps): JSX.Element => {
    let deleteConfirmationText = '';
    const slidingIn = props?.data?.id !== this.deletedRow;
    const slideDuration = props?.data?.id === this.deletedRow ? deleteTransitionDuration : 0;

    if (props.mode === 'delete') {
      const { intl } = this.props;
      const {
        data: { amount, registrationPoint, date }
      } = props;

      const deleteDescription = registrationPoint
        ? registrationPoint.name
        : moment(date).format('L');

      deleteConfirmationText = intl.formatMessage(
        { id: 'base.confirm.deletion.description' },
        { name: `${deleteDescription}, ${formatWeight(amount)}` }
      );
    }

    return (
      <Slide in={slidingIn} direction={'left'} timeout={slideDuration}>
        <CustomEditRow
          {...props}
          deleteConfirmationText={deleteConfirmationText}
          onCancelHandler={this.onCancelHandler}
          onSubmitHandler={this.onSubmitHandler}
        />
      </Slide>
    );
  };

  overrideAction = (props): JSX.Element => {
    return <CustomTableAction {...props} />;
  };

  onCancelHandler = (): void => {
    this.resetHelperGlobals();
  };

  onSubmitHandler = (data: Registration, mode?: 'delete' | 'edit'): void => {
    if (mode === 'delete') {
      this.deletedRow = data.id;
    } else {
      this.updatedRow = data.id;
    }
  };

  sortedRegistrations = (registrations: Registration[]) =>
    registrations.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  addTotalRow = (registrations: Registration[]) => {
    if (registrations.length > 0) {
      const { totalAmount, totalCost, totalCo2 } = registrations.reduce(
        ({ totalAmount, totalCost, totalCo2 }, { amount, cost, co2 }) => {
          return {
            totalAmount: totalAmount + amount,
            totalCost: totalCost + cost,
            totalCo2: totalCo2 + co2
          };
        },
        { totalAmount: 0, totalCost: 0, totalCo2: 0 }
      );
      return [
        ...registrations,
        { date: 'total', amount: totalAmount, cost: totalCost, co2: totalCo2 }
      ];
    }
    return registrations;
  };

  isRowEditable = (row: Registration, lockedDays?: number) => {
    const canEdit = row.date !== 'total';
    return canEdit && !isRegistrationLockedForDate(new Date(row.date), lockedDays);
  };

  render() {
    const { registrations, intl, lockedDays } = this.props;

    const headerData = [
      { property: 'date', translationKey: 'date' },
      { property: 'registrationPoint', translationKey: 'registrationPoint' },
      { property: 'amount', translationKey: 'registration.history.weight', alignRight: true },
      { property: 'cost', translationKey: 'registration.history.cost', alignRight: true },
      { property: 'co2', translationKey: 'registration.history.co2', alignRight: true }
    ];

    return (
      <Container
        className='recent-history'
        title={<FormattedMessage id='registration.recentHistory' />}
      >
        <AlteredMaterialTable
          noContentMessage={intl.messages['noRegistrationsFound']}
          tableRef={this.tableRef}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={this.renderColumns(headerData)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options={materialTableOptions}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          actions={[(row: Registration) => (row.offlineAt ? <SyncButton /> : null)]}
          data={this.addTotalRow(this.sortedRegistrations(registrations))}
          components={{
            Header: this.overrideTableHead,
            Body: this.overrideTableBody,
            OverlayLoading: this.overrideOverlay,
            EditRow: this.overrideEditRow,
            Action: this.overrideAction
          }}
          editable={{
            isEditable: (rowData: Registration) => this.isRowEditable(rowData, lockedDays),
            isDeletable: (rowData: Registration) => this.isRowEditable(rowData, lockedDays),
            onRowUpdate: this.onRowUpdate,
            onRowDelete: this.onRowDelete
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(withStyles(styles)(History));
