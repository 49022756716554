import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { RegistrationActions } from 'redux/ducks/registration';
import RegistrationSuccessModal from 'pages/Registration/RegistrationSuccessModal';
import StarterPlanRegister from 'pages/Registration/StarterRegister/StarterRegister';
import { SettingsActions } from 'redux/ducks/settings';
import { getRegistrationPoints } from 'redux/ducks/data/registrationPoints';
import { ReasonQuery } from 'redux/ducks/reasons';
import * as reasonActions from 'redux/ducks/reasons';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RegisterProps = StateProps & DispatchProps & InjectedIntlProps;

const Register: React.FunctionComponent<RegisterProps> = (props) => {
  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const { getRegistrationPoints, getAll } = props;

    void getRegistrationPoints();
    void getAll();
  };

  return (
    <div>
      <StarterPlanRegister />
      <RegistrationSuccessModal />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  step: state.registration.step
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, SettingsActions | RegistrationActions>
) => ({
  getRegistrationPoints: () => dispatch(getRegistrationPoints({ '$sort[name]': 1 })),
  getAll: (query?: ReasonQuery) => dispatch(reasonActions.getAll(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Register));
