import * as React from 'react';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import FilterHeader from './FilterHeader';
import * as reportDispatch from 'redux/ducks/reportFilter';

import { withRouter, WithRouterProps } from 'react-router';
import parseFilterQueryParams from './utils/parseFilterQueryParams';
import {
  Dimension,
  getCachedState,
  ReportActions,
  RegistrationPointNamesByLabel,
  Order,
  InitFilterOptions
} from 'redux/ducks/reportFilter';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { getCompareToFilters, getGuestTypeNames } from 'redux/ducks/reportFilter/selectors';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { makeStyles } from '@material-ui/styles';
import { useFeature } from 'flagged';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  hidden?: boolean;
}

interface ComponentProps extends StateProps, DispatchProps, WithRouterProps, OwnProps {}

const useStyles = makeStyles({
  spinner: { margin: '0 -12px' }
});

const FilterHeaderContainer: React.FunctionComponent<ComponentProps> = (props) => {
  const classes = useStyles(props);
  const {
    accountId,
    filter,
    registrationPointFilters,
    initFilter,
    changeDimension,
    location,
    updateURL,
    onAccountChange,
    onRegistrationPointChange,
    onAddRegistrationPointFilter,
    onRemoveRegistrationPointFilter,
    onOrderChange,
    guestTypeNames,
    onGuestTypeChange,
    hidden,
    planType
  } = props;

  useEffect(() => {
    updateLocation();
  }, [filter]);

  const updateLocation = () => {
    if (!filter.loading) {
      updateURL();
    }
  };

  useEffect(() => {
    // only called once, when any report page is mounted
    initFilters();
  }, []);

  const initFilters = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const parsedFilter = parseFilterQueryParams(location);
    // need to fix the whole caching thing, maybe move to backend?
    // key should be generated consistently, needs an abstraction
    const cachedState = getCachedState(`${accountId}_${planType}`);
    // if user has not provided any query params, read them from cache

    if (cachedState && location.search.length === 0) {
      const nextFilterState = parsedFilter.basis
        ? { ...cachedState.state, basis: parsedFilter.basis }
        : cachedState.state;
      void initFilter(nextFilterState);
    } else {
      void initFilter(parsedFilter);
    }
  };

  const handleDimension = (event: React.MouseEvent<HTMLElement>, newDimension: Dimension) => {
    if (!newDimension) {
      return;
    }

    void changeDimension(newDimension);
  };

  const handleGuestTypeChange = (names: string[]) => {
    void onGuestTypeChange(names);
  };

  const handleRegistrationPointChange = (id: number, points: RegistrationPointNamesByLabel) => {
    void onRegistrationPointChange(id, points);
  };

  const handleAccountChange = (id: number, accounts: string[]) => {
    void onAccountChange(id, accounts);
  };

  const handleAddRegistrationPointFilter = () => {
    void onAddRegistrationPointFilter();
  };

  const handleRemoveRegistrationPointFilter = (id: number) => {
    void onRemoveRegistrationPointFilter(id);
  };

  const handleOrderChange = (id: number, order: Order) => {
    void onOrderChange(id, order);
  };

  const { pathname } = location;

  const isFreeReportFeature = useFeature('report/free') as boolean;

  const filterConfig = {
    enableDimension:
      !pathname.includes('report/frequency') &&
      !pathname.includes('report/sustainability') &&
      !isFreeReportFeature,
    enableRegistrationPoints:
      !pathname.includes('report/frequency') &&
      !pathname.includes('report/sustainability') &&
      !isFreeReportFeature,
    enableComparison: pathname.includes('report/accounts') && !isFreeReportFeature,
    enableSort:
      (pathname.includes('report/accounts') || pathname.includes('report/frequency')) &&
      !isFreeReportFeature,
    disableAdvancedFilter: isFreeReportFeature
  };

  if (hidden) {
    return null;
  }

  return filter.isInitialized ? (
    <FilterHeader
      filter={filter}
      onDimensionChange={handleDimension}
      config={filterConfig}
      registrationPointFilters={registrationPointFilters}
      onAccountChange={handleAccountChange}
      onRegistrationPointChange={handleRegistrationPointChange}
      onAddRegistrationPointFilter={handleAddRegistrationPointFilter}
      onRemoveRegistrationPointFilter={handleRemoveRegistrationPointFilter}
      onOrderChange={handleOrderChange}
      guestTypeNames={guestTypeNames}
      onGuestTypeChange={handleGuestTypeChange}
    />
  ) : (
    <LoadingPlaceholder className={classes.spinner} />
  );
};

const mapStateToProps = (state: RootState) => ({
  accountId: state.user.accountId,
  planType: state.user.subscription.type,
  filter: state.reportFilter,
  // temp fix; need to refactor report filter store, but cant efficiently until
  // the whole report page layout is refactored so that filter is initialised first
  // before rendering report sub page (since they use filters)
  registrationPointFilters: getCompareToFilters(state),
  guestTypeNames: getGuestTypeNames(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  initFilter: (filter: Partial<InitFilterOptions>) => dispatch(reportDispatch.init(filter)),
  changeDimension: (dimension: Dimension) => dispatch(reportDispatch.changeDimension(dimension)),
  updateURL: () => dispatch(reportDispatch.updateURL()),
  onAccountChange: (id: number, accounts: string[]) =>
    dispatch(reportDispatch.changeAccountGroup(id, { accounts })),
  onRegistrationPointChange: (id: number, points: RegistrationPointNamesByLabel) =>
    dispatch(reportDispatch.changeAccountGroup(id, { selectedRegistrationPoints: points })),
  onAddRegistrationPointFilter: () => dispatch(reportDispatch.addAccountGroup({})),
  onRemoveRegistrationPointFilter: (id: number) => dispatch(reportDispatch.removeAccountGroup(id)),
  onOrderChange: (id: number, order: Order) =>
    dispatch(reportDispatch.changeAccountGroup(id, { order })),
  onGuestTypeChange: (names: string[]) => dispatch(reportDispatch.changeGuestTypes(names))
});

export default withRouter<OwnProps>(
  connect<StateProps, DispatchProps, ComponentProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FilterHeaderContainer)
);
