import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Grid, Typography, Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import withLoading from 'components/LoadingPlaceholder/withLoading';
import ErrorMessage from 'pages/Report/components/ErrorMessage';
import createValueFormatter from 'pages/Report/utils/createValueFormatter';
import { HighestLowestEmissionByCategory } from 'redux/ducks/reportData/selector';
import { ApiError } from 'redux/ducks/error';

interface ComponentProps extends InjectedIntlProps {
  highestLowestEmission: HighestLowestEmissionByCategory;
  error: ApiError;
}

const HighestLowestProduct: React.FunctionComponent<ComponentProps> = (props) => {
  const classes = useStyles(props);
  const { highestLowestEmission, error } = props;
  const { highest, lowest } = highestLowestEmission;

  return error ? (
    <ErrorMessage error={error} />
  ) : (
    <div>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={6}>
          <div className={classes.emissionMetric}>
            <ArrowUpward className={classes.arrowUp} />
            <Typography variant={'h3'} className={classes.value}>
              {createValueFormatter('co2', 'total').format(highest?.value ?? 0, highest?.unit)}
              <sup className={classes.subValue}>
                CO<sub>2</sub>
              </sup>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.product}>{highest?.label}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={6}>
          <div className={classes.emissionMetric}>
            <ArrowDownwardIcon className={classes.arrowDown} />
            <Typography variant={'h3'} className={classes.value}>
              {createValueFormatter('co2', 'total').format(lowest?.value ?? 0, lowest?.unit)}
              <sup className={classes.subValue}>
                CO<sub>2</sub>
              </sup>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.product}>{lowest?.label}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles<Theme, ComponentProps>((theme) => ({
  emissionMetric: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '48px 36px',
    ['@media screen and (max-width: 800px)']: {
      padding: '24px 12px'
    },
    ['@media screen and (max-width: 599px)']: {
      padding: '24px 12px 0'
    }
  },
  value: {
    fontSize: theme.typography.pxToRem(35),
    lineHeight: theme.typography.pxToRem(42),
    fontWeight: 900,
    color: theme.palette.text.primary
  },
  subValue: {
    color: theme.palette.text.secondary
  },
  product: {
    marginLeft: '12px',
    fontSize: theme.typography.pxToRem(24),
    ['@media screen and (max-width: 599px)']: {
      padding: '0 12px 12px'
    }
  },
  arrowUp: {
    color: theme.palette.error.main,
    height: '50px',
    width: '50px'
  },
  arrowDown: {
    color: theme.palette.success.main,
    height: '50px',
    width: '50px'
  }
}));

export default injectIntl(withLoading(HighestLowestProduct));
