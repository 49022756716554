import * as React from 'react';
import { useAuth } from 'frontend-core';
import SplashScreen from 'components/SplashScreen';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { UiActions } from 'redux/ducks/ui';
import { bootstrapUser, BootstrapUserData, UserActions, UserProfile } from 'redux/ducks/user';
import { SettingsActions } from 'redux/ducks/settings';
import { ErrorActions } from 'redux/ducks/error';
import * as userDispatch from 'redux/ducks/user';
import { useFeature } from 'flagged';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as featuresDispatch from 'redux/ducks/features';
import { FeaturesActions } from 'redux/ducks/features';

// TODO: look into why our app gets re-rendered so many times during bootstrap
// TODO: this logic is way too complex, same goes for fe-haccp, need to simplify
// flow: authenticated => init user => show app
// intl provider is rendered pointlessly many times

type BootstrapState =
  | 'init'
  | 'auth-loaded'
  | 'user-authenticated'
  | 'user-fetched'
  | 'features-fetched'
  | 'ready';

// HOC component that makes sure the app is bootstrapped with all necessary data
const BootstrapInternal: React.FC<Props> = (props) => {
  const { isLoading, isAuthenticated, login, user } = useAuth();
  const [state, setState] = React.useState<BootstrapState>('init');

  // need to streamline bootsrapping, cant remember why haccp requires empty bootstrap
  const requiresHaccpBootstrap = useFeature('bootstrap/haccp');
  const requiresBootstrapFinish = useFeature('bootstrap/preInitialised');
  const bootstrapRequired = requiresHaccpBootstrap || requiresBootstrapFinish;

  const { featureState, userState, initUser, bootstrapUser, intl, getFeatures, children } = props;

  React.useEffect(() => {
    switch (state) {
      case 'auth-loaded': {
        void login();
        return;
      }
      case 'user-authenticated':
        void getFeatures();
        return;
      case 'features-fetched':
        if (bootstrapRequired) {
          //does a full page reload
          void bootstrapUser();
          return;
        }
        void initUser(user);
        return;
    }
  }, [state]);

  React.useEffect(() => {
    if (!isLoading) {
      setState(isAuthenticated() ? 'user-authenticated' : 'auth-loaded');
    }
  }, [isLoading, isAuthenticated()]);

  React.useEffect(() => {
    if (featureState === 'fetched') {
      setState('features-fetched');
    }
  }, [featureState]);

  React.useEffect(() => {
    if (userState === 'fetched') {
      setState('ready');
    }
  }, [userState]);

  if (state !== 'ready') {
    return <SplashScreen label={intl.messages['base.loading']} />;
  }

  return children;
};

const mapStateProps = (state: RootState) => ({
  featureState: state.features.status,
  userState: state.user.state
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    RootState,
    void,
    UiActions | UserActions | SettingsActions | FeaturesActions | ErrorActions
  >
) => ({
  initUser: (user: UserProfile) => dispatch(userDispatch.initUser(user)),
  bootstrapUser: async (data?: BootstrapUserData) => dispatch(bootstrapUser(data)),
  getFeatures: () => dispatch(featuresDispatch.getFeatures())
});

type StateProps = ReturnType<typeof mapStateProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps & InjectedIntlProps & { children: React.ReactElement };

export const Bootstrap = connect<StateProps, DispatchProps, unknown>(
  mapStateProps,
  mapDispatchToProps
)(injectIntl(BootstrapInternal));
