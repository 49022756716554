import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { Box, Grid, Divider, Button, Link } from '@material-ui/core';
import SVGInline from 'react-svg-inline';
import { makeStyles } from '@material-ui/core/styles';
import esmileyLogo from 'static/img/esmiley-logo-white.svg';
import Apple from 'static/icons/apple.svg';
import Google from 'static/icons/google.svg';
import classNames from 'classnames';
import { Icon } from 'components/Icon';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      background: 'linear-gradient(180deg, rgba(2,93,136,1) 0%, rgba(0,130,197,1) 100%)',
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    logo: {
      width: '200px'
    },
    innerContainer: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '6px',
      padding: '12px 10px',
      height: 'min-content',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '450px',
      marginBottom: '100px'
    },
    actionTitle: {
      marginTop: '15px',
      color: '#818181',
      fontWeight: 'bold',
      fontSize: '1.05rem'
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '20px',
      width: '100%'
    },
    divider: {
      backgroundColor: 'rgba(136,136,136,0.3)',
      width: '95%',
      height: '2px',
      marginTop: '20px',
      marginBottom: '20px'
    },
    button: {
      border: '2px solid #aad8d6',
      color: '#008b87',
      fontWeight: 'bold',
      width: '100%',
      height: '70px',
      fontFamily: 'inherit'
    },
    link: {
      marginTop: '-10px',
      marginBottom: '-10px',
      width: '100%'
    }
  };
});

const Success: React.FunctionComponent<InjectedIntlProps> = (props) => {
  const { intl } = props;
  const classes = useStyles(props);
  const { GOOGLE_PLAY, APP_STORE } = window.sysvars;
  const accessToken = sessionStorage.getItem('accessToken');
  const refreshToken = sessionStorage.getItem('refreshToken');
  const continueLink =
    accessToken && refreshToken
      ? `/?access_token=${accessToken}&refresh_token=${refreshToken}`
      : '/';

  return (
    <Grid item xs={12} className={classNames(classes.container)}>
      <Icon icon={esmileyLogo} className={classes.logo} />
      <Box className={classes.innerContainer}>
        <span className={classes.actionTitle}>{intl.messages['signup.landing.download']}</span>
        <div className={classes.actionContainer}>
          <Link className={classes.link} href={APP_STORE} target='_blank'>
            <SVGInline svg={Apple} />
          </Link>
          <Link className={classes.link} href={GOOGLE_PLAY} target='_blank'>
            <SVGInline svg={Google} />
          </Link>
        </div>
        <Divider className={classes.divider} />
        <Button classes={{ root: classes.button }} variant={'outlined'} href={continueLink}>
          {intl.messages['signup.landing.continue']}
        </Button>
      </Box>
    </Grid>
  );
};

export default injectIntl(Success);
