import './index.scss';
import Container from 'components/Container';
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  colors,
  Tooltip,
  Slide
} from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import moment from 'moment';
import { GuestRegistration, IPatchObject } from 'redux/ducks/guestRegistrations';
import AlteredMaterialTable from 'components/MaterialTable';
import { isIE11 } from 'utils/browsers';
import { Column, EditComponentProps } from 'material-table';
import classNames from 'classnames';
import { scrollToEl } from 'utils/helpers';
import CustomTableBody from 'components/MaterialTable/components/customTableBody';
import CustomTableHead from 'components/MaterialTable/components/customTableHead';
import CustomEditRow from 'components/MaterialTable/components/customEditRow';
import NumberInput from 'components/Input/NumberInput';
import isRegistrationLockedForDate from 'pages/Registration/utils/isRegistrationLocked';
import CustomTableAction from 'components/MaterialTable/components/customTableAction';
import { SyncButton } from 'pages/Registration/SyncButton';

const NEW_OR_UPDATED_ROW = 'new-or-updated-row';
const deleteTransitionDuration = 1000;

// MT options (https://material-table.com/#/docs/all-props)
const materialTableOptions = {
  actionsColumnIndex: -1,
  showTitle: false,
  search: false,
  searchFieldAlignment: 'left',
  emptyRowsWhenPaging: false,
  paginationType: 'stepped',
  toolbarButtonAlignment: 'right',
  headerStyle: {
    backgroundColor: colors.grey['50']
  },
  rowStyle: {
    //CRAS: IE11 has an issue with transitioning opacity on table rows
    transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
    borderBottom: `1px solid ${colors.grey['200']}`,
    height: 55
  },
  pageSize: 25,
  pageSizeOptions: [10, 15, 20, 25],
  actionsCellStyle: {
    whiteSpace: 'pre'
  }
};

const styles = createStyles({
  statusDot: {
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    width: '10px',
    height: '10px',
    position: 'relative'
  },
  deleteButton: {
    width: 'initial',
    height: 'initial',
    padding: 0,
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  offlineBox: {
    display: 'flex',
    alignItems: 'center'
  },
  offlineBoxText: {
    marginLeft: 10
  }
});

export interface HistoryColumn {
  property: keyof GuestRegistration;
  translationKey: string;
  alignRight?: boolean;
  hidden?: boolean;
  numeric?: boolean;
}

type EditedRowData = GuestRegistration & {
  tableData: {
    id: number;
    editing?: boolean;
  };
};

export interface OverrideEditRowProps {
  data: GuestRegistration;
  mode: 'delete' | 'edit';
  icons: any;
}

interface IComponentProps extends InjectedIntlProps, WithStyles<typeof styles> {
  columns: HistoryColumn[];
  registrations: GuestRegistration[];
  onDelete: (id: string) => void;
  onUpdate: (id: string, patchObject: IPatchObject[]) => void;
  lockedDays?: number;
}

class History extends React.Component<IComponentProps> {
  deletedRow: any;
  tableRef = React.createRef<any>();
  private updatedRow: number | string;

  constructor(props: IComponentProps) {
    super(props);
  }

  renderColumns = (columns: HistoryColumn[]): Column<GuestRegistration>[] => {
    return columns.map(({ property, translationKey }) => {
      switch (property) {
        case 'date':
          return this.setupDateColumn(property, translationKey);
        case 'guestType':
          return this.setupGuestTypeColumn(property, translationKey);
        case 'amount':
          return this.setupAmountColumn(property, translationKey);
        default:
          return;
      }
    });
  };

  setupDateColumn = (key: keyof GuestRegistration, intlKey: string): Column<GuestRegistration> => {
    const { intl, classes } = this.props;

    return {
      title: intl.messages[intlKey],
      field: key,
      editable: 'never',
      render: (row) =>
        row.date === 'total' ? (
          <Typography>{intl.messages['report.registration_list.total']}</Typography>
        ) : (
          <>
            <Typography>{moment(row.date).format('L')}</Typography>
            {row.offline && (
              <div className={classes.offlineBox}>
                <Typography className={classes.offlineBoxText} variant='caption'>
                  {intl.messages['registration.waitingForConnection']}
                </Typography>
              </div>
            )}
          </>
        )
    };
  };

  setupGuestTypeColumn = (
    key: keyof GuestRegistration,
    intlKey: string
  ): Column<GuestRegistration> => {
    const { intl, classes } = this.props;

    return {
      title: intl.messages[intlKey],
      field: key,
      editable: 'never',
      customSort: (a, b) =>
        a.guestType?.name.localeCompare(b.guestType?.name, intl.locale, { sensitivity: 'base' }),
      render: (row) => {
        const { guestType } = row;
        if (!guestType) {
          return '';
        }
        const status = guestType
          ? guestType.deletedAt
            ? 'deleted'
            : guestType.active
            ? 'active'
            : 'inactive'
          : 'active';
        const toolTipTitle = intl.messages[`settings.status.${status}`];
        return (
          <>
            <Tooltip title={toolTipTitle} enterTouchDelay={50} placement={'bottom'}>
              <span className={classNames(classes.statusDot, status)} />
            </Tooltip>
            {(guestType && guestType.name) || '-'}
          </>
        );
      }
    };
  };

  setupAmountColumn = (
    key: keyof GuestRegistration,
    intlKey: string
  ): Column<GuestRegistration> => {
    const { intl } = this.props;

    return {
      title: intl.messages[intlKey],
      field: key,
      type: 'numeric',
      editComponent: (props) => this.renderAmountEditMode(props, intlKey)
    };
  };

  renderAmountEditMode = (
    props: EditComponentProps<GuestRegistration>,
    key: string
  ): JSX.Element => {
    const { intl } = this.props;

    return (
      <NumberInput
        type={'text'}
        required
        value={props.value as number}
        name={`${key}_field`}
        allowNegative={false}
        min={0}
        thousandSeparator={false}
        label={intl.messages[`${key}`]}
        onChange={(e) => props.onChange(e.target.value)}
      />
    );
  };

  resetHelperGlobals = (): void => {
    this.deletedRow = undefined;
  };

  onRowUpdate = async (newData: EditedRowData, oldData: EditedRowData) => {
    const amountToUpdate = Number(newData.amount);
    const patchObject = [{ op: 'replace', path: '/amount', value: amountToUpdate }];
    this.props.onUpdate(oldData.id, patchObject);
    scrollToEl(NEW_OR_UPDATED_ROW);
    this.resetHelperGlobals();
    return;
  };

  onRowDelete = async (data: GuestRegistration) => {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        this.props.onDelete(data.id);
        this.updatedRow = undefined;
        this.resetHelperGlobals();
        resolve();
      }, deleteTransitionDuration - 200);
    });
  };

  overrideTableBody = (props): JSX.Element => {
    return <CustomTableBody {...props} />;
  };

  overrideTableHead = (props): JSX.Element => {
    return <CustomTableHead {...props} draggable={false} />;
  };

  overrideOverlay = (): JSX.Element => <span />;

  overrideEditRow = (props: OverrideEditRowProps): JSX.Element => {
    let deleteConfirmationText = '';
    const slidingIn = props?.data?.id !== this.deletedRow;
    const slideDuration = props?.data?.id === this.deletedRow ? deleteTransitionDuration : 0;

    if (props.mode === 'delete') {
      const { intl } = this.props;
      const {
        data: { guestType, amount, date }
      } = props;

      const deleteDescription = guestType ? guestType.name : moment(date).format('L');

      deleteConfirmationText = intl.formatMessage(
        { id: 'base.confirm.deletion.description' },
        { name: `${deleteDescription}, ${amount}` }
      );
    }

    return (
      <Slide in={slidingIn} direction={'left'} timeout={slideDuration}>
        <CustomEditRow
          {...props}
          deleteConfirmationText={deleteConfirmationText}
          onCancelHandler={this.onCancelHandler}
          onSubmitHandler={this.onSubmitHandler}
        />
      </Slide>
    );
  };

  overrideAction = (props): JSX.Element => {
    return <CustomTableAction {...props} />;
  };

  onCancelHandler = (): void => {
    this.resetHelperGlobals();
  };

  onSubmitHandler = (data: GuestRegistration, mode?: 'delete' | 'edit'): void => {
    if (mode === 'delete') {
      this.deletedRow = data.id;
    } else {
      this.updatedRow = data.id;
    }
  };

  sortedRegistrations = (registrations: GuestRegistration[]) =>
    registrations.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  addTotalRow = (registrations: GuestRegistration[]) => {
    const totalSum = registrations.reduce((sum, { amount }) => {
      return sum + amount;
    }, 0);
    return [...registrations, { date: 'total', amount: totalSum }];
  };

  isRowEditable = (row: GuestRegistration, lockedDays?: number) => {
    const canEdit = row.date !== 'total';
    return canEdit && !isRegistrationLockedForDate(new Date(row.date), lockedDays);
  };

  render() {
    const { registrations, columns, intl, lockedDays } = this.props;

    return (
      <Container
        className='recent-history-guests'
        title={intl.messages['registration.recentHistory']}
      >
        <AlteredMaterialTable
          noContentMessage={intl.messages['noRegistrationsFound']}
          tableRef={this.tableRef}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={this.renderColumns(columns)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options={materialTableOptions}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          actions={[(row: GuestRegistration) => (row.offlineAt ? <SyncButton /> : null)]}
          data={this.addTotalRow(this.sortedRegistrations(registrations))}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          components={{
            Header: this.overrideTableHead,
            Body: this.overrideTableBody,
            OverlayLoading: this.overrideOverlay,
            EditRow: this.overrideEditRow,
            Action: this.overrideAction
          }}
          editable={{
            isEditable: (rowData: GuestRegistration) => this.isRowEditable(rowData, lockedDays),
            isDeletable: (rowData: GuestRegistration) => this.isRowEditable(rowData, lockedDays),
            onRowUpdate: this.onRowUpdate,
            onRowDelete: this.onRowDelete
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(withStyles(styles)(History));
