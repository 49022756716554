import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Typography } from '@material-ui/core';

interface ComponentProps extends InjectedIntlProps {
  id: string;
  trend?: number;
  value?: string;
  target?: string;
}

export const ProgressText: React.FunctionComponent = ({ children }) => (
  <Typography color={'textSecondary'} display={'inline'} style={{ fontWeight: 900 }}>
    {children}
  </Typography>
);

const ProgressMessage: React.FunctionComponent<ComponentProps> = ({
  id,
  trend,
  value,
  target,
  intl
}) => (
  <FormattedMessage
    tagName='div'
    id={id}
    values={{
      trend: trend && (
        <ProgressText>
          {intl.formatNumber(Math.abs(trend), { maximumFractionDigits: 2 }) + '% '}
        </ProgressText>
      ),
      value,
      target
    }}
  />
);

export default injectIntl(ProgressMessage);
