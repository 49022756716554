import * as React from 'react';
import { CardContent, Grid, Card, Box, Typography, useMediaQuery } from '@material-ui/core';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import { WithRouterProps } from 'react-router';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type FreeOverviewProps = StateProps & InjectedIntlProps & WithRouterProps & DispatchProps;

import withRouter from 'react-router/lib/withRouter';
import { Bar } from 'pages/Report/components/Chart';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { ReportActions } from 'redux/ducks/reportFilter';
import * as reportDispatch from 'redux/ducks/reportData';
import createValueFormatter from 'pages/Report/utils/createValueFormatter';
import moment from 'moment';
import getChartData from 'pages/Report/components/Chart/utils/getChartData';
import {
  seriesMappers,
  themeMapper
} from 'pages/Report/FoodWaste/components/FoodWasteStatus/utils/chartMappers';
import { getTotalGuests } from 'redux/ducks/reportData/selector';
import classNames from 'classnames';
import { PERIOD_FORMAT } from 'utils/datetime';
import { FetchDataOptions, ReportDataIdentifier } from 'redux/ducks/reportData';
import { CHART_COLORS } from 'pages/Report';

const identifier = 'foodWasteStatus';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1)
  },
  cardHeader: {
    textAlign: 'center',
    padding: 0,
    fontWeight: 'bold'
  },
  box: {
    marginTop: 0,
    marginBottom: 0
  },
  guestNumber: {
    fontSize: '48px',
    marginTop: theme.spacing(3)
  }
}));

const GuestsFoodwastePerMonth: React.FunctionComponent<FreeOverviewProps> = (props) => {
  const { intl, isLoading, foodwasteStatus, fetchData, totalGuestsMetric, filter, accounts } =
    props;
  const { data, dimension } = foodwasteStatus;
  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  useEffect(() => {
    void fetchData(identifier, { basis: 'per-guest' });
    void fetchData('guestRegistrations');
  }, [filter.timeRange, filter.period, accounts, filter.filter]);

  const valueFormatter = createValueFormatter(dimension, 'per-guest');
  const labelFormatter = {
    format: (timestamp: string): string => moment(timestamp).format(PERIOD_FORMAT['week'])
  };

  const chartOptions = getChartData(
    {
      series: (data.series[0] || {}).series || [],
      chartColors: CHART_COLORS,
      plotLines: {},
      intl
    },
    seriesMappers(),
    themeMapper(valueFormatter, labelFormatter)
  );

  return (
    <>
      <Card className={classes.card}>
        <Typography variant={'h1'} component={'h1'} className={classes.cardHeader}>
          {intl.messages['report.metric.overview.total_guests']}
        </Typography>
        <CardContent>
          <Grid container alignContent={'flex-start'} justify={'center'}>
            <Typography
              variant={'h1'}
              component={'h1'}
              className={classNames(classes.cardHeader, classes.guestNumber)}
            >
              {totalGuestsMetric.data}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <Typography variant={'h1'} component={'h1'} className={classes.cardHeader}>
          {intl.messages['report.foodwaste.perGuest.status.title.week']}
        </Typography>
        <CardContent>
          <Box className={classes.box} mb={2} mt={1.5}>
            <Grid container alignContent={'flex-start'} justify={'center'}>
              <Bar
                chartOptions={{
                  ...chartOptions,
                  chart: { ...chartOptions.chart, width: isMobile ? 300 : null },
                  legend: { enabled: false }
                }}
                isLoading={isLoading}
              />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    filter: state.reportFilter,
    isLoading: state.reportFilter.loading,
    foodwasteStatus: state.reportData[identifier],
    error: state.reportData[identifier].error,
    totalGuestsMetric: getTotalGuests(state),
    accounts: state.reportFilter.filter.accounts
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  fetchData: (identifier: ReportDataIdentifier, options?: FetchDataOptions) =>
    dispatch(reportDispatch.fetchData(identifier, options))
});

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(GuestsFoodwastePerMonth)));
