import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, MobileStepper } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as errorDispatch from 'redux/ducks/error';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from 'redux/rootReducer';
import { getSettings, SettingsActions, SettingsState } from 'redux/ducks/settings';
import { ThunkDispatch } from 'redux-thunk';
import { Spinner } from 'components/LoadingPlaceholder';

import { DataTransfer } from 'frontend-core';
import { useFeature } from 'flagged';
import Template from 'components/OnboardDialog/BootstrapDialog/Template';
import Plan from 'components/OnboardDialog/BootstrapDialog/Plan';
import { Subscription } from 'redux/ducks/user';
import { ErrorActions } from 'redux/ducks/error';

const transfer = new DataTransfer();

const useStyles = makeStyles((theme) => ({
  currencySelector: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      flex: '0 0 auto',
      marginRight: theme.spacing(2)
    }
  },
  buttonFooter: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& button + button': {
      marginLeft: theme.spacing(1)
    }
  },
  stepper: {
    backgroundColor: 'transparent',
    justifyContent: 'center'
  },
  spinnerContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spinner: {
    display: 'flex',
    flex: '1 1 100%',
    justifyContent: 'center'
  }
}));

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface OwnProps {
  onClose: () => void;
}

type BootstrapDialogProps = StateProps & DispatchProps & InjectedIntlProps & OwnProps;

const ButtonLabels = { next: 'base.next', save: 'base.save' };

interface BootstrapStep {
  title: string;
  allowSkip?: boolean;
  defaultValues?: (state: Partial<SettingsState>) => Partial<SettingsState>;
  isValid: (state: Partial<SettingsState>) => boolean;
  component: React.ComponentType<{
    subscription?: Subscription;
    settings?: Partial<SettingsState>;
    onChange?: (settings: Partial<SettingsState>) => void;
  }>;
}

interface State {
  settings: Partial<SettingsState>;
  byStep: { [step: number]: Partial<SettingsState> };
  step: number;
  steps: number;
  skipped: number[];
  state: 'edit' | 'submit' | 'loading';
}

type NextAction = {
  type: 'stepFinished';
};

type SkipAction = {
  type: 'stepSkipped';
};

type UpdateAction = {
  type: 'stepUpdated';
  payload: Partial<SettingsState>;
};

type SubmitAction = {
  type: 'submitted';
};

type Actions = NextAction | SkipAction | SubmitAction | UpdateAction;

const initState = (initialState: Partial<SettingsState>, steps: BootstrapStep[]): State => ({
  settings: {},
  byStep: steps.reduce<{ [step: number]: Partial<SettingsState> }>(
    (steps, stepData, step) => ({
      ...steps,
      [step]: stepData.defaultValues?.(initialState) || {}
    }),
    {}
  ),
  step: 0,
  steps: steps.length,
  skipped: [],
  state: 'edit'
});

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'stepFinished': {
      const { step, steps, byStep, settings } = state;
      const nextStep = step < steps - 1 ? step + 1 : step;
      return {
        ...state,
        step: nextStep,
        settings: { ...settings, ...byStep[step] },
        state: nextStep === step ? 'submit' : 'edit'
      };
    }
    case 'stepSkipped': {
      const { step, steps, skipped } = state;
      const nextStep = step < steps - 1 ? step + 1 : step;
      return {
        ...state,
        step: nextStep,
        skipped: [...skipped, step],
        state: nextStep === step ? 'submit' : 'edit'
      };
    }
    case 'stepUpdated': {
      const { step, byStep } = state;
      return {
        ...state,
        byStep: { ...byStep, [step]: action.payload }
      };
    }
    case 'submitted': {
      return {
        ...state,
        state: 'loading'
      };
    }
    default:
      return state;
  }
};

const BootstrapDialog: React.FunctionComponent<BootstrapDialogProps> = (props) => {
  const {
    intl,
    onClose,
    displayError,
    settings,
    user: { subscription }
  } = props;
  const hasPlanFeature = useFeature('bootstrap/plan');
  const hasTemplateFeature = useFeature('bootstrap/template');
  const hasHaccpFeature = useFeature('bootstrap/haccp');

  const classes = useStyles(props);

  const steps = React.useMemo(() => {
    const activeSteps = [] as BootstrapStep[];

    if (hasPlanFeature) {
      activeSteps.push({
        title: intl.messages['bootstrap.plan.title'],
        isValid: () => true,
        component: Plan
      });
    }
    if (hasTemplateFeature) {
      activeSteps.push({
        title:
          intl.messages[
            `bootstrap.${subscription.type === 'essential' ? 'essential' : 'template'}.title`
          ],
        allowSkip: true,
        isValid: (state: Partial<SettingsState>) => 'bootstrapTemplateId' in state,
        component: Template
      });
    }

    return activeSteps;
  }, [hasTemplateFeature, hasPlanFeature]);

  const [draft, dispatch] = React.useReducer(reducer, initState(settings, steps));

  React.useEffect(() => {
    if (draft.state === 'submit') {
      void submit();
    }
  }, [draft.state]);

  React.useEffect(() => {
    if (hasHaccpFeature) {
      void submit();
    }
  }, [hasHaccpFeature]);

  const submit = async () => {
    dispatch({ type: 'submitted' });

    const {
      settings: { bootstrapTemplateId, ...settings }
    } = draft;
    const payload = {};

    if (bootstrapTemplateId) {
      payload['templateId'] = bootstrapTemplateId;
    }

    if (settings) {
      payload['settings'] = settings;
    }

    try {
      await transfer.post('/foodwaste/bootstrap-tasks', payload);
      onClose();
    } catch (error: unknown) {
      void displayError(error as Error);
    }
  };

  const handleNext = () => {
    dispatch({ type: 'stepFinished' });
  };

  const handleSkip = () => {
    dispatch({ type: 'stepSkipped' });
  };

  const handleDraftChange = (payload: Partial<SettingsState>) => {
    const { step, byStep } = draft;
    dispatch({ type: 'stepUpdated', payload: { ...byStep[step], ...payload } });
  };

  if (hasHaccpFeature) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner className={classes.spinner} />
      </div>
    );
  }

  const { isValid, title, allowSkip, component: CurrentStep } = steps[draft.step];
  const buttonLabel = draft.step === steps.length - 1 ? ButtonLabels.save : ButtonLabels.next;

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {['loading', 'submit'].includes(draft.state) ? (
          <Spinner className={classes.spinner} />
        ) : (
          <div className='innerBootstrapDialog'>
            <CurrentStep
              subscription={subscription}
              settings={draft.byStep[draft.step]}
              onChange={handleDraftChange}
            />
            <div className={classes.buttonFooter}>
              {allowSkip && (
                <Button variant='outlined' color='primary' onClick={handleSkip}>
                  {intl.messages['skip']}
                </Button>
              )}
              <Button
                variant='contained'
                color='primary'
                className='BootstrapDialogSubmitBtn'
                disabled={!isValid(draft.byStep[draft.step])}
                onClick={handleNext}
              >
                {intl.messages[buttonLabel]}
              </Button>
            </div>
            {steps.length > 1 && (
              <MobileStepper
                className={classes.stepper}
                variant='dots'
                steps={steps.length}
                position='static'
                activeStep={draft.step}
                nextButton={null}
                backButton={null}
              />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  settings: getSettings(state),
  user: state.user
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, SettingsActions | ErrorActions>
) => ({
  displayError: (error: Error) => dispatch(errorDispatch.displayError(error))
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BootstrapDialog));
