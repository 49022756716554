import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import Nav from 'components/Nav';
import Menu from 'components/SidebarMenu';
import Notification from 'components/Notification';
import Modal from 'components/Modal';
import * as uiDispatch from 'redux/ducks/ui';
import { Location } from 'history';
import { UiActions } from 'redux/ducks/ui';
import { HelmetProps } from 'react-helmet';
import classNames from 'classnames';
import { RootState } from 'redux/rootReducer';
import { ErrorActions } from 'redux/ducks/error';
import { ThunkDispatch } from 'redux-thunk';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OnboardDialog from 'components/OnboardDialog';
import { useFeature } from 'flagged';
import ExpiredTrialModal from 'components/ExpiredTrialModal';
import { expiredStarterSubscription } from 'redux/ducks/user/selectors';
import AdPopUp from '../AdPopUp';
import ServiceWorker from 'components/ServiceWorker';
import { Typography } from '@material-ui/core';

import './index.scss';
// todo: do we still use this?
import 'react-placeholder/lib/reactPlaceholder.css';
import { useNetworkStatus } from 'frontend-core';

export interface OwnProps {
  children: JSX.Element;
  location: Location;
}

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentProps extends InjectedIntlProps, OwnProps, StoreProps, DispatchProps {}

export interface AppState {
  title: string;
}

const exceptionPages = ['/settings', '/account'];

/**
 * This is the main entrypoint for protected app content (requires user to be authenticated)
 */
const App: React.FunctionComponent<IComponentProps> = (props) => {
  const {
    intl,
    modal,
    children,
    routing,
    isMenuOpen,
    isScaleClient,
    isNotificationActive,
    hideModal,
    isScreenLocked,
    expiredSubscription
  } = props;

  const [state, setState] = React.useState<AppState>({ title: null });
  const hasBootstrapFeature = useFeature('bootstrap');
  const hasPopupFeature = useFeature('popup');
  const { online } = useNetworkStatus();
  const currentUrl = window.location.href;
  const displayExpirationOnCurrentPage = !exceptionPages.some((el) => currentUrl.includes(el));

  /**
   * Manipulates the title data from Helmet and updates the state to the new title.
   * @param { HelmetProps } titleObject: Object received from Helmet.
   * @public
   */
  const handleTitleChange = (titleObject: HelmetProps) => {
    // FIXME (Daniel) empty title => infinite loop
    if (titleObject && titleObject.title && titleObject.title.length > 0) {
      const pattern = new RegExp(/(.*)(?:-)/g);
      const title = pattern ? pattern.exec(titleObject.title)[0].replace('-', '') : state.title;
      if (state.title !== title) {
        setState((prevState) => ({ ...prevState, title }));
      }
    }
  };

  const { title } = state;
  const isDesktop = useMediaQuery('(min-width:450px)');
  const isReportPage =
    routing.locationBeforeTransitions &&
    routing.locationBeforeTransitions.pathname.indexOf('/report/') !== -1;
  const isAuthPage =
    routing.locationBeforeTransitions && routing.locationBeforeTransitions.pathname === '/auth';
  const isNavVisible = !isScaleClient && !isAuthPage;

  const mainWrapperClass = classNames('mainAppWrapper', {
    'menu-active': !isScaleClient && isMenuOpen,
    'is-reports-page': isReportPage,
    locked: isScreenLocked
  });

  const contentWrapperClass = classNames('mainAppViewContentWrapper', {
    'nav-visible': isNavVisible,
    'mobile-menu-spacing': !isDesktop
  });

  const contentClass = classNames('mainAppViewContent is-logged-in', {
    'nav-visible': isNavVisible
  });

  if (hasBootstrapFeature) {
    return (
      <main className='mainAppView'>
        <Nav title={title} isMobile={false} isBootstrap />
        <OnboardDialog />
      </main>
    );
  }

  return (
    <Modal modal={modal} hideModal={hideModal}>
      <ServiceWorker />
      <div className={mainWrapperClass}>
        <Helmet
          onChangeClientState={handleTitleChange}
          title={title}
          titleTemplate='%s - eSmiley FoodWaste'
        />
        <main className='mainAppView'>
          {isNavVisible && <Nav title={title} isMobile={!isDesktop} />}
          <div className={contentWrapperClass}>
            <div className={`${contentClass} ${isNotificationActive ? 'transition-down' : ''}`}>
              {!online && (
                <div
                  style={{
                    zIndex: 1000,
                    position: 'sticky',
                    top: isNavVisible ? 64 : 0,
                    margin: '0 -12px',
                    background: 'rgba(0,0,0, 0.4)',
                    color: '#ffffff',
                    textAlign: 'center'
                  }}
                >
                  <Typography variant='caption'>
                    {intl.messages['base.youAreWorkingOffline']}
                  </Typography>
                </div>
              )}
              {children}
              <Notification />
              {displayExpirationOnCurrentPage && expiredSubscription && <ExpiredTrialModal />}
              {hasPopupFeature && <AdPopUp />}
            </div>
            {!isScaleClient && <Menu isMobile={!isDesktop} />}
          </div>
        </main>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  modal: state.ui.modal,
  isScreenLocked: state.ui.isScreenLocked,
  routing: state.routing,
  isMenuOpen: state.ui.isMenuOpen,
  isNotificationActive: state.notification.active,
  isScaleClient: state.user.client === 'scale',
  expiredSubscription: expiredStarterSubscription(state),
  backToHaccp: state.ui.returnTo === 'haccp'
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, UiActions | ErrorActions>
) => ({
  hideModal: () => dispatch(uiDispatch.hideModal())
});

const ConnectedApp = connect<StoreProps, DispatchProps, OwnProps & InjectedIntlProps>(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default injectIntl(ConnectedApp);
