import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as signupDispatch from 'redux/ducks/signup';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Box, Typography, Divider, Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { eSmileyBlue } from 'styles/palette';
import { SignupActions, SignUpData } from 'redux/ducks/signup/types';
import { onSubmitForm } from 'utils/helpers';
import InputValidationCode from 'components/ValidationCodeInput';

interface SignupPageProps {
  onStepChange: React.Dispatch<React.SetStateAction<number>>;
  data: SignUpData;
  onChange: (value: string, target: string) => void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type OwnProps = DispatchProps & InjectedIntlProps & SignupPageProps & StateProps;

const useStyles = makeStyles((theme) => ({
  input: {
    border: '1px solid ' + theme.palette.grey.A100,
    borderRadius: '6px',
    padding: '6px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none'
      }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '12px 0 0 0'
  },
  inputField: {
    margin: '8px 0'
  },
  label: { ...theme.typography.h3, fontWeight: theme.typography.fontWeightBold },
  arrow: {
    ...theme.typography.h1,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    color: theme.palette.text.primary,
    margin: '0 0 8px 0'
  },
  signInLabel: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightLight,
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0 0 0',
    flexWrap: 'wrap'
  },
  formContainer: {
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px',
    padding: '12px 10px',
    height: '550px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    maxWidth: '400px',
    marginBottom: '20px'
  },
  container: {
    background: 'linear-gradient(180deg, rgba(2,93,136,1) 0%, rgba(0,130,197,1) 100%)',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  divider: {
    margin: '18px 0 12px 0',
    color: theme.palette.grey.A100
  },
  textLink: {
    ...theme.typography.subtitle2,
    margin: '0 0 0 3px',
    fontWeight: theme.typography.fontWeightBold,
    color: eSmileyBlue
  },
  button: {
    backgroundColor: eSmileyBlue,
    color: theme.palette.background.paper,
    height: '60px',
    textTransform: 'none',
    fontSize: '16px'
  },
  buttonResend: {
    height: '60px',
    textTransform: 'none',
    borderColor: eSmileyBlue,
    color: eSmileyBlue,
    margin: '3px 0 18px 0',
    fontSize: '16px'
  },
  footerText: {
    fontSize: '12px'
  },
  select: {
    color: theme.palette.grey.A400,
    border: 0,
    width: '45px'
  },
  flagIcon: {
    margin: '4px 0 0 0'
  },
  headerLabel: {
    color: theme.palette.background.default,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px'
  },
  sendLabel: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

const SignUpStepFour: React.FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { handleSignUp, intl, data, onChange, verificationStatus, handleSmsVerification } = props;
  const { prefix, phone } = data;

  const handleSubmit = () => {
    handleSignUp(data);
  };

  const completeHandle = (code: string) => {
    onChange(code, 'code');
  };

  const handleResend = () => {
    handleSmsVerification(`${prefix} ${phone}`);
  };

  return (
    <Box className={classes.formContainer} component='form' onSubmit={onSubmitForm(handleSubmit)}>
      <div>
        <Typography className={classes.headerLabel} variant='body1' component='h1'>
          {intl.messages['signup.codeMessage']}
        </Typography>
        <div className={classes.form}>
          <InputValidationCode
            length={6}
            label={'Verification'}
            loading={false}
            onComplete={completeHandle}
            required
            error={verificationStatus === 'error'}
          />
        </div>
      </div>

      <div>
        <Typography
          className={classes.sendLabel}
        >{`${intl.messages['signup.sendTo']} ${prefix} ${phone}`}</Typography>
        <Divider className={classes.divider} />

        <Button
          onClick={handleResend}
          fullWidth
          variant='outlined'
          className={classes.buttonResend}
        >
          {intl.messages['signup.sendAgain']}
        </Button>

        <Button type='submit' fullWidth variant='contained' className={classes.button}>
          {intl.messages['signup.verify']}
        </Button>

        <div className={classes.signInLabel}>
          <Typography variant='body1' component='p'>
            {intl.messages['base.tos.message']}
          </Typography>
          <Link
            target='_blank'
            href={intl.messages['url.termsAndConditions']}
            className={classes.textLink}
            underline='none'
          >
            {intl.messages['base.tos']}
          </Link>
        </div>
      </div>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, SignupActions>) => ({
  handleSignUp: (data: SignUpData) => {
    void dispatch(signupDispatch.signup(data));
  },
  handleSmsVerification: (phone: string) => {
    void dispatch(signupDispatch.sendVerificationCode(phone));
  }
});

const mapStateToProps = (state: RootState) => ({
  verificationStatus: state.signup.verificationStatus
});

export default connect<StateProps, DispatchProps, SignupPageProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SignUpStepFour));
