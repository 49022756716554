import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as registrationDispatch from 'redux/ducks/registration';
import {
  Grid,
  Fade,
  Box,
  Button,
  Typography,
  ButtonGroup,
  Card,
  Link,
  InputAdornment
} from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { onSubmitForm } from 'utils/helpers';
import { RootState } from 'redux/rootReducer';
import { getSettings } from 'redux/ducks/settings';
import { ThunkDispatch } from 'redux-thunk';
import {
  CreateFreeRegistration,
  RegistrationActions,
  RegistrationAvoidable
} from 'redux/ducks/registration';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { fetchRegistrations, FetchRegistrationsOptions } from 'redux/ducks/data/registrations';
import moment, { Moment } from 'moment';
import StarterPlanHistoryTable from 'pages/Registration/StarterRegister/StarterHistoryTable';
import { TopbarMenu } from 'styles/themes/global';
import Helmet from 'react-helmet';
import { Feature, useFeature } from 'flagged';
import SelectReason from 'pages/Registration/Register/Overview/SelectReason';
import { Reason } from 'redux/ducks/reasons';
import { getActiveReasons } from 'redux/ducks/reasons/selectors';
import { DatePicker } from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import NumberInput from 'components/Input/NumberInput';
import { NumberFormatValues } from 'react-number-format';
import { DateType } from '@date-io/type';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type RegisterProps = StateProps & DispatchProps & InjectedIntlProps;

const initialValidation = {
  reason: false,
  avoidable: false,
  weight: false
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(3)}px 0px`
  },
  input: {
    borderRadius: '3px',
    padding: '6px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    minHeight: `calc(100vh - ${TopbarMenu.height})`
  },
  label: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  labelAvoidable: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  card: {
    width: '90%',
    backgroundColor: 'white',
    padding: '12px 10px',
    maxWidth: '480px',
    margin: '0 0 24px 0'
  },
  container: {
    background: 'linear-gradient(180deg, rgba(2,93,136,1) 0%, rgba(0,130,197,1) 100%)',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  iframeContainer: {
    flex: 2
  },
  iframeCard: {
    height: '100%'
  },
  iframe: {
    border: 'none'
  },
  selectionYesButton: {
    width: '33.3%',
    textTransform: 'capitalize',
    borderRadius: '6px 0 0 6px',
    borderRightWidth: 0
  },
  selectionPartButton: {
    width: '33.3%',
    textTransform: 'capitalize',
    borderRadius: 0
  },
  selectionNoButton: {
    width: '33.3%',
    textTransform: 'capitalize',
    borderRadius: '0 6px 6px 0',
    borderLeftWidth: 0
  },
  groupButton: {
    textTransform: 'capitalize',
    width: '33.3%'
  },
  inputGreen: {
    backgroundColor: `${theme.palette.primary.main}50`
  },
  inputLightGreen: {
    backgroundColor: `${theme.palette.primary.main}10`
  },
  inputError: {
    border: '2px solid #F44236'
  },
  buttonSubmit: {
    marginTop: theme.spacing(3),
    height: '54px',
    marginBottom: theme.spacing(1)
  },
  avoidableGridError: {
    boxSizing: 'border-box',
    border: '2px solid #F44236',
    padding: '0 1px 10px 2px'
  },
  group: {
    width: '100%',
    height: '46px'
  },
  avoidableContainer: {
    marginTop: theme.spacing(3)
  },
  reasonsTitle: {
    textAlign: 'center'
  },
  link: {
    marginLeft: theme.spacing(0.5)
  },
  reasonsError: {
    border: `1px solid ${theme.palette.error.main}`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: '6px'
  },
  inputDisabled: {
    backgroundColor: `${theme.palette.text.secondary}35`,
    pointerEvents: 'none'
  },
  datepickerInput: {
    padding: 0
  }
}));

const FixedRangeQuery = (): FetchRegistrationsOptions => ({
  createdAt: {
    from: moment().subtract(1, 'week').startOf('day').toISOString(),
    to: moment().endOf('day').toISOString()
  }
});

const StarterRegister: React.FunctionComponent<RegisterProps> = (props) => {
  const {
    register,
    intl,
    weight,
    setWeight,
    avoidable,
    handleAvoidable,
    getRegistrations,
    isLoading,
    setReason,
    setDate,
    reasons,
    selectedReason,
    comment,
    reasonId,
    date,
    locale
  } = props;
  const classes = useStyles(props);
  const [validationError, setValidationError] = React.useState<{
    reason: boolean;
    avoidable: boolean;
    weight: boolean;
  }>(initialValidation);
  const hasReasonFeature = useFeature('registration-reason/full');
  const hasMarketingFeature = useFeature('marketing');
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const disableInputs = !isLoading && hasReasonFeature && reasons.length < 1;

  const formatDate = React.useCallback(
    (date: DateType) =>
      moment(date).toDate().toLocaleDateString(locale, { month: 'numeric', day: 'numeric' }),
    [locale]
  );

  const onDateChange = (newDate: Moment) => {
    const date = new Date(newDate.toDate());
    date.setHours(0, 0, 0, 0);
    setDate(date);
  };

  const handleRegister = () => {
    const selectedReasonValue = hasReasonFeature ? selectedReason : true;
    if (avoidable && selectedReasonValue && weight > 0) {
      void register({
        avoidable,
        weight,
        date,
        reasonId,
        comment
      });
      setValidationError(initialValidation);
    } else {
      const reasonValidation = hasReasonFeature ? { ['reason']: !selectedReason } : null;
      const avoidableValidation = { ['avoidable']: !avoidable };
      setValidationError((prev) => ({
        ...prev,
        ...reasonValidation,
        ...avoidableValidation,
        weight: !(weight > 0)
      }));
    }
  };

  React.useEffect(() => {
    void getRegistrations(FixedRangeQuery());
  }, []);

  return (
    <Fade in={true} timeout={750}>
      <Grid container direction='row' className={classes.root}>
        <Grid item className={classes.form} style={{ width: isDesktop ? 'auto' : '100%' }}>
          <Helmet title={intl.messages['registration.headline']} />
          <Card className={classes.card}>
            <Box
              onSubmit={onSubmitForm(handleRegister)}
              component='form'
              className={classes.formContainer}
            >
              <Grid container alignItems='center' direction='column' justify='center'>
                <Grid container item justify='space-between' className={classes.label}>
                  <Typography component='h1' variant='h3'>
                    {intl.messages['registration.label']}
                  </Typography>
                  <DatePicker
                    minDate={moment().subtract(7, 'days')}
                    size='small'
                    labelFunc={formatDate}
                    disableFuture
                    value={moment(date)}
                    onChange={onDateChange}
                    InputProps={{
                      classes: { input: classes.datepickerInput },
                      inputProps: { size: 5 },
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <EventIcon fontSize='small' />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <NumberInput
                  allowNegative={false}
                  allowEmptyFormatting
                  fullWidth
                  autoComplete='off'
                  type='text'
                  suffix=' kg'
                  placeholder='-'
                  required={true}
                  className={classNames(classes.input, {
                    [classes.inputLightGreen]: !weight || weight >= 1,
                    [classes.inputGreen]: weight < 1,
                    [classes.inputDisabled]: disableInputs,
                    [classes.inputError]: validationError.weight
                  })}
                  value={weight ?? ''}
                  onValueChange={(values: NumberFormatValues) => setWeight(values.floatValue)}
                  focused
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      style: { textAlign: 'center', height: '48px' }
                    }
                  }}
                />
              </Grid>

              <Grid
                container
                alignItems='center'
                direction='column'
                justify='center'
                className={classNames({
                  [classes.avoidableGridError]: validationError.avoidable
                })}
              >
                <Typography className={classes.labelAvoidable} component='p'>
                  {intl.messages['registration.avoidable']} ?
                </Typography>
                <ButtonGroup className={classes.group} disabled={disableInputs}>
                  <Button
                    color='primary'
                    variant={avoidable === 'yes' ? 'contained' : 'outlined'}
                    onClick={() => handleAvoidable('yes')}
                    className={classes.groupButton}
                  >
                    {intl.messages['yes']}
                  </Button>
                  <Button
                    color='primary'
                    variant={avoidable === 'partly' ? 'contained' : 'outlined'}
                    onClick={() => handleAvoidable('partly')}
                    className={classes.groupButton}
                  >
                    {intl.messages['registration.avoidable.partially']}
                  </Button>
                  <Button
                    color='primary'
                    variant={avoidable === 'no' ? 'contained' : 'outlined'}
                    onClick={() => handleAvoidable('no')}
                    className={classes.groupButton}
                  >
                    {intl.messages['no']}
                  </Button>
                </ButtonGroup>
              </Grid>

              <Feature name='registration-reason/full'>
                {reasons.length > 0 ? (
                  <SelectReason
                    onSelect={setReason}
                    reasons={reasons}
                    error={validationError.reason}
                    selected={selectedReason}
                    style={classes.avoidableContainer}
                    titleStyles={classes.reasonsTitle}
                    title={'settings.reasons.tableHeader'}
                  />
                ) : (
                  <div className={classes.avoidableContainer}>
                    <Typography align={'center'} component='h1' variant='h3'>
                      {intl.messages['settings.reasons.tableHeader']}
                    </Typography>
                    <Typography
                      className={classNames(classes.labelAvoidable, {
                        [classes.reasonsError]: validationError.reason
                      })}
                      component='p'
                    >
                      {`${intl.messages['registration.noReasonsMessage']} ${intl.messages['registration.addReasonsAction']}`}
                      <Link className={classes.link} href='/settings' underline='none'>
                        {intl.messages['base.here']}
                      </Link>
                      .
                    </Typography>
                  </div>
                )}
              </Feature>

              <Button
                type='submit'
                color='primary'
                variant='contained'
                className={classes.buttonSubmit}
                disabled={isLoading || disableInputs}
              >
                {intl.messages['registration.btn']}
              </Button>
            </Box>
          </Card>

          <StarterPlanHistoryTable />
        </Grid>
        {hasMarketingFeature && isDesktop && (
          <Grid item className={classes.iframeContainer}>
            <Card className={classes.iframeCard}>
              <iframe
                className={classes.iframe}
                height={'100%'}
                width={'100%'}
                src={intl.messages['dashboard.marketing.url']}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </Fade>
  );
};

const mapStateToProps = (state: RootState) => {
  const { reasonId } = state.registration;
  const reasons = getActiveReasons(state);
  return {
    soundSettings: getSettings(state).sound,
    weight: state.registration.weight,
    date: state.registration.date,
    reasonId: state.registration.reasonId,
    comment: state.registration.comment,
    avoidable: state.registration.avoidable,
    isLoading: state.registration.loading,
    reasons: getActiveReasons(state),
    selectedReason: reasonId ? reasons.find((r) => r.id === reasonId) : undefined,
    locale: state.settings.locale
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, RegistrationActions>) => ({
  register: (registration: CreateFreeRegistration) =>
    dispatch(registrationDispatch.registerFreePlan(registration)),
  setWeight: (weight: number) => {
    dispatch(registrationDispatch.setWeight(weight));
  },
  setDate: (date: Date) => {
    dispatch(registrationDispatch.setDate(date));
  },
  handleAvoidable: (data: RegistrationAvoidable) => {
    dispatch(registrationDispatch.setAvoidable(data));
  },
  setReason: (reason: Reason) => dispatch(registrationDispatch.setReason(reason.id)),
  getRegistrations: (options?: FetchRegistrationsOptions) => dispatch(fetchRegistrations(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StarterRegister));
