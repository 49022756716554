import * as React from 'react';
import EmissionDistribution from './components/EmissionDistribution';
import EmissionOverview from './components/EmissionOverview';
import { PageTitle } from 'pages/Report/components/ReportPageLayout';
import { Grid } from '@material-ui/core';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import EmissionMetricsOverview from './components/EmissionMetrics';

interface ComponentProps extends InjectedIntlProps {
  downloadButton: React.ReactNode;
}

const Sustainability: React.FunctionComponent<ComponentProps> = ({ downloadButton, intl }) => (
  <>
    <PageTitle>{intl.messages['report.sustainability.title']}</PageTitle>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <EmissionOverview />
      </Grid>
      <Grid item xs={12}>
        <EmissionDistribution />
      </Grid>
      <Grid item xs={12}>
        <EmissionMetricsOverview />
      </Grid>
    </Grid>
    {downloadButton}
  </>
);

export default injectIntl(Sustainability);
