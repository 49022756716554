import * as React from 'react';
import { CardContent, Grid, Card, Box, Typography, useMediaQuery } from '@material-ui/core';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import { WithRouterProps } from 'react-router';
import { Feature } from 'flagged';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type FreeOverviewProps = StateProps & InjectedIntlProps & WithRouterProps & DispatchProps;

import withRouter from 'react-router/lib/withRouter';
import donutChartOptions from 'pages/Report/components/Chart/Donut/options';
import donutChartOptionsMobile from 'pages/Report/components/Chart/Donut/optionsMobile';
import { Donut } from 'pages/Report/components/Chart';
import { makeStyles } from '@material-ui/core/styles';
import { getAvoidableMetrics, getReportReasonsWeight } from 'redux/ducks/reportData/selector';
import Helmet from 'react-helmet';
import { useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { ReportActions } from 'redux/ducks/reportFilter';
import GuestsFoodwastePerMonth from 'pages/Report/StarterPlanOverview/GuestsFoodwastePerMonth';
import * as reportDispatch from 'redux/ducks/reportData';
import theme from 'styles/themes/reports';
import merge from 'lodash/merge';
import { ReportDataIdentifier } from 'redux/ducks/reportData';
import { CHART_COLORS } from 'pages/Report';

const reasonsIdentifier = 'foodWasteReasons';
const registrationsIdentifier = 'registrations';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1)
  },
  cardHeader: {
    textAlign: 'center',
    padding: 0,
    fontWeight: 'bold'
  },
  box: {
    marginTop: 0
  }
}));

const StarterPlanOverview: React.FunctionComponent<FreeOverviewProps> = (props) => {
  const { intl, isLoading, avoidableRegistrations, fetchData, reasonsWeightData } = props;
  const classes = useStyles(props);

  useEffect(() => {
    if (!isLoading) {
      void fetchData(registrationsIdentifier);
      void fetchData(reasonsIdentifier);
    }
  }, [isLoading]);

  const isMobile = useMediaQuery('(max-width: 500px)', { noSsr: true });

  const avoidableRegistrationsPoints = avoidableRegistrations.points.map((value) => ({
    ...value,
    label: intl.messages[value.label]
  }));

  const registrationsArray = { ...avoidableRegistrations, points: avoidableRegistrationsPoints };
  const defaultChartOptions = isMobile ? donutChartOptionsMobile : donutChartOptions;
  const chartOptionsAvoidable = merge({}, defaultChartOptions, {
    plotOptions: {
      pie: {
        dataLabels: {
          distance: isMobile ? 15 : 30,
          y: -10
        }
      }
    }
  });

  const reasonsWeightChartOptionsReasons = merge({}, chartOptionsAvoidable, {
    title: {
      style: { display: 'none' }
    },
    tooltip: {
      pointFormat: '<b>{point.y}kg {point.percentage:.2f}%</b>'
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        dataLabels: {
          format: '{point.y}kg </br> ({point.percentage:.2f}%)'
        }
      }
    },
    chart: { height: 400 },
    legend: {
      enabled: true,
      align: 'center',
      layout: isMobile ? 'vertical' : 'horizontal',
      margin: 20,
      itemStyle: {
        fontSize: '16px',
        fontWeight: (900).toString(),
        color: theme.palette.text.secondary
      },
      itemHiddenStyle: {
        fontWeight: theme.typography.fontWeightRegular.toString(),
        color: theme.palette.text.secondary
      },
      symbolWidth: 14,
      symbolHeight: 14,
      symbolRadius: 2
    }
  });

  return (
    <Grid container>
      <Helmet title={intl.messages['mobile.navigation.reports']} />
      <Card className={classes.card}>
        <Typography variant={'h1'} component={'h1'} className={classes.cardHeader}>
          {intl.messages['freeplan_simple.report_header']}
        </Typography>
        <CardContent>
          <Box className={classes.box} mb={2} mt={1.5}>
            <Grid container alignContent={'flex-start'} justify={'center'}>
              <Donut
                options={{
                  colors: CHART_COLORS,
                  total: registrationsArray.aggregates.total,
                  unit: 'kg'
                }}
                seriesData={registrationsArray || []}
                chartOptions={chartOptionsAvoidable}
                isLoading={isLoading}
              />
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <Feature name='report-guest-history/full'>
        <Card className={classes.card}>
          <Typography variant={'h1'} component={'h1'} className={classes.cardHeader}>
            {intl.messages['report.reasons.label']}
          </Typography>
          <CardContent>
            <Box className={classes.box} mb={2} mt={1.5}>
              <Grid container alignContent={'flex-start'} justify={'center'}>
                <Donut
                  options={{
                    colors: CHART_COLORS,
                    unit: 'kg',
                    total: undefined
                  }}
                  seriesData={reasonsWeightData || []}
                  chartOptions={reasonsWeightChartOptionsReasons}
                  isLoading={isLoading}
                />
              </Grid>
            </Box>
          </CardContent>
        </Card>

        <GuestsFoodwastePerMonth />
      </Feature>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.reportFilter.loading,
    avoidableRegistrations: getAvoidableMetrics(state),
    reasonsData: state.reportData[reasonsIdentifier],
    reasonsWeightData: getReportReasonsWeight(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  fetchData: (identifier: ReportDataIdentifier) => dispatch(reportDispatch.fetchData(identifier))
});

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(StarterPlanOverview)));
