import calculateChartHeight from 'pages/Report/components/Chart/Bar/utils/calculateChartHeight';
import merge from 'lodash/merge';
import BaseOptions from 'pages/Report/components/Chart/Bar/options';
import { Formatter } from 'pages/Report/utils/createValueFormatter';
import { ThemeMapper } from 'pages/Report/components/Chart/utils/getChartData';

const chartMargin = 0;

const themeMapper =
  (valueFormatter: Formatter): ThemeMapper =>
  (data) => {
    return merge({}, BaseOptions, {
      chart: {
        height: calculateChartHeight({
          series: data.series,
          marginTop: chartMargin,
          marginBottom: chartMargin
        }),
        marginTop: chartMargin,
        marginBottom: chartMargin
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          groupPadding: 0.155 - 0.02 * data.series.length,
          dataLabels: {
            formatter: function () {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
              return valueFormatter.format(this.y, data.unit);
            }
          }
        }
      },
      tooltip: {
        pointFormatter: function () {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
          return `<b>${valueFormatter.format(this.y, data.unit)}</b> ${
            data.intl.messages['report.terms.foodwaste']
          }`;
        }
      },
      yAxis: [
        {
          // need to udpate on drilldown
          max: Number(data.maxValue) * 1.2, // The 1.2 multiplier is to allow more space for the data labels
          labels: {
            formatter: function () {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
              return valueFormatter.format(this.value, data.unit);
            }
          }
        }
      ],
      colors: data.chartColors
    });
  };

export { themeMapper };
