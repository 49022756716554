import * as React from 'react';
import { connect } from 'react-redux';
import RegistrationFrequency from 'pages/Report/RegistrationFrequency/RegistrationFrequency';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { getSelectedAccountNames } from 'redux/ducks/reportFilter/selectors';
import { useDownloadReportPDF } from 'pages/Report/utils/useDownloadPDF';
import { getChartPNGs } from 'pages/Report/RegistrationFrequency/utils/generateChartImages';
import DownloadBtn from 'pages/Report/components/DownloadBtn';
import { RootState } from 'redux/rootReducer';

type StateProps = ReturnType<typeof mapStateToProps>;
type RegistrationFrequencyContainerProps = StateProps & InjectedIntlProps;

const RegistrationFrequencyContainer: React.FunctionComponent<
  RegistrationFrequencyContainerProps
> = (props) => {
  const { chartRefs, regFrequency, intl, selectedAccountNames } = props;
  const {
    data: chartsData,
    timeRange: { from, to }
  } = regFrequency;
  const [isDownloading, downloadPDF] = useDownloadReportPDF({
    generateChartPNG: () => getChartPNGs({ chartRefs, filter: null, chartsData: null }),
    reportData: {
      AsyncDocument: async () => (await import('./PDF')).default,
      data: {
        regFrequencyMetrics: chartsData,
        intl,
        startDate: from,
        endDate: to,
        selectedAccountNames
      },
      name: `registration-frequency-${from}-${to}`
    }
  });

  return (
    <RegistrationFrequency
      downloadButton={<DownloadBtn isDownloading={isDownloading} onClick={downloadPDF} />}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  chartRefs: state.charts.chartRefs,
  regFrequency: state.reportData.regFrequencyMetrics,
  selectedAccountNames: getSelectedAccountNames(state)
});

export default connect<StateProps, unknown, unknown>(mapStateToProps)(
  injectIntl(RegistrationFrequencyContainer)
);
